/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
import React, {Component} from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { trls } from '../../factories/translate';
import API from '../../factories/api';
import SessionManager from '../../factories/session_manage';
import Axios from 'axios';
import $ from 'jquery';
import history from '../../history';
import Message from '../../components/message';
import Sweetalert from "sweetalert";

const mapStateToProps = state => ({ 
    ...state.auth,
});

class ChangePassword extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {  
            errorMessage: ''
        };
    }
    
    componentDidMount() {
    }

    handleSubmit = (event, formData, inputs) => {
        const { userData } = this.props
        this._isMounted = true;
        event.preventDefault();
        const clientFormData = new FormData(event.target);
        const data = {};
        for (let key of clientFormData.keys()) {
        data[key] = clientFormData.get(key);
        }
        var headers = SessionManager.shared().getAuthorizationHeader();
        let params = {
            email: userData.email,
            password: data.password
        }
        Axios.put(API.ChangePassword, params, headers)
        .then(result => {
            Sweetalert(trls('Success'), {
                icon: "success",
            }).then((value) => {
                this.props.onHide()
            });
        })
        .catch(err => {
            if(err.response.status===401){
                history.push('/login')
            }
            if(err.response.status===400){
                $('.add-error').removeClass('d-none')
                if (err.response.data.errors.Password) {
                    this.setState({errorMessage: err.response.data.errors.Password[0]})
                } else {
                    this.setState({errorMessage: ''})
                }
                
            }
        });
    }

    onShow = () => {
       
    }

    render(){
        const { userData } = this.props
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                onShow={this.onShow}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop= "static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {trls('ChangePassword')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Message message={this.state.errorMessage} type={"error"}/>
                    <Form onSubmit = { this.handleSubmit }>
                        <Form.Group as={Row} controlId="formPlaintextPassword">
                            <Col className="product-text">
                                <Form.Control type="email" name="email" disabled required placeholder={trls('Email')} value={userData.email}/>
                                <label className="placeholder-label">{trls('Email')}</label>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextPassword">
                            <Col className="product-text">
                                <Form.Control type="password" name="password" required placeholder={trls('Password')}/>
                                <label className="placeholder-label">{trls('Password')}</label>
                            </Col>
                        </Form.Group>
                        <Form.Group style={{textAlign:"center"}}>
                            <Button type="Submit" >{trls("Save")}</Button>
                        </Form.Group>
                    </Form>    
                </Modal.Body>
            </Modal>
        );
    }
}
export default connect(mapStateToProps)(ChangePassword);