import React, {Component} from 'react'
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { trls } from '../factories/translate';

const mapStateToProps = state => ({ 
    ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({

});

class ShippingAddressModal extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.myInput = React.createRef(); 
        this.state = {  
            selectedIndex: '',
            selectShippingAddress: []
        };
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    
    componentDidMount() {
    }

    handleSubmit = (e, formData, inputs) => {
    }

    removeState = () => {
        this.setState({
            selectedIndex:'',
            selectShippingAddress: []
        })
    }

    selectRow = (selectId) => {
        const { shippingAddressList } = this.props;
        this.setState({selectedIndex: selectId});
        var selectShippingAddress = shippingAddressList.filter((item, key)=>item.ID===selectId);
        this.setState({selectShippingAddress: selectShippingAddress[0]})
    }

    onShow = () => {
        const { shippingAddressList } = this.props;
        this.setState({selectedIndex: shippingAddressList[0] ? shippingAddressList[0].ID : '', selectShippingAddress: shippingAddressList[0]});
    }

    saveShippingAddress = () => {
        this.props.onHide();
        const { selectShippingAddress } = this.state;
        localStorage.setItem('eijf_shippingAddress', selectShippingAddress.ID);
        this.removeState()
    }

    render(){
        const { shippingAddressList } = this.props;
        const { selectedIndex } = this.state;
        return (
            <Modal
                show={this.props.show}
                onHide={this.saveShippingAddress}
                onShow={this.onShow}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop= "static"
                size="lg"
                centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Select Shipping Address
                </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive place-order__search-itemtable">
                        <table id="example" className="place-and-orders__table table prurprice-dataTable" width="100%">
                            <thead>
                                <tr>
                                    <th>{trls("Country")}</th>
                                    <th>{trls("Address")}</th>
                                    <th>{trls("City")}</th>
                                    <th>{trls("Street")}</th>
                                    <th>{trls("Zipcode")}</th>
                                </tr>
                            </thead>
                            {shippingAddressList &&(<tbody >
                                {
                                    shippingAddressList.map((data,index) =>(
                                        <tr key={index} onClick={() => this.selectRow(data.ID)} className={selectedIndex === data.ID? "item-search__tr-active" : "item-search__tr"}>
                                            <td>{data.CountryInEnglish}</td>
                                            <td>{data.addressName}</td>
                                            <td>{data.city}</td>
                                            <td>{data.street}</td>
                                            <td>{data.zipcode}</td>
                                        </tr>
                                ))
                                }
                            </tbody>)}
                        </table>
                    </div>
                    <div className="text-center">
                        <Button type="button" onClick={()=>this.saveShippingAddress()} style={{width: "100px"}}>{trls('Save')}</Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddressModal);