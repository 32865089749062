/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import { trls } from "../../factories/translate";
import { Container, Row, Col, Button } from "react-bootstrap";
import SessionManager from "../../factories/session_manage";
import API from "../../factories/api";
// import * as Queries  from '../../factories/queries'
import Axios from "axios";
import { Link } from "react-router-dom";
import * as Common from "../../factories/common";
import * as authAction from "../../actions/authAction";
import Pageloadspiiner from "../../components/page_load_spinner";
import history from "../../history";
import * as Auth from "../../factories/auth";
import Parser from "html-react-parser";
import Pagination from "../../components/pagination";
import "./dashboard_manage_style.css";
import $ from "jquery";
import HelpTextModal from "./HelpText";
import dayjs from "dayjs";
import Slideshow from "../../components/slideshow";
const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  blankdispatch: () => dispatch(authAction.blankdispatch()),
});

class Dashboard extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      pageLodingFlag: true,
      dashBoardFlag: false,
      lastOrdersFlag: false,
      lastDeleiversFlag: false,
      lastOutstanding: false,
      newFlag: false,
      newsArrayData: [],
      newsViewData: [],
      loginUser: Auth.getLoggedUserInfo(),
      newsDataPageSize: 0,
      newsLanguage: {
        English: {
          title: "subjectEnglish",
          subtitle: "subTitleEnglish",
          text: "textEnglish",
          locale: "en",
        },
        Dutch: {
          title: "subjectDutch",
          subtitle: "subTitleDutch",
          text: "textDutch",
          locale: "nl",
        },
        German: {
          title: "subjectGerman",
          subtitle: "subTitleGerman",
          text: "textGerman",
          locale: "de",
        },
        French: {
          title: "subjectFrench",
          subtitle: "subTitleFrench",
          text: "textFrench",
          locale: "fr",
        },
      },
      HelpTextModal: false,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this.getNewsData();
  }

  getHelpText = () => {
    var pageName = "News";
    var headers = SessionManager.shared().getAuthorizationHeader();
    var lang = localStorage.getItem("eijf_lang");
    Axios.get(API.GetHelpTextByName + pageName, headers)
      .then((result) => {
        let helpURL = "";
        if (lang === "Dutch") {
          helpURL = result.data.contentDutch;
        } else if (lang === "English") {
          helpURL = result.data.contentEnglish;
        } else if (lang === "French") {
          helpURL = result.data.contentFrench;
        } else {
          helpURL = result.data.contentGerman;
        }
        const a = document.createElement("a");
        a.setAttribute("href", helpURL);
        a.setAttribute("target", "_blank");
        a.click();
      })
      .catch((err) => {
        // if(err.response.status===401){
        //     history.push('/login')
        // }
      });
  };

  chunk = (array, size) => {
    const chunked_arr = [];
    for (let i = 0; i < array.length; i++) {
      const last = chunked_arr[chunked_arr.length - 1];
      if (!last || last.length === size) {
        chunked_arr.push([array[i]]);
      } else {
        last.push(array[i]);
      }
    }
    return chunked_arr;
  };
  getNewsData = () => {
    this._isMounted = true;
    var headers = SessionManager.shared().getAuthorizationHeader();
    Axios.get(API.GetNews, headers).then((result) => {
      if (this._isMounted) {
        let newsData = result.data;
        newsData.sort(function (a, b) {
          return new Date(b.startDate) - new Date(a.startDate);
        });
        // newsData = this.chunk(newsData, 3);
        let newsDataPageSize = newsData.length;
        this.setState({
          newsArrayData: newsData,
          newsViewData: newsData,
          newsDataPageSize: newsDataPageSize,
          newFlag: true,
          pageLodingFlag: false,
        });
      }
      window.setTimeout(function () {
        $(".welcome-message").addClass("d-none");
        localStorage.removeItem("welcome_message");
      }, 4000);
    });
    // .catch(err => {
    //     if(err.response.status===401){
    //         history.push('/login')
    //     }
    // })
  };

  //   onChangeNewsPage = (page) => {
  //     const { newsArrayData } = this.state;
  //     this.setState({ newsViewData: newsArrayData[page - 1] });
  //   };

  render() {
    let {
      pageLodingFlag,
      newsViewData,
      loginUser,
      newsDataPageSize,
      newFlag,
      newsLanguage,
    } = this.state;
    let userInfo = Auth.getUserInfo();
    let lodingFlag = pageLodingFlag;

    if (userInfo.role === "Sales") {
      history.push("/user");
    }
    if (newFlag && userInfo.role === "Administrator") {
      lodingFlag = false;
    }
    if (newFlag && userInfo.role !== "Administrator") {
      lodingFlag = false;
    }
    let newsTitle,
      newsText,
      newsSubtitle,
      locale = "";
    var lang = localStorage.getItem("eijf_lang");

    if (loginUser.Language === lang) {
      newsTitle = newsLanguage[loginUser.Language].title;
      newsSubtitle = newsLanguage[loginUser.Language].subtitle;
      newsText = newsLanguage[loginUser.Language].text;
      locale = newsLanguage[loginUser.Language].locale;
    } else {
      newsTitle = newsLanguage[lang].title;
      newsSubtitle = newsLanguage[lang].subtitle;
      newsText = newsLanguage[lang].text;
      locale = newsLanguage[lang].locale;
    }

    const newNewsData = [];
    
    console.log(loginUser.GroupCode);
    console.log(newsViewData);
    for (let index = 0; index < newsViewData.length; index++) {
      const news = newsViewData[index];
      //if(!(news.customerGroupCodes.includes("102")&& !news.customerGroupCodes.includes("100") && loginUser.GroupCode === "100")) {
        //newNewsData.push(news);
      //}

      if(news.customerGroupCodes.length === 0) {
        newNewsData.push(news);
      } else if(news.customerGroupCodes.includes(loginUser.GroupCode)) {
        newNewsData.push(news);
      }
    }
    newsViewData = newNewsData;

    let SapCustomerName = Auth.getLoggedUserInfo().SapCustomerName;
    let Welcome_Message = localStorage.getItem("welcome_message");

    // let dash18 = JSON.parse(localStorage.getItem('eijf_loggedUser'));
    // console.log(dash18.Currency,"182 klk")
    return (
      <Container>
        {Welcome_Message ? (
          <div className="alert alert-success mb-4 welcome-message">
            Welcome <strong>{SapCustomerName}</strong> in your own Eijffinger
            portal
          </div>
        ) : (
          false
        )}
        {userInfo.role !== "Administrator" ? (
          <Row>
            <Col sm={6}></Col>
            <Col sm={6} className="pr-0">
              {/* <Button variant="primary" className="float-right mb-2" onClick={this.getHelpText}><i className="fa fa-info-circle " aria-hidden="true"></i></Button>  */}
            </Col>
          </Row>
        ) : (
          false
        )}

        <div className="card border-news mt-2 mb-4">
          <div className="card-header border-news news-header">
            <h2 className="title">{trls("News")}</h2>
          </div>

          <div className="card-body">
            <Col className="p-0">
              <div className="news">
                {newsViewData &&
                  newsViewData.map(
                    (data, i) =>
                      data[newsText] && (
                        <div className="news-item" id={i} key={i}>
                          <div className="news-image">
                            <Slideshow
                              images={data.documents.filter(
                                (x) =>
                                  (x.documentUrl.includes(".jpg") ||
                                    x.documentUrl.includes(".png")) &&
                                  x.isPrimary
                              )}
                            />
                          </div>
                          <div className="subtitle">{data[newsSubtitle]}</div>
                          <h2 className="title">{data[newsTitle]}</h2>
                          <div className="date">
                            {dayjs(data.startDate).format("DD MMMM YYYY")}
                          </div>
                          <div className="text">{data[newsText]}</div>
                          {/*Parser(data[newsText])*/}
                        </div>
                      )
                  )}
                {/* {newsDataPageSize>1 && (
                            <Pagination
                                recordNum={newsDataPageSize}
                                getPage={(page)=>this.onChangeNewsPage(page)}
                            />
                        )} */}
              </div>
            </Col>
            <Pageloadspiiner loading={lodingFlag} text={""} />
            <HelpTextModal
              show={this.state.HelpTextModal}
              onHide={() => this.setState({ HelpTextModal: false })}
              HelpTextTitle={this.state.HelpTextTitle}
              HelpTextDescription={this.state.HelpTextDescription}
            />
          </div>
        </div>
      </Container>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
