/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { connect } from "react-redux";
import { trls } from "../../factories/translate";
import API from "../../factories/api";
import * as authAction from "../../actions/authAction";
import SessionManager from "../../factories/session_manage";
import Axios from "axios";
import $ from "jquery";
import history from "../../history";

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({});

class UpdateDocumentTypeForm extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.myInput = React.createRef();
    this.state = {
      documentType: [],
      selectedDocumentType: {},
      documentTypeId: 0,
      documentTypeName: "",
      documentFile: "",
      documentUrl: "",
      documentUpdateData: "",
      customerCode: [],
      customerCodes: [],
      selectCustomerCodes: [],
      documentName: "",
      customerGroup: [],
      openInBrowser: false,
      customerGroupCodes: [],
    };
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {}

  handleSubmit = (e, formData, inputs) => {
    this._isMounted = true;
    e.preventDefault();
    const submitData = new FormData(e.target);
    const data = {};
    for (let key of submitData.keys()) {
      data[key] = submitData.get(key);
    }
    var headers = SessionManager.shared().getAuthorizationHeader();
    var id = this.state.documentUpdateData.id;
    var documentTypeId = this.state.documentTypeId;

    let params = {
      id: id,
      name: data.name,
      // "documentTypeId": documentTypeId,
      documentUrl: "",
      customerCodes: this.state.customerCodes,
      customerGroupCodes: this.state.customerGroupCodes
        ? this.state.customerGroupCodes
        : [],
    };
    Axios.put(API.UpdateDocuments + id, params, headers)
      .then((result) => {
        if (this._isMounted) {
          if (this.state.documentFile !== "") {
            var formData = new FormData();
            formData.append("file", this.state.documentFile);
            Axios.post(
              API.PostDocumentsfileId +
                id +
                "?documentId=" +
                documentTypeId +
                "&id=" +
                id +
                "&openInBrowser=" +
                this.state.openInBrowser,
              formData,
              headers
            )
              .then((result) => {
                this.setState({ documentUrl: result.data.documentUrl });
                if (this._isMounted) {
                  this.props.onHide();
                  this.props.onGetUser();
                  this.removeState();
                }
              })
              .catch((err) => {
                if (err.response.status === 401) {
                  history.push("/login");
                }
                if (err.response.status === 400) {
                  $(".add-error").removeClass("d-none");
                  $(".add-error").text(err.response.data);
                }
              });
          } else {
            this.props.onHide();
            this.props.onGetUser();
            this.removeState();
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          history.push("/login");
        }
        if (err.response.status === 400) {
          $(".add-error").removeClass("d-none");
          $(".add-error").text(err.response.data);
        }
      });
  };

  removeState = () => {
    this.setState({
      documentLanguage: "",
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    $(".add-error").addClass("d-none");
    this.setState({
      documentFile: e.target.files[0],
    });
  };

  hideModel = () => {
    this.props.onHide();
    this.removeState();
  };
  onShow = () => {
    const { customerGroupList } = this.props;
    var tempDocumentTypeData = [];
    var tempCustomerCodesData = [];
    var tempselectCustomerCodes = [];
    var tempselectCustomerGroupCodes = [];

    this.props.DocumentTypeData.forEach((element) => {
      tempDocumentTypeData.push({ value: element.id, label: element.name });
    });
    this.props.CustomerCodesData.forEach((element) => {
      tempCustomerCodesData.push({
        value: element.CardCode,
        label: element.CardCode,
      });
    });
    this.props.documentUpdateData.customerCodes.forEach((element) => {
      tempselectCustomerCodes.push({ value: element, label: element });
    });
    this.props.documentUpdateData.customerGroupCodes.forEach((element) => {
      var groupTemp = customerGroupList.filter(
        (item, key) => item.value === Number(element)
      );
      tempselectCustomerGroupCodes.push(groupTemp[0]);
    });
    this.setState({
      documentUpdateData: this.props.documentUpdateData,
      documentType: tempDocumentTypeData,
      selectedDocumentType: this.props.documentUpdateData.documentType,
      // documentTypeId:this.props.documentUpdateData.documentType.id,
      // documentTypeName: this.props.documentUpdateData.documentType.name,
      customerCode: tempCustomerCodesData,
      selectCustomerCodes: tempselectCustomerCodes,
      customerGroup: tempselectCustomerGroupCodes,
    });
  };

  customerCodeChange = (val) => {
    let tempCustomerCodes = [];
    val.forEach((element) => {
      tempCustomerCodes.push(element.value);
    });
    this.setState({
      customerCodes: tempCustomerCodes,
      selectCustomerCodes: val,
    });
  };

  customerGroupCodeChange = (val) => {
    let tempCustomerCodes = [];
    val.forEach((element) => {
      tempCustomerCodes.push(String(element.value));
    });
    this.setState({
      customerGroupCodes: tempCustomerCodes,
      customerGroup: val,
    });
  };

  render() {
    const { documentUpdateData, customerGroupList } = this.props;
    const { customerGroup, selectCustomerCodes } = this.state;
    return (
      <Modal
        show={this.props.show}
        onHide={this.hideModel}
        onShow={this.onShow}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {trls("Edit_Document")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="alert alert-danger add-error d-none"
            role="alert"
          ></div>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group as={Row} controlId="formPlaintextPassword">
              <Col className="product-text">
                <Form.Control
                  type="text"
                  name="name"
                  onChange={(e) =>
                    this.setState({ documentName: e.target.value })
                  }
                  required
                  defaultValue={
                    documentUpdateData ? documentUpdateData.name : ""
                  }
                  placeholder={trls("Name")}
                />
                <label className="placeholder-label">{trls("Name")}</label>
              </Col>
            </Form.Group>
            {/* <Form.Group as={Row} controlId="documentTypeId">
                        <Col>
                            <Select
                            name="documentTypeId"
                            options={this.state.documentType}
                            onChange={val => this.setState({documentTypeId: val.value,documentTypeName:val.label})}
                            value = {{value:this.state.documentTypeId,label:this.state.documentTypeName}}
                            />

                            <label className="placeholder-label">{trls('documentLanguage')}</label>

                        </Col>
                    </Form.Group> */}
            <Form.Group as={Row} controlId="documentFile">
              <Col className="product-text d-flex align-items-center">
                <Form.Check
                  type="checkbox"
                  label="Open"
                  onChange={(evt) =>
                    this.setState({ openInBrowser: evt.target.checked })
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="documentFile">
              <Col className="product-text">
                <div className="form-control">
                  <Form.Control
                    onChange={this.handleChange}
                    type="file"
                    name="documentFile"
                  />
                  <label className="placeholder-label">
                    {trls("Document_File")}
                  </label>
                </div>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="documentType">
              <Col>
                <Select
                  options={this.state.customerCode}
                  isMulti
                  onChange={(val) => this.customerCodeChange(val)}
                  value={selectCustomerCodes}
                />
                <label className="placeholder-label">
                  {trls("customerCode")}
                </label>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="customerGroup">
              <Col>
                <Select
                  options={customerGroupList}
                  isMulti
                  onChange={(val) => this.customerGroupCodeChange(val)}
                  value={customerGroup ? customerGroup : []}
                />
                <label className="placeholder-label">
                  {trls("CustomerGroupCode")}
                </label>
              </Col>
            </Form.Group>
            <Form.Group style={{ textAlign: "center" }}>
              <Button type="Submit">{trls("Save")}</Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateDocumentTypeForm);
