/* eslint-disable no-lone-blocks */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Form, Col } from 'react-bootstrap';
import Select from 'react-select';
import { trls } from '../../factories/translate';
import DatePicker from "react-datepicker";

class FilterErrorLogs extends React.Component {
    _isMounted = false
    constructor(props) {
        super(props);
        this.state = {  
            filterData: this.props.filterData,
            filterOptionData: [],
            selectDate: new Date(),
            endDate: new Date(),
            datePickerFlag: false,
            ColumnCount:0,
            addDisable:false
        };

    }
    
    addFilterOption = () => {
        var ColumnCount = this.state.ColumnCount;
        let filterOptionData = this.state.filterOptionData;

        if(ColumnCount!==0){
            this.setState({filterData: this.props.fitlerData1})
        }
        else{
            this.setState({filterData: this.props.filterData})
        }
        let arr = this.state.filterData;
        let data = {value:"", dateFlag:false,leveFlag:false,eventFlag:false,startDate:this.state.selectDate,endDate:this.state.endDate};
        filterOptionData.push(data);
        this.setState({filterOptionData: filterOptionData});
        // arr.map((item, i) => {
        //     if((filterOptionData.length>1)&&(item.value === "id") || (item.value === "referenceId")){
        //         // arr.splice(i, 1);
        //     }
        // })
        ColumnCount++;
        this.setState({ColumnCount: ColumnCount})

    }

    removeFilterOption = (index, dataOption) => {
        let arr = this.state.filterOptionData, deletedValue = '', addDisable = this.state.addDisable;
        arr = arr.filter(function(item, key) {
            deletedValue = ''
            if((key === index)&& (item.filterOption ==="id" || item.filterOption ==="referenceId")){
                deletedValue = item.filterOption
            }
            return key !== index
        })

        // this.setState({filterOptionData: arr});
        var ColumnCount = this.state.ColumnCount;
        ColumnCount--;
        if(ColumnCount > 1){
            this.setState({filterData: this.props.fitlerData1})
        }
        else{
            this.setState({filterData: this.props.filterData})
        }
        
        {((ColumnCount === 0) && (deletedValue!='') )? this.setState({addDisable: false}) : this.setState({addDisable: addDisable}) }

        this.setState({ColumnCount: ColumnCount,filterOptionData: arr})

    }

    onChangeDate = (date, e, index, mode) => {
        let arr = this.state.filterOptionData;
        if(e.type==="click"){
            arr.map((item, key)=>{
                if(key===index){
                    if(mode==="start"){
                        item.startDate = date;
                    }else{
                        item.endDate = date;
                    }
                }
                return item;
            })
            this.setState({filterOptionData: arr});
            if(mode==="start"){
                this.setState({selectDate: date})
            }else{
                this.setState({endDate: date})
            }
        }
    }

    filterChangeData = (val, index) => {
        let arr_option = this.state.filterOptionData;
        if(val.type==="date"){
            this.setState({datePickerFlag: true});
        }else{
            this.setState({datePickerFlag: false});
        }

        arr_option.map((item, key)=>{
            if(key===index){
                if(val.type==="date"){
                    item.value = this.state.selectDate;
                    item.dateFlag = true;
                }else{
                    item.dateFlag = false;
                }
                {val.value==="logLevel"? item.leveFlag=true : item.leveFlag=false}
                {val.value==="logEvent"? item.eventFlag=true : item.eventFlag=false}
                {(val.value === "id")||(val.value === "referenceId")? this.setState({addDisable: true}) : this.setState({addDisable: false})}
                item.filterOption = val.value;
            }
            return item;
        })

        this.setState({filterOptionData: arr_option});
    }

    filterChangeMode = (val, index) => {
        let arr = this.state.filterOptionData;
        arr.map((item, key)=>{
            if(key===index){
                item.mode = val.value;
            }
            return item;
        })
        this.setState({filterOptionData: arr});
    }

    changeValue = (evt, index) => {
        let arr = this.state.filterOptionData;
        arr.map((item, key)=>{
            if(key===index){
                item.value = evt.target.value;
            }
            return item;
        })
        this.setState({filterOptionData: arr});
    }

    LeveEventChange = (val, index) => {
        let arr = this.state.filterOptionData;
        arr.map((item, key)=>{
            if(key===index){
                item.value = val.value;
            }
            return item;
        })
        this.setState({filterOptionData: arr});
    }

    onFilterData = () => {
        this.props.onFilterData(this.state.filterOptionData);
        this.props.onHide();
    }


    render() {
        const{filterData, filterOptionData, datePickerFlag, leveFlag,addDisable}=this.state;
        return (
            <Col sm={4} className={!this.props.showFlag ? "multi-filter__div filter-show__hide" : "multi-filter__div" }>
                {this.state.filterOptionData.map((data, index) =>(
                    <div key={index} style={{display: 'flex', paddingTop: 10, justifyContent: "space-between"}}>
                        <div style={{width: "125px"}} >
                            <Select
                                style={{width: "120px"}}
                                name="filter"
                                placeholder="Column..."
                                options={filterData}
                                onChange={(val)=>this.filterChangeData(val, index)}
                            />
                        </div>

                        {!data.dateFlag ? (
                            <div style={{width: "125px"}}>
                                {(!data.leveFlag)&&(!data.eventFlag) ? (
                                    <Form.Control className=" w-100 h-100" type="text" name="number" placeholder="value" onChange={(evt)=>this.changeValue(evt, index)}/>
                                ):
                                    <Select
                                        style={{width: "120px"}}
                                        name="filter"
                                        placeholder="Column..."
                                        options={data.leveFlag?this.props.LevelData:this.props.EventData}
                                        onChange={(val)=>this.LeveEventChange(val, index)}
                                    />
                                }
                            </div>
                        ):
                            <div className="filter-header__option">
                                <DatePicker name="startdate" id="startdatetest" className="myDatePicker filter-date__picker" dateFormat="dd-MM-yyyy" selected={this.state.selectDate} onChange = {(value, e)=>this.onChangeDate(value, e, index, 'start')} />
                            </div>
                        }
                        {data.dateFlag && (
                             <div className="filter-header__option">
                                <DatePicker name="enddate" id="enddate" className="myDatePicker filter-date__picker" dateFormat="dd-MM-yyyy" selected={this.state.endDate} onChange = {(value, e)=>this.onChangeDate(value, e, index, 'end')} />
                            </div>
                        )}
                        <i className="fas fa-times" style={{ fontSize: 20, cursor: 'pointer', marginTop: 4}} onClick={()=>this.removeFilterOption(index, data.filterOption)}></i>
                    </div>
                ))}
                <div style={{padding:"20px 10px", fontSize: 12, display: 'flex'}}>
                    {(!addDisable) ?(
                        <span style={{cursor: "pointer"}} onClick={()=>this.addFilterOption()}><i className="fas fa-plus add-icon"></i>{trls('Add_filter')}</span>
                    )
                    :
                    null
                    }
                    <span style={{cursor: "pointer", marginLeft: "auto"}} onClick={()=>this.onFilterData()}><i className="fas fa-filter add-icon"></i>{trls('Filter')}</span>
                </div>
            </Col>
        );
    } 
}
export default FilterErrorLogs;
    