/* eslint-disable no-unused-vars */
import React, {Component} from 'react'
import { Row, Col, Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import $ from 'jquery';
import SessionManager from '../../factories/session_manage';
import API from '../../factories/api'
import Axios from 'axios';
import { BallBeat } from 'react-pure-loaders';
import { trls } from '../../factories/translate';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'datatables.net';
import * as Common from '../../factories/common';
import * as Auth from '../../factories/auth';
import * as authAction  from '../../actions/authAction';
import history from '../../history';
import AddHelperTextForm from './add_helper_text';
import UpdateHelperTextForm from './update_help_text';

class HelpText extends Component {
    _isMounted = false
    constructor(props) {
        super(props);
        this.state = {
            helpTextData:[],
            userUpdateData:[],
            loading:true,
            slideFormFlag: false,
            originFilterData: [],
            filterFlag: false,
            filterColunm: [
                {"label": 'page', "value": "Email", "type": 'text', "show": true},
                {"label": 'Title', "value": "userName", "type": 'text', "show": true},
                {"label": 'Edit', "value": "active", "type": 'text', "show": true},
                // {"label": 'Delete', "value": "action", "type": 'text', "show": true},
            ],
            userInfo: Auth.getUserInfo(), 
            userType: '',
            showAllUser: false,
            // updateHelpTextModal: false
        };
    }

    componentDidMount() {
        this._isMounted=true;
        this.getHelpTextData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps = nextProps => {
        this.setState({
            userType: nextProps.userType
        });
    };
    
    getHelpTextData (data) {    
        this._isMounted = true;
        this.setState({loading: true})
        var headers = SessionManager.shared().getAuthorizationHeader();
        Axios.get(API.GetAllHelpText, headers)
        .then(result => {
                if(this._isMounted){
                    if(!data){
                        this.setState({helpTextData: result.data, originFilterData: result.data});
                    }else{
                        this.setState({helpTextData: data});
                    }
                    this.setState({loading:false})
                    
                }
        })
        .catch(err => {
            if(err.response.status===401){
                history.push('/login')
            }
        })
        
    }

    // filter module
    filterOptionData = (filterOption) =>{
        let dataA = []
        dataA = Common.filterData(filterOption, this.state.originFilterData);
        if(!filterOption.length){
            dataA=null;
        }
        $('#project_table').dataTable().fnDestroy();
        this.getHelpTextData(dataA);
    }

    changeFilter = () => {
        if(this.state.filterFlag){
            this.setState({filterFlag: false})
        }else{
            this.setState({filterFlag: true})
        }
    }
    // filter module
    userUpdate = (id) => {
        this._isMounted = true;
        var headers = SessionManager.shared().getAuthorizationHeader();
        Axios.get(API.GetHelpText+id, headers)
        .then(response => {
            this.setState({helpTextUpdateData: response.data, updateHelpTextModal: true});
        })
        .catch(err => {
            if(err.response.status===401){
                history.push('/login')
            }
        });
    }

    userDelete = (id) => {
        var headers = SessionManager.shared().getAuthorizationHeader();
        confirmAlert({
            title: 'Confirm',
            message: 'Are you sure to do this.',
            buttons: [
              {
                label: trls('Delete'),
                onClick: () => {
                    Axios.post(API.DeleteHelpText+id,params,headers)
                    .then(result => {
                        this.setState({loading:true})
                        this.getHelpTextData();               
                    })
                    .catch(err => {
                        if(err.response.status===401){
                            history.push('/login')
                        }
                    });
                }
              },
              {
                label: 'Cancel',
                onClick: () => {}
              }
            ]
          });
        var params = {"id":id}
       
    }


    render() {
        const { filterColunm, helpTextData } = this.state;
        let helpTextDataList = helpTextData;
        
        return (
            <div className="order_div">
            <div className="content__header content__header--with-line">
                <h2 className="title">{trls('Help_Text')}</h2>
            </div>
            <div className="orders">
                <Row>
                    {/* <Col sm={6}>
                        <Button variant="primary" onClick={()=> this.setState({addHelpTextModal: true}) }><i className="fas fa-plus add-icon mr-2"></i>{trls('add_help_text')}</Button>
                    </Col> */}
                    <Col sm={6} className="has-search">
                        <div style={{display: 'flex', float: 'right'}}>
                            {/* <Button variant="light" onClick={()=>this.changeFilter()}><i className="fas fa-filter add-icon"></i>{trls('Filter')}</Button> */}
                            <div style={{marginLeft: 20}}>
                                <span className="fa fa-search form-control-feedback"></span>
                                <Form.Control className="form-control fitler" type="text" name="number"placeholder={trls("Quick_search")}/>
                            </div>
                        </div>
                    </Col>
                </Row>
                
                <div className="table-responsive credit-history">
                        <table id="example" className="place-and-orders__table table" width="99%">
                        <thead>
                            <tr>
                                {filterColunm.map((item, key)=>(
                                    <th className={!item.show ? "filter-show__hide" : ''} key={key}>
                                       {trls(item.label) ? trls(item.label) : ''}
                                    </th>
                                    )
                                )}
                            </tr>
                        </thead>
                        {helpTextDataList && !this.state.loading &&(<tbody >
                            {
                                helpTextDataList.map((data,i) =>(
                                    <tr id={data.id} key={i}>
                                        <td >{trls(data.page)}</td>
                                        <td >{data.title}</td>
                                        <td >
                                            <i className="fas fa-edit add-icon" onClick={()=>this.userUpdate(data.id)}></i>
                                        </td>
                                        {/* <td style={{cursor:'pointer'}}>
                                            <i className ="fa fa-trash" onClick={()=>this.userDelete(data.id)}></i>
                                        </td> */}
                                    </tr>
                            ))
                            }
                        </tbody>)}
                    </table>
                    { this.state.loading&& (
                        <div className="col-md-4 offset-md-4 col-xs-12 loading" style={{textAlign:"center"}}>
                            <BallBeat
                                color={'#222A42'}
                                loading={this.state.loading}
                            />
                        </div>
                    )}
                </div>
            </div>
            <AddHelperTextForm
                show={this.state.addHelpTextModal}
                onHide={() => this.setState({addHelpTextModal: false})}
                onGetUser={() => this.getHelpTextData()}
            />
            <UpdateHelperTextForm
                show={this.state.updateHelpTextModal}
                onHide={() => this.setState({updateHelpTextModal: false})}
                onGetUser={() => this.getHelpTextData()}
                helpTextUpdateData = {this.state.helpTextUpdateData}
            />
            
        </div>
        );
  }
}

export default HelpText;
