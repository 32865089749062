/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import React from "react";
import * as authAction from "../../actions/authAction";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import Message from "../../components/message";
import { trls } from "../../factories/translate";
import Pageloadspiiner from "../../components/page_load_spinner";
import ReactFlagsSelect from "react-flags-select";
const mapStateToProps = (state) => ({ ...state.auth });

const mapDispatchToProps = (dispatch) => ({
  authLogin: (params) => dispatch(authAction.fetchLoginData(params)),
  changeLan: (params) => dispatch(authAction.changeLan(params)),
});

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      lang: [
        { value: "English", label: "GB" },
        { value: "Dutch", label: "NL" },
        { value: "German", label: "DE" },
        { value: "French", label: "FR" },
      ],
      selectLangValue: window.localStorage.getItem("eijf_lang"),
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const clientFormData = new FormData(event.target);
    const data = {};
    for (let key of clientFormData.keys()) {
      data[key] = clientFormData.get(key);
    }
    this.props.authLogin(data);
  };

  changeLangauge = (val) => {
    let lang = this.state.lang;
    lang = lang.filter((item, key) => item.label === val);
    this.props.changeLan(lang[0]);
    this.setState({selectLangValue: lang[0].value});
  };

  changeShowPrice = (evt) => {
    localStorage.setItem("eijf_showPrice", evt.target.checked);
  };

  render() {
    const { loggedUserInfo, lang, selectLangValue } = this.state;
    let selectLang = lang.filter((item, key) => item.value === selectLangValue);
    const { loading } = this.props;

    return (
      <div className="container">
        <div className="col-xl-5 col-lg-7 col-md-12  vertical-center">
          <Row>
            <div className="login-side-div">
              <img
                src="https://www.eijffinger.com/Themes/Eijffinger/Content/images/logo.svg"
                alt="appzmakerz"
                className="login-logo-img"
              ></img>
              <p className="login-side__footer-text">eijffinger.com</p>
            </div>

            <Col>
              <div className="container mb-2 mx-4">
                <p className="login-title">{trls("login")}</p>
              </div>
              <Form
                className="container login-form"
                onSubmit={this.handleSubmit}
              >
                <div style={{display: 'flex', justifyContent: 'end'}}>
                  <ReactFlagsSelect
                      countries={["GB", "NL", "DE", "FR"]}
                      selected={selectLang[0].label}
                      showSelectedLabel={false}
                      showOptionLabel={false}
                      fullWidth={false}
                      selectedSize={14}
                      optionsSize={14}
                      onSelect={(val) => this.changeLangauge(val)}
                  />
                </div>
                <Form.Group controlId="form">
                  <Col className="login-form__control">
                    <Form.Control
                      type="text"
                      name="username"
                      className="login-input-email"
                      placeholder={trls("Email")}
                      required
                    />
                    <label className="placeholder-label__login">
                      {trls("Email")}
                    </label>
                  </Col>
                </Form.Group>
                <Form.Group controlId="form">
                  <Col className="login-form__control">
                    <Form.Control
                      type="password"
                      name="password"
                      className="login-input-password"
                      placeholder={trls("Password")}
                      required
                    />
                    <label className="placeholder-label__login">
                      {trls("Password")}
                    </label>
                  </Col>
                </Form.Group>
                <Form.Group controlId="form">
                  <Form.Check
                    type="checkbox"
                    label={trls("ShowPrice")}
                    style={{ color: "#B9C0CE" }}
                    onChange={(evt) => this.changeShowPrice(evt)}
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  style={{ width: "100%", height: 42 }}
                  onClick={() => this.setState({ modalResumeShow: true })}
                >
                  {trls("login")}
                </Button>
                <p className="text-xs-center" style={{ marginTop: 10 }}>
                  <Link to="/forgot-password" className="back-to_signin">
                    {trls("Forgot_password")}
                  </Link>
                </p>
                <hr />
                <p className="text-xs-center" style={{ marginTop: 10 }}>
                  {trls("Not_Registered_Yet")}
                  <Link to="/registration" className="">
                    <a> {trls("Register_Here")}</a>
                  </Link>
                </p>
                <p className="text-xs-center" style={{ marginTop: 10 }}>
                  {trls("Customer_yet")} {trls("Contact_us_here")}{" "}
                  <a
                    href="mailto:verkoop@eijffinger.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "underline" }}
                  >
                    verkoop@eijffinger.com
                  </a>
                </p>
                <Message message={this.props.error} type={"error"} />
              </Form>
            </Col>
          </Row>
        </div>
        <Pageloadspiiner loading={loading} text={""} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
