/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import { trls } from "../../factories/translate";
import { Container, Row, Col, Button } from "react-bootstrap";
import SessionManager from "../../factories/session_manage";
import API from "../../factories/api";
// import * as Queries  from '../../factories/queries'
import Axios from "axios";
import * as authAction from "../../actions/authAction";
import Pageloadspiiner from "../../components/page_load_spinner";
import history from "../../history";
import * as Auth from "../../factories/auth";
import "../Dashboard/dashboard_manage_style.css";
import $ from "jquery";
import HelpTextModal from "../Dashboard/HelpText";
import dayjs from "dayjs";
import Slideshow from "../../components/slideshow";
const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  blankdispatch: () => dispatch(authAction.blankdispatch()),
});

class Dashboard extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      pageLodingFlag: true,
      dashBoardFlag: false,
      lastOrdersFlag: false,
      lastDeleiversFlag: false,
      lastOutstanding: false,
      newFlag: false,
      newsArrayData: [],
      newsViewData: [],
      loginUser: Auth.getLoggedUserInfo(),
      newsDataPageSize: 0,
      newsLanguage: {
        English: {
          title: "subjectEnglish",
          subtitle: "subTitleEnglish",
          text: "textEnglish",
          locale: "en",
        },
        Dutch: {
          title: "subjectDutch",
          subtitle: "subTitleDutch",
          text: "textDutch",
          locale: "nl",
        },
        German: {
          title: "subjectGerman",
          subtitle: "subTitleGerman",
          text: "textGerman",
          locale: "de",
        },
        French: {
          title: "subjectFrench",
          subtitle: "subTitleFrench",
          text: "textFrench",
          locale: "fr",
        },
      },
      HelpTextModal: false,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this.getNewsData();
  }

  getHelpText = () => {
    const pageName = "News";
    const headers = SessionManager.shared().getAuthorizationHeader();
    const lang = localStorage.getItem("eijf_lang");

    Axios.get(API.GetHelpTextByName + pageName, headers)
      .then((result) => {
        let helpURL = "";

        if (lang === "Dutch") {
          helpURL = result.data.contentDutch;
        } else if (lang === "English") {
          helpURL = result.data.contentEnglish;
        } else if (lang === "French") {
          helpURL = result.data.contentFrench;
        } else {
          helpURL = result.data.contentGerman;
        }
        const a = document.createElement("a");
        a.setAttribute("href", helpURL);
        a.setAttribute("target", "_blank");
        a.click();
      })
  };

  getNewsData = () => {
    this._isMounted = true;
    const headers = SessionManager.shared().getAuthorizationHeader();

    Axios.get(API.GetAllNews, headers).then((result) => {
      if (this._isMounted) {
        const newsData = result.data;
        newsData.sort(function (a, b) {
          return new Date(b.startDate) - new Date(a.startDate);
        });

        const newsDataPageSize = newsData.length;
        this.setState({
          newsArrayData: newsData,
          newsViewData: newsData,
          newsDataPageSize: newsDataPageSize,
          newFlag: true,
          pageLodingFlag: false,
        });
      }
      
      window.setTimeout(function () {
        $(".welcome-message").addClass("d-none");
        localStorage.removeItem("welcome_message");
      }, 4000);
    });
  };

  render() {
    const {
      pageLodingFlag,
      newsViewData,
      loginUser,
      newFlag,
      newsLanguage,
    } = this.state;
    let userInfo = Auth.getUserInfo();
    let lodingFlag = pageLodingFlag;

    if (userInfo.role === "Sales") {
      history.push("/user");
    }
    if (newFlag && userInfo.role === "Administrator") {
      lodingFlag = false;
    }
    if (newFlag && userInfo.role !== "Administrator") {
      lodingFlag = false;
    }
    let newsTitle,
      newsText,
      newsSubtitle,
      locale = "";
    var lang = localStorage.getItem("eijf_lang");

    if (loginUser.Language === lang) {
      newsTitle = newsLanguage[loginUser.Language].title;
      newsSubtitle = newsLanguage[loginUser.Language].subtitle;
      newsText = newsLanguage[loginUser.Language].text;
      locale = newsLanguage[loginUser.Language].locale;
    } else {
      newsTitle = newsLanguage[lang].title;
      newsSubtitle = newsLanguage[lang].subtitle;
      newsText = newsLanguage[lang].text;
      locale = newsLanguage[lang].locale;
    }

    let SapCustomerName = Auth.getLoggedUserInfo().SapCustomerName;
    let Welcome_Message = localStorage.getItem("welcome_message");

    // let dash18 = JSON.parse(localStorage.getItem('eijf_loggedUser'));
    // console.log(dash18.Currency,"182 klk")
    return (
      <Container>
        {Welcome_Message ? (
          <div className="alert alert-success mb-4 welcome-message">
            Welcome <strong>{SapCustomerName}</strong> in your own Eijffinger
            portal
          </div>
        ) : (
          false
        )}
        {userInfo.role !== "Administrator" ? (
          <Row>
            <Col sm={6}></Col>
          </Row>
        ) : (
          false
        )}

        <div className="card border-news mt-2 mb-4">
          <div className="card-header border-news news-header">
            <h2 className="title">{trls("News")}</h2>
          </div>

          <div className="card-body">
            <Col className="p-0">
              <div className="news">
                {newsViewData &&
                  newsViewData.map(
                    (data, i) =>
                      data[newsText] && (
                        <div className="news-item" id={i} key={i}>
                          <div className="news-image">
                            <Slideshow
                              images={data.documents.filter(
                                (x) =>
                                  (x.documentUrl.includes(".jpg") ||
                                    x.documentUrl.includes(".png")) &&
                                  x.isPrimary
                              )}
                            />
                          </div>
                          <div className="subtitle">{data[newsSubtitle]}</div>
                          <h2 className="title">{data[newsTitle]}</h2>
                          <div className="date">
                            {dayjs(data.startDate).format("DD MMMM YYYY")}
                          </div>
                          <div className="text">{data[newsText]}</div>
                        </div>
                      )
                  )}
              </div>
            </Col>
            <Pageloadspiiner loading={lodingFlag} text={""} />
            <HelpTextModal
              show={this.state.HelpTextModal}
              onHide={() => this.setState({ HelpTextModal: false })}
              HelpTextTitle={this.state.HelpTextTitle}
              HelpTextDescription={this.state.HelpTextDescription}
            />
          </div>
        </div>
      </Container>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
