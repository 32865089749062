import React, { Component } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import SessionManager from "../../factories/session_manage";
import API from "../../factories/api";
import Axios from "axios";
import { trls } from "../../factories/translate";
import * as Common from "../../factories/common";
import history from "../../history";
import DatePicker from "react-datepicker";
//import CKEditor from "react-ckeditor-component";
import Select from "react-select";
import Sweetalert from "sweetalert";

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({});

class Addnewform extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    const { newsUpdateData, mode } = this.props;
    this.state = {
      val1: "",
      val2: "1",
      startDate:
        mode === "add" ? new Date() : new Date(newsUpdateData.startDate),
      endDate: mode === "add" ? new Date() : new Date(newsUpdateData.endDate),
      textDutch: mode === "add" ? "" : newsUpdateData.textDutch,
      textEnglish: mode === "add" ? "" : newsUpdateData.textEnglish,
      textGerman: mode === "add" ? "" : newsUpdateData.textGerman,
      textFrench: mode === "add" ? "" : newsUpdateData.textFrench,
      subjectDutch: mode === "add" ? "" : newsUpdateData.subjectDutch,
      subjectEnglish: mode === "add" ? "" : newsUpdateData.subjectEnglish,
      subjectGerman: mode === "add" ? "" : newsUpdateData.subjectGerman,
      subjectFrench: mode === "add" ? "" : newsUpdateData.subjectFrench,
      subTitleDutch: mode === "add" ? "" : newsUpdateData.subTitleDutch,
      subTitleEnglish: mode === "add" ? "" : newsUpdateData.subTitleEnglish,
      subTitleGerman: mode === "add" ? "" : newsUpdateData.subTitleGerman,
      subTitleFrench: mode === "add" ? "" : newsUpdateData.subTitleFrench,
      documents: [],
      selectedDocuments: [],
      customerGroupCodes:
        mode === "add" ? [] : newsUpdateData.customerGroupCodes,
      selectedCustomerGroups: [],
      customerGroupsLoading: true,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this.getGallery();
    this.getCustomerGroups();
  }

  getCustomerGroups() {
    this.setState({ customerGroupsLoading: true });
    var headers = SessionManager.shared().getAuthorizationHeader();
    Axios.post(API.GetCustomerGroup, {}, headers)
      .then((result) => {
        //selected:this.state.customerGroupCodes.includes(element.Code.toString())
        var selectData = [];
        var selectedOptions = [];
        result.data.value.forEach((element, index) => {
          selectData.push({
            label: element.Name,
            value: element.Code.toString(),
            key: index,
          });
          if (this.state.customerGroupCodes.includes(element.Code.toString())) {
            selectedOptions.push({
              label: element.Name,
              value: element.Code.toString(),
              key: index,
            });
          }
        });

        this.setState({
          customerGroupList: selectData,
          selectedCustomerGroups: selectedOptions,
          customerGroupsLoading: false,
        });
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          this.displayError(err);
        }
      });
  }

  getGallery() {
    //get selected
    let selected = [];
    if (this.props.newsUpdateData) {
      if (
        this.props.newsUpdateData.documents &&
        this.props.newsUpdateData.documents.length > 0
      ) {
        //filter images
        const images = this.props.newsUpdateData.documents.filter(
          (x) =>
            x.documentUrl.includes(".jpg") || x.documentUrl.includes(".png")
        );
        images.forEach((newsDocument) => {
          const doc = {
            documentId: newsDocument.id,
            isPrimary: newsDocument.isPrimary,
          };
          selected.push(doc);
        });
      }
    }
    this.setState({ selectedDocuments: selected });

    //get images
    var headers = SessionManager.shared().getAuthorizationHeader();
    Axios.get(API.GetAllDocuments, headers)
      .then((result) => {
        var items = result.data.filter(
          (item) =>
            item.documentUrl.includes(".jpg") ||
            item.documentUrl.includes(".png")
        );
        this.setState({ documents: items });
      })
      .catch((err) => {});
  }

  handleSubmit = (event) => {
    this._isMounted = true;
    var headers = SessionManager.shared().getAuthorizationHeader();
    event.preventDefault();
    const clientFormData = new FormData(event.target);
    //clientFormData.append("file",this.state.imageFile)
    const data = {};

    for (let key of clientFormData.keys()) {
      data[key] = clientFormData.get(key);
    }

    const { startDate, endDate, selectedCustomerGroups } = this.state;
    const { newsUpdateData } = this.props;
    let group = [];
    if (selectedCustomerGroups.length > 0) {
      group = selectedCustomerGroups.map((item) => item.value);
    }
    let params = {
      subjectDutch: data.subjectDutch,
      subjectEnglish: data.subjectEnglish,
      subjectGerman: data.subjectGerman,
      subjectFrench: data.subjectFrench,
      subTitleDutch: data.subTitleDutch,
      subTitleEnglish: data.subTitleEnglish,
      subTitleGerman: data.subTitleGerman,
      subTitleFrench: data.subTitleFrench,
      textDutch: data.textDutch,
      textEnglish: data.textEnglish,
      textGerman: data.textGerman,
      textFrench: data.textFrench,
      startDate: startDate,
      endDate: endDate,
      customerGroupCodes: group,
    };

    if (this.props.mode === "add") {
      Axios.post(API.Postnews, params, headers)
        .then((result) => {
          if (this.state.selectedDocuments.length > 0) {
            Axios.post(
              API.AddNewsDocuments + result.data.id,
              this.state.selectedDocuments,
              headers
            )
              .then((result) => {
                this.onHide();
              })
              .catch((err) => {
                this.displayError(err);
              });
          } else {
            this.onHide();
          }
        })
        .catch((err) => {
          this.displayError(err);
        });
    } else {
      params = {
        subjectDutch: data.subjectDutch,
        subjectEnglish: data.subjectEnglish,
        subjectGerman: data.subjectGerman,
        subjectFrench: data.subjectFrench,
        subTitleDutch: data.subTitleDutch,
        subTitleEnglish: data.subTitleEnglish,
        subTitleGerman: data.subTitleGerman,
        subTitleFrench: data.subTitleFrench,
        textDutch: data.textDutch,
        textEnglish: data.textEnglish,
        textGerman: data.textGerman,
        textFrench: data.textFrench,
        startDate: startDate,
        endDate: endDate,
        newsDocuments: data.newsDocuments,
        customerGroupCodes: group,
      };
      headers = SessionManager.shared().getAuthorizationHeader();
      Axios.post(API.UpdateNews + newsUpdateData.id, params, headers)
        .then((result) => {
          //add
          if (this.state.selectedDocuments.length > 0) {
            let promises = [];

            //removable documents
            const currentImages = newsUpdateData.documents
              .filter(
                (x) =>
                  x.documentUrl.includes(".jpg") ||
                  x.documentUrl.includes(".png")
              )
              .map((item) => item.id);
            const removeImages = [...currentImages];
            this.state.selectedDocuments.forEach((document) => {
              const index = removeImages.indexOf(document.documentId);
              if (index > -1) {
                removeImages.splice(index, 1);
              }
            });
            if (removeImages.length > 0) {
              removeImages.forEach((documentToRemove) => {
                promises.push(
                  Axios.post(
                    API.DeleteNewsDocument +
                      newsUpdateData.id +
                      "/Document/" +
                      documentToRemove,
                    {},
                    headers
                  )
                );
              });
            }

            //add new
            promises.push(
              Axios.post(
                API.AddNewsDocuments + newsUpdateData.id,
                this.state.selectedDocuments,
                headers
              )
            );
            Promise.all(promises)
              .then((responses) => {
                this.onHide();
              })
              .catch((err) => {
                this.displayError(err);
              });
          } else {
            this.onHide();
          }
        })
        .catch((err) => {
          this.displayError(err);
        });
    }
  };

  getRoles(value) {
    this.setState({ val1: value.value });
  }

  onHide = () => {
    this.props.onGetnewData();
    this.props.onHide();
    Common.hideSlideForm();
  };

  displayError = (err) => {
    if (err.response) {
      if (err.response.status === 401) {
        history.push("/login");
      } else {
        Sweetalert({
          text: "Something went wrong, please try again later",
          icon: "error",
        });
      }
    }
  };

  setPrimary = (e, id) => {
    e.stopPropagation();
    const docs = this.state.selectedDocuments;
    const document = docs.filter((item) => item.documentId === id)[0];
    document.isPrimary = e.target.checked;
    this.setState({ selectedDocuments: docs });
  };

  selectDocument = (e, id) => {
    const hasDocument =
      this.state.selectedDocuments.filter((item) => item.documentId === id)
        .length > 0;
    const doc = {
      documentId: id,
      isPrimary: false,
    };
    let docs = this.state.selectedDocuments;
    if (!hasDocument) {
      docs.push(doc);
    } else {
      const find = (element) => element.documentId === id;
      docs.splice(docs.findIndex(find), 1);
    }
    this.setState({ selectedDocuments: docs });
  };

  isPrimary = (id) => {
    const docs = this.state.selectedDocuments.filter(
      (item) => item.documentId === id
    );
    if (docs) {
      if (docs[0]) {
        return docs[0].isPrimary;
      }
    }
    return false;
  };

  render() {
    const { mode } = this.props;

    const {
      customerGroupList,
      customerGroupsLoading,
      selectedCustomerGroups,
      textDutch,
      textEnglish,
      textGerman,
      textFrench,
      subjectDutch,
      subjectEnglish,
      subjectGerman,
      subjectFrench,
      subTitleDutch,
      subTitleEnglish,
      subTitleGerman,
      subTitleFrench,
      documents,
      selectedDocuments,
    } = this.state;
    console.log("documents", documents);
    return (
      <div className="slide-form__controls open" style={{ height: "100%" }}>
        <div style={{ marginBottom: 30 }}>
          <i
            className="fas fa-times slide-close"
            style={{ fontSize: 20, cursor: "pointer" }}
            onClick={() => this.onHide()}
          ></i>
        </div>
        <Form className="container" onSubmit={this.handleSubmit}>
          <Col className="title add-product">
            {mode === "add"
              ? trls("Add News")
              : mode === "update"
              ? trls("Edit News")
              : trls("View News")}
          </Col>
          <Form.Group as={Row} controlId="controlStartDate">
            <Col>
              <DatePicker
                name="startdate"
                className="myDatePicker"
                dateFormat="dd-MM-yyyy"
                selected={this.state.startDate}
                onChange={(date) => this.setState({ startDate: date })}
                readOnly={mode === "view" ? true : false}
              />
              <label className="placeholder-label">{trls("StartDate")}</label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="controlEndDate">
            <Col>
              <DatePicker
                name="enddate"
                className="myDatePicker"
                dateFormat="dd-MM-yyyy"
                selected={this.state.endDate}
                onChange={(date) => this.setState({ endDate: date })}
                readOnly={mode === "view" ? true : false}
              />
              <label className="placeholder-label">{trls("EndDate")}</label>
            </Col>
          </Form.Group>
          <h3>Image</h3>
          <div className="gallery-wrapper">
            <div className="gallery">
              {documents &&
                documents.map((document, i) => (
                  <div
                    className={
                      selectedDocuments.filter(
                        (item) => item.documentId === document.id
                      ).length > 0
                        ? "selected"
                        : ""
                    }
                    id={document.id}
                    key={i}
                    onClick={(e) => {
                      this.selectDocument(e, document.id);
                    }}
                  >
                    <img alt={document.id} src={document.documentUrl} />
                    <span>{document.id}</span>
                    <div className="primary">
                      <input
                        name={document.id}
                        type="checkbox"
                        checked={this.isPrimary(document.id) ? true : false}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          this.setPrimary(e, document.id);
                        }}
                      />
                      <label htmlFor={document.id}>primary</label>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <h3>Customergroup</h3>
          <Form.Group as={Row} controlId="customerGroup">
            <Col>
              <Select
                options={customerGroupList}
                isMulti
                isLoading={customerGroupsLoading}
                onChange={(val) =>
                  this.setState({ selectedCustomerGroups: val })
                }
                value={selectedCustomerGroups}
              ></Select>
              <label className="placeholder-label">
                {trls("CustomerGroupCode")}
              </label>
            </Col>
          </Form.Group>
          <h3>Dutch</h3>
          <Form.Group as={Row} controlId="controlSubjectDutch">
            <Col>
              <Form.Control
                type="text"
                name="subjectDutch"
                required
                defaultValue={subjectDutch}
                placeholder={trls("Subject Dutch")}
                readOnly={mode === "view" ? true : false}
              />
              <label className="placeholder-label">
                {trls("Subject Dutch")}
              </label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="controlSubTitleDutch">
            <Col>
              <Form.Control
                type="text"
                name="subTitleDutch"
                required
                defaultValue={subTitleDutch}
                placeholder={trls("Subtitle Dutch")}
                readOnly={mode === "view" ? true : false}
              />
              <label className="placeholder-label">
                {trls("Subtitle Dutch")}
              </label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="controlTextDutch">
            <Col>
              <Form.Control
                as="textarea"
                rows={3}
                name="textDutch"
                required
                defaultValue={textDutch}
                placeholder={trls("Text Dutch")}
                readOnly={mode === "view" ? true : false}
              />
              <label className="placeholder-label">{trls("Text Dutch")}</label>
            </Col>
          </Form.Group>
          <h3>English</h3>
          <Form.Group as={Row} controlId="controlSubjectEnglish">
            <Col>
              <Form.Control
                type="text"
                name="subjectEnglish"
                defaultValue={subjectEnglish}
                placeholder={trls("Subject English")}
                readOnly={mode === "view" ? true : false}
              />
              <label className="placeholder-label">
                {trls("Subject English")}
              </label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="controlSubTitleEnglish">
            <Col>
              <Form.Control
                type="text"
                name="subTitleEnglish"
                defaultValue={subTitleEnglish}
                placeholder={trls("Subtitle English")}
                readOnly={mode === "view" ? true : false}
              />
              <label className="placeholder-label">
                {trls("Subtitle English")}
              </label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="controlTextEnglish">
            <Col>
              <Form.Control
                as="textarea"
                rows={3}
                name="textEnglish"
                defaultValue={textEnglish}
                placeholder={trls("Text English")}
                readOnly={mode === "view" ? true : false}
              />
              <label className="placeholder-label">
                {trls("Text English")}
              </label>
            </Col>
          </Form.Group>
          <h3>German</h3>
          <Form.Group as={Row} controlId="controlSubjectGerman">
            <Col>
              <Form.Control
                type="text"
                name="subjectGerman"
                defaultValue={subjectGerman}
                placeholder={trls("Subject German")}
                readOnly={mode === "view" ? true : false}
              />
              <label className="placeholder-label">
                {trls("Subject German")}
              </label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="controlSubtitleGerman">
            <Col>
              <Form.Control
                type="text"
                name="subTitleGerman"
                defaultValue={subTitleGerman}
                placeholder={trls("Subtitle German")}
                readOnly={mode === "view" ? true : false}
              />
              <label className="placeholder-label">
                {trls("Subtitle German")}
              </label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="controlTextGerman">
            <Col>
              <Form.Control
                as="textarea"
                rows={3}
                name="textGerman"
                defaultValue={textGerman}
                placeholder={trls("Text German")}
                readOnly={mode === "view" ? true : false}
              />
              <label className="placeholder-label">{trls("Text German")}</label>
            </Col>
          </Form.Group>
          <h3>French</h3>
          <Form.Group as={Row} controlId="controlSubjectFrench">
            <Col>
              <Form.Control
                type="text"
                name="subjectFrench"
                defaultValue={subjectFrench}
                placeholder={trls("Subject French")}
                readOnly={mode === "view" ? true : false}
              />
              <label className="placeholder-label">
                {trls("Subject French")}
              </label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="controlSubtitleFrench">
            <Col>
              <Form.Control
                type="text"
                name="subTitleFrench"
                defaultValue={subTitleFrench}
                placeholder={trls("Subtitle French")}
                readOnly={mode === "view" ? true : false}
              />
              <label className="placeholder-label">
                {trls("Subtitle French")}
              </label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="controlTextFrench">
            <Col>
              <Form.Control
                as="textarea"
                rows={3}
                name="textFrench"
                defaultValue={textFrench}
                placeholder={trls("Text French")}
                readOnly={mode === "view" ? true : false}
              />
              <label className="placeholder-label">{trls("Text French")}</label>
            </Col>
          </Form.Group>
          <Form.Group>
            <Col>
              <Button type="submit" style={{ width: "15%" }}>
                {trls("Save")}
              </Button>
            </Col>
          </Form.Group>
        </Form>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Addnewform);
