/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, {Component} from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import { trls } from '../../factories/translate';
import API from '../../factories/api';
import * as authAction  from '../../actions/authAction';
import SessionManager from '../../factories/session_manage';
import Axios from 'axios';
import $ from 'jquery';

const mapStateToProps = state => ({ 
    ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({

});

class UpdateDocumentForm extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.myInput = React.createRef(); 
        this.state = {  
            documentLanguage: '',
            name: '',
            documentLanguage_name:  [{"value":"English","label":"English"},{"value":"Dutch","label":"Dutch"},
                                    {"value":"German","label":"German"},{"value":"French","label":"French"}
                                ],
            documentTypeUpdateData:'',
            customerGroup: ''
        };
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    
    componentDidMount() {
    }

    handleSubmit = (e, formData, inputs) => {
        this._isMounted = true;
        e.preventDefault();
        var headers = SessionManager.shared().getAuthorizationHeader();
        var id = this.state.documentTypeUpdateData.id;
        let params ={
            "id":id,
            "documentLanguage": this.state.documentLanguage,
            "name": this.state.name,
            "customerGroupCode": String(this.state.customerGroup.value)
        }

        Axios.put(API.UpdateDocumentType+id, params, headers)
        .then(result => {
            if(this._isMounted){
                this.props.onHide()
                this.props.onGetUser()
                this.removeState();
            }
        })
        .catch(err => {
            if(err.response.status===400){
                $('.add-error').removeClass('d-none')
                $('.add-error').text(err.response.data)
            }
        });
    }

    removeState = () => {
        this.setState({
            documentLanguage: '',
            name: '',
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        $('.add-error').addClass('d-none')
    }

    hideModel = () => {
        this.props.onHide();
        this.removeState()
    }

    onShow = () => {
        const { customerGroupList } = this.props;
        var groupTemp = customerGroupList.filter((item, key)=>item.value===Number(this.props.documentTypeUpdateData.customerGroupCode));
        this.setState({
            documentTypeUpdateData: this.props.documentTypeUpdateData,
            documentLanguage: this.props.documentTypeUpdateData.documentLanguage,
            name: this.props.documentTypeUpdateData.name,
            customerGroup: groupTemp[0]
        })
    }
    

    render(){
        const { customerGroupList } = this.props;
        const { customerGroup } = this.state;
        return (
            <Modal
                show={this.props.show}
                onHide={this.hideModel}
                onShow={this.onShow}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop= "static"
                centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {trls('Edit_DocumentType')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="alert alert-danger add-error d-none" role="alert"></div>
                <Form onSubmit = { this.handleSubmit }>
                    <Form.Group as={Row} controlId="name">
                        <Col className="product-text">
                            <Form.Control defaultValue = {this.state.documentTypeUpdateData.documentLanguage} defaultValue={this.state.name} onChange={this.handleChange} type="text" name="name" required />
                            <label className="placeholder-label">{trls('Name')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="documentLanguage">
                        <Col>
                            <Select
                            name="documentLanguage"
                            options={this.state.documentLanguage_name}
                            onChange={val => this.setState({documentLanguage: val.value})}
                            value = {{value: this.state.documentLanguage, label: this.state.documentLanguage}}
                            />

                            <label className="placeholder-label">{trls('documentLanguage')}</label>

                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="documentLanguage">
                        <Col>
                            <Select
                                name="documentLanguage"
                                options={customerGroupList}
                                onChange={val => this.setState({customerGroup: val})}
                                value = {customerGroup}
                            />

                            <label className="placeholder-label">{trls('documentLanguage')}</label>

                        </Col>
                    </Form.Group>

                    <Form.Group style={{textAlign:"center"}}>
                        <Button type="Submit" >{trls("Save")}</Button>
                    </Form.Group>
                </Form>    
            </Modal.Body>
            </Modal>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateDocumentForm);