import { TRANSLATIONS } from '../constants/translateLanguage';
export const trls = (translate_key) => {
    var lang = window.localStorage.getItem('eijf_lang');
    // var role = window.localStorage.getItem('eijf_role');
    // if(role==='Customer') {
    //     return(
    //         lang!=="undefined" ? TRANSLATIONS[lang][translate_key] : TRANSLATIONS["Dutch"][translate_key]
    //     )
    // } else {
    //     return(
    //         TRANSLATIONS["Dutch"][translate_key]
    //     )
    // }
    return(
        lang!=="undefined" ? TRANSLATIONS[lang][translate_key] : TRANSLATIONS["Dutch"][translate_key]
    )
};