/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, {Component} from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import { trls } from '../../factories/translate';
import API from '../../factories/api';
import * as authAction  from '../../actions/authAction';
import SessionManager from '../../factories/session_manage';
import Axios from 'axios';

const mapStateToProps = state => ({ 
    ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({

});

class UpdateHelperTextForm extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.myInput = React.createRef(); 
        this.state = {  
            page: '',
            title: '',
            contentDutch : '',
            contentEnglish : '',
            contentGerman : '',
            contentFrench : '',
            pageName:  [{"value":"News","label":trls('News')},{"value":"Place_an_order","label":trls('Place_an_order')},
                        {"value":"Place_a_sample_order","label":trls('Place_a_sample_order')},
                        {"value":"Orders","label":trls('Orders')},{"value":"Deliveries","label":trls('Deliveries')},
                        {"value":"Salesinvoices","label":trls('Salesinvoices')},
                        {"value":"Open_invoices","label":trls('Open_invoices')},{"value":"Returns","label":trls('Returns')},
                        {"value":"Maintenance","label":trls('Maintenance')}

                    ],
            page_val: '',
        helpTextUpdateData:''
        };
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    
    componentDidMount() {
    }

    handleSubmit = (e, formData, inputs) => {
        this._isMounted = true;
        e.preventDefault();
        var headers = SessionManager.shared().getAuthorizationHeader();
        var id = this.state.helpTextUpdateData.id;
        let params ={
            "page": this.state.page,
            "title": this.state.title,
            "contentDutch": this.state.contentDutch,
            "contentEnglish": this.state.contentEnglish,
            "contentGerman": this.state.contentGerman,
            "contentFrench": this.state.contentFrench
        }

        Axios.post(API.UpdateHelpText+id, params, headers)
        .then(result => {
            if(this._isMounted){
                this.props.onHide()
                this.props.onGetUser()
                this.removeState();
            }
        })
        .catch(err => {
        });
    }

    removeState = () => {
        this.setState({
            page: '',
            title: '',
            contentDutch : '',
            contentEnglish : '',
            contentGerman : '',
            contentFrench : '',
            page_val: ''
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    hideModel = () => {
        this.props.onHide();
        this.removeState()
    }
    onShow = () => {
        this.setState({
            helpTextUpdateData: this.props.helpTextUpdateData,
            page: this.props.helpTextUpdateData.page,
            title: this.props.helpTextUpdateData.title,
            contentDutch : this.props.helpTextUpdateData.contentDutch,
            contentEnglish : this.props.helpTextUpdateData.contentEnglish,
            contentGerman : this.props.helpTextUpdateData.contentGerman,
            contentFrench : this.props.helpTextUpdateData.contentFrench,
        })
    }
    

    render(){
        return (
            <Modal
                show={this.props.show}
                onHide={this.hideModel}
                onShow={this.onShow}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop= "static"
                centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {trls('Edit_help_text')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit = { this.handleSubmit }>
                    <Form.Group as={Row} controlId="page_name">
                        <Col>
                
                            <Select
                            name="page_name"
                            options={this.state.pageName}
                            onChange={val => this.setState({page: val.value})}
                            value = {{value:this.state.page,label:trls(this.state.page)}}
                            />

                            <label className="placeholder-label">{trls('page')}</label>

                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="title">
                        <Col className="product-text">
                            <Form.Control defaultValue = {this.state.helpTextUpdateData.page} defaultValue={this.state.title} onChange={this.handleChange} type="text" name="title" required />
                            <label className="placeholder-label">{trls('Title')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="contentDutch">
                        <Col className="product-text">
                            <Form.Control type="text" name="contentDutch" required defaultValue={this.state.contentDutch} onChange={this.handleChange}/>
                            <label className="placeholder-label">{trls('contentDutch')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="contentEnglish">
                        <Col className="product-text">
                            <Form.Control type="text" name="contentEnglish" required defaultValue={this.state.contentEnglish} onChange={this.handleChange}/>
                            <label className="placeholder-label">{trls('contentEnglish')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="contentGerman">
                        <Col className="product-text">
                            <Form.Control type="text" name="contentGerman" required defaultValue={this.state.contentGerman} onChange={this.handleChange}/>
                            <label className="placeholder-label">{trls('contentGerman')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="contentFrench">
                        <Col className="product-text">
                            <Form.Control type="text" name="contentFrench" required defaultValue={this.state.contentFrench} onChange={this.handleChange}/>
                            <label className="placeholder-label">{trls('contentFrench')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group style={{textAlign:"center"}}>
                        <Button type="Submit" >{trls("Save")}</Button>
                    </Form.Group>
                </Form>    
            </Modal.Body>
            </Modal>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateHelperTextForm);