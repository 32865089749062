/* eslint-disable no-unused-vars */
import React, {Component} from 'react'
import {  Button, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as authAction  from '../../actions/authAction';
import { trls } from '../../factories/translate';


const mapStateToProps = state => ({ 
    ...state.auth,
});
const mapDispatchToProps = (dispatch) => ({
    postUserError: (params) =>
        dispatch(authAction.dataServerFail(params)),
    removeState: () =>
        dispatch(authAction.blankdispatch()),
});

class ShippingAddressEdit extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {  
        };
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    render(){   
        const { shippingAddressOption } = this.props;
        return (
            <div className = "slide-form__controls open" style={{height: "100%"}}>
                <div style={{marginBottom:30}}>
                    <i className="fas fa-times slide-close" style={{ fontSize: 20, cursor: 'pointer'}} onClick={()=>this.props.onHide()}></i>
                </div>
                <div className="title add-product pb-2">{trls('Shipping_Address')}</div>

                <div className=" d-flex justify-content-end">
                    <Button type="button" onClick = {()=> this.props.AddNewShippingAddress()}>{trls('Add_New')}</Button>
                </div>
                <div style={{marginTop: "10px",overflow:"auto"}}>
                {shippingAddressOption ? shippingAddressOption.map((i, index)=> (
                    <p key={index} className="bg-light py-4 pl-2">
                        {i.name ? i.name : ''} - {i.address ? i.address : ''} {i.city  ? i.city : ''} {i.zipCode} {i.countryName ? i.countryName:null}
                        <i className="fas fa-edit add-icon mx-2 float-right" onClick={()=>this.props.editShippinOption(i)} />
                    </p>
                ))
                : ''
                }
                </div>
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddressEdit);