/* eslint-disable eqeqeq */
import React, {Component} from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { trls } from '../../factories/translate';
import API from '../../factories/api';
import SessionManager from '../../factories/session_manage';
import Axios from 'axios';
import $ from 'jquery';

const mapStateToProps = state => ({ 
    ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({
});

class UpdateTermOfServiceUrl extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {  
            termOfServiceKey: '',
            termOfServiceUrl: ''
        };
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit = (e, formData, inputs) => {
        e.preventDefault();
        this._isMounted = true;
        var headers = SessionManager.shared().getAuthorizationHeader();
        const params = {};
        const updateFormData = new FormData(e.target);
       
        for (let key of updateFormData.keys()) {
            params[key] = updateFormData.get(key);
        }
        
        Axios.put(API.UpdateTermsOfService,params,headers)
        .then(result => {
            this.props.onHide()
            this.props.getTermsOfService()
            this.removeState();
        })
        .catch(err=>{
            if(err.response.data&& err.response.status==400){
                $('.add-error').text(err.response.data.Email)
                $('.add-error').removeClass("d-none")
            }
            this.props.onError()
        });

       
    }

    removeState = () => {
        this.setState({
            termOfServiceKey: '',
            termOfServiceUrl: ''
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        $('.add-error').addClass('d-none')
    }

    hideModel = () => {
        this.props.onHide();
        this.removeState()
    }

    onShow = () => {
        this.setState({
            termOfServiceKey: this.props.termsOfServiceData.termOfServiceKey,
            termOfServiceUrl: this.props.termsOfServiceData.termOfServiceUrl
        })
    }

    render(){
        return (
            <Modal
            show={this.props.show}
            onHide={this.hideModel}
            onShow={this.onShow}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop= "static"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {trls('Update_Terms_Of_Delivery')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="alert alert-danger add-error d-none" role="alert"></div>
                <Form onSubmit = { this.handleSubmit }>
                    <Form.Group as={Row} controlId="termOfServiceKey">
                        <Col className="product-text">
                            <Form.Control value={this.state.termOfServiceKey} readOnly onChange={this.handleChange} type="text" name="termOfServiceKey" required />
                            <label className="placeholder-label">termOfServiceKey</label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="termOfServiceUrl">
                        <Col className="product-text">
                            <Form.Control value={this.state.termOfServiceUrl} onChange={this.handleChange} type="text" name="termOfServiceUrl" required />
                            <label className="placeholder-label">termOfServiceUrl</label>
                        </Col>
                    </Form.Group>
                    <Form.Group style={{textAlign:"center"}}>
                        <Button type="Submit" >{trls("Save")}</Button>
                    </Form.Group>
                </Form>    
            </Modal.Body>
            </Modal>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateTermOfServiceUrl);