/* eslint-disable no-unused-vars */
import React, {Component} from 'react'
import { Row, Col, Form } from 'react-bootstrap';
import $ from 'jquery';
import SessionManager from '../../factories/session_manage';
import API from '../../factories/api'
import { BallBeat } from 'react-pure-loaders';
import { trls } from '../../factories/translate';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'datatables.net';
import * as Auth from '../../factories/auth';
import * as authAction  from '../../actions/authAction';
import history from '../../history';
import ApproveRegistrationForm from './ApproveRegistrationForm';
// import UpdateDocumentTypeForm from './update_documentType';
import ReactDOM from 'react-dom';
import Pageloadspiiner from '../../components/page_load_spinner';
import Axios from 'axios';

class RegistrationRequest extends Component {
    _isMounted = false
    constructor(props) {
        super(props);
        this.state = {
           
            filterColunm: [
                {"label": 'firstName', "value": "name", "type": 'text', "show": true},
                {"label": 'lastName', "value": "name", "type": 'text', "show": true},
                {"label": 'email', "value": "email", "type": 'text', "show": true},
                {"label": 'companyName', "value": "documentLanguage", "type": 'text', "show": true},
                {"label": 'city', "value": "active", "type": 'text', "show": true},
                {"label": 'country', "value": "country", "type": 'text', "show": true},
                {"label": 'zipCode', "value": "zipCode", "type": 'text', "show": true},
                {"label": 'Delete', "value": "action", "type": 'text', "show": true},
                {"label": 'Action', "value": "action", "type": 'text', "show": true},
            ],
            userInfo: Auth.getUserInfo(), 
            userType: '',
            showAllUser: false,
            ApproveModal: false,
            approveId: 0,
            pageSpiner:false
        }; 
       
    }

    componentDidMount() {
        this._isMounted=true;
        this.getRegistrationRequest();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps = nextProps => {
        this.setState({
            userType: nextProps.userType
        });
    };
    
    getRegistrationRequest (data) {    
        this._isMounted = true;
        
        var headers = SessionManager.shared().getAuthorizationHeader();
        var this_Obj = this;
        this.setState({loading: true})
      

        var table = $('#approve_table').DataTable({
            // dom: "Bfrtip",
            
            paging: true,
            pageLength: 10,
            "language": {
                "lengthMenu": trls("Show")+" _MENU_ "+trls("Result_on_page"),
                "zeroRecords": "Nothing found - sorry",
                "info": trls("Show_page")+" _PAGE_ "+trls('Results_of')+" _PAGES_",
                "infoEmpty": "No records available",
                "infoFiltered": "(filtered from _MAX_ total records)",
                "search": trls('Search'),
                "paginate": {
                  "previous": trls('Previous'),
                  "next": trls('Next')
                },
                "searching": true,
            },
            "dom": 't<"bottom-datatable" lip>',
            ajax: function ( data, callback, settings ) {
                $.ajax({
                    url: API.GetAllRegistration+"?pageNumber=" + (data.start / data.length + 1 )  + "&pageSize=" + data.length,
                    type: 'GET',
                    headers: headers.headers,
                    success: function( data, textStatus, jQxhr ){
                        callback({
                            data: data.data,
                            recordsTotal:  data.totalCount,
                            recordsFiltered:  data.totalCount,
                        });
                        
                        this_Obj.setState({loading:false,DocumentTypeData:data,pageSpiner:false})
                    },
                    error: function( jqXhr, textStatus, errorThrown ){
                        if(jqXhr.status===401){
                            history.push('/login')
                        }
                        this_Obj.setState({pageSpiner:false})
                    }
                });
            },
            serverSide: true,
            columns: [
                { data: "firstName" },
                { data: "lastName" },
                { data: "email" },
                { data: "companyName" },
                { data: "city" },
                { data: "country" },
                { data: "zipCode" },
                { 
                    data: "id",
                    createdCell: (td, cellData, rowData, row, col) =>
                    ReactDOM.render(
                        <i className ="fa fa-trash" onClick={()=>this.userDelete(cellData)}></i>, td),
                    
                },
                { 
                    data: "id",
                    createdCell: (td, cellData, rowData, row, col) =>
                    ReactDOM.render(
                    <button className="btn btn-primary"
                        onClick={() => this.handleApprove(cellData) }>
                        {trls('Approve')}
                    </button>, td),
                },
            ],
                    
        });
        
    }

     handleApprove(id){
        this.setState({ApproveModal: true,approveId:id})
    }

    userDelete = (id) => {
        // var params = {"id":id}
        var headers = SessionManager.shared().getAuthorizationHeader();
        confirmAlert({
            title: 'Confirm',
            message: trls('Confirm_Delete_Message'),
            buttons: [
              {
                label: trls('Delete'),
                onClick: () => {
                    this.setState({pageSpiner:true})
                    Axios.delete(API.DeleteUser+id,headers)
                    .then(result => {
                        var table = $('#approve_table').DataTable();
                        table.ajax.reload();               
                    })
                    .catch(err => {
                        if(err.response.status===401){
                            history.push('/login')
                        }
                    });
                }
              },
              {
                label: 'Cancel',
                onClick: () => {}
              }
            ]
          });
       
    }

    render() {
        const { filterColunm } = this.state;
            
        return (
            <div className="order_div">
            <div className="content__header content__header--with-line">
                <h2 className="title">{trls('Approve_Users')}</h2>
            </div>
            <div className="orders">
                <Row>
                    <Col sm={6} className="has-search">
                        <div style={{display: 'flex', float: 'right'}}>
                            {/* <Button variant="light" onClick={()=>this.changeFilter()}><i className="fas fa-filter add-icon"></i>{trls('Filter')}</Button> */}
                            <div style={{marginLeft: 20}}>
                                <span className="fa fa-search form-control-feedback"></span>
                                <Form.Control className="form-control fitler" type="text" name="number"placeholder={trls("Quick_search")}/>
                            </div>
                        </div>
                    </Col>
                </Row>
                
                <div className="table-responsive credit-history">
                        <table id="approve_table" className="place-and-orders__table table" width="99%">
                        <thead>
                            <tr>
                                {filterColunm.map((item, key)=>(
                                    <th key={key}>
                                       {/* {trls(item.label) ? trls(item.label) : ''} */}
                                       {item.label}
                                    </th>
                                    )
                                )}
                            </tr>
                        </thead>
                        <tbody></tbody>
                    </table>

                    { this.state.loading&& (
                        <div className="col-md-4 offset-md-4 col-xs-12 loading" style={{textAlign:"center"}}>
                            <BallBeat
                                color={'#222A42'}
                                loading={this.state.loading}
                            />
                        </div>
                    )}

                </div>
            </div>
            <ApproveRegistrationForm
                show={this.state.ApproveModal}
                onHide={() => this.setState({ApproveModal: false})}
                getRegistrationRequest={() => this.getRegistrationRequest()}
                approveId={this.state.approveId}
                onApprove={()=> this.setState({pageSpiner:true})}
                onError={()=>this.setState({pageSpiner:false})}
            />
            <Pageloadspiiner loading = {this.state.pageSpiner} text={''}/>
        </div>
        );
  }
}

export default RegistrationRequest;
