/* eslint-disable no-unused-vars */
import React, {Component} from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import { trls } from '../../factories/translate';
import API from '../../factories/api';
import * as authAction  from '../../actions/authAction';
import SessionManager from '../../factories/session_manage';
import Axios from 'axios';
import $ from 'jquery';

const mapStateToProps = state => ({ 
    ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({

});

class AddDocumentTypeForm extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.myInput = React.createRef(); 
        this.state = {  
            page: '',
            name: '',
            documentLanguage:  [{"value":"English","label":"English"},{"value":"Dutch","label":"Dutch"},
                        {"value":"German","label":"German"},{"value":"French","label":"French"}
                    ],
            page_val: '',
            customer_groupList: [],
            customer_group: ''
        };
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    
    componentDidMount() {
    }

    handleSubmit = (e, formData, inputs) => {
        this._isMounted = true;
        e.preventDefault();
        var headers = SessionManager.shared().getAuthorizationHeader();

        let params ={
            "documentLanguage": this.state.page_val.value,
            "name": this.state.name,
            "customerGroupCode": String(this.state.customer_group.value)
        }
        Axios.post(API.PostDocumentType, params, headers)
        .then(result => {
            if(this._isMounted){
                this.props.onHide()
                this.props.onGetUser()
                this.removeState();
            }
        })
        .catch(err => {
            if(err.response.status===400){
                $('.add-error').removeClass('d-none')
                $('.add-error').text(err.response.data)
            }
        });
    }

    removeState = () => {
        this.setState({
            page: '',
            name: '',
            page_val: ''
        })
    }

    getCustomerGroup = () => {
        this._isMounted = true;
        var headers = SessionManager.shared().getAuthorizationHeader();
        Axios.post(API.GetCustomerGroup, {}, headers)
        .then(result => {
            if(this._isMounted){
                var groupTempList = []
                result.data.value.forEach(element => {
                    groupTempList.push({"value":element.Code,"label":element.Name})
                });
                this.setState({customer_groupList: groupTempList});
            }
        })
        .catch(err => {
            if(err.response.status===400){
                $('.add-error').removeClass('d-none')
                $('.add-error').text(err.response.data)
            }
        });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        $('.add-error').addClass('d-none')
    }

    hideModel = () => {
        this.props.onHide();
        this.removeState()
    }

    render(){
        const { customerGroupList } = this.props;
        return (
            <Modal
            show={this.props.show}
            onHide={this.hideModel}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop= "static"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {trls('Add_DocumentType')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="alert alert-danger add-error d-none" role="alert"></div>
                <Form onSubmit = { this.handleSubmit }>
                    <Form.Group as={Row} controlId="name">
                        <Col className="product-text">
                            <Form.Control value={this.state.name} onChange={this.handleChange} type="text" name="name" required />
                            <label className="placeholder-label">{trls('Name')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="page_name">
                        <Col>
                            <Select
                                name="page_name"
                                // placeholder= 'select page'
                                options={this.state.documentLanguage}
                                onChange={val => this.setState({page_val: val})}
                                defaultValue = ''
                            />
                            <label className="placeholder-label">{trls('documentLanguage')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="page_name">
                        <Col>
                            <Select
                                name="customerGroup"
                                // placeholder= 'select page'
                                options={customerGroupList}
                                onChange={val => this.setState({customer_group: val})}
                                defaultValue = ''
                            />
                            <label className="placeholder-label">{trls('documentLanguage')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group style={{textAlign:"center"}}>
                        <Button type="Submit" >{trls("Save")}</Button>
                    </Form.Group>
                </Form>    
            </Modal.Body>
            </Modal>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddDocumentTypeForm);