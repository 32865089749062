import React from 'react'
import Axios from 'axios';
import API from '../factories/api'
import SessionManager from '../factories/session_manage';
class OrderDeliveryDate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            DeliveryDate: 'loading...'
        };
    }

    getData = () => {
        var headers = SessionManager.shared().getAuthorizationHeader();
        Axios.get(API.GetDeliveryDate + '?orderlineId=' + this.props.orderlineId, headers)
        .then(result => {
            if (!result.data[0].DeliveryDate) {
                setTimeout(()=>{
                    this.getData();
                },1000)
               
            } else {
                this.setState({ DeliveryDate: result.data[0].DeliveryDate })
            }
        })
        .catch(err => {
            this.setState({ DeliveryDate: '' })
        })
    }

    componentDidMount() {
        if (this.props.orderlineId) {
            this.getData();
        } else {
            this.setState({ DeliveryDate: '' })
        }
    }

    render() {
        return (
            <span>{this.state.DeliveryDate}</span>
        )
    };
}
export default OrderDeliveryDate;
