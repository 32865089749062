/* eslint-disable no-unused-vars */
import React, {Component} from 'react'
import { connect } from 'react-redux';
import { trls } from '../../factories/translate';
import { Row, Col, Button, Form } from 'react-bootstrap';
import SessionManager from '../../factories/session_manage';
import API from '../../factories/api'
import Axios from 'axios';
import HelpTextModal from '../Dashboard/HelpText';
import history from '../../history';


const mapStateToProps = state => ({ 
    ...state.auth,
});

class Maintenance extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {  
            loading: false,
            Colunm: [
                {"label": 'DocumentType', "value": "DocumentType", "type": 'text', "show": true},
                {"label": 'Document', "value": "Document", "type": 'text', "show": true},
            ],
            MaintenanceData:'',
            HelpTextModal: false
            
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this.getMaintenance();
    }

    getHelpText = () => {
        var pageName = 'Maintenance'
        var headers = SessionManager.shared().getAuthorizationHeader();
        var lang = localStorage.getItem('eijf_lang');
        Axios.get(API.GetHelpTextByName+pageName,headers)
        .then(result => {
            let helpURL = ''
            if (lang==='Dutch') {
                helpURL = result.data.contentDutch
            } else if (lang==='English') {
                helpURL = result.data.contentEnglish
            } else if (lang==='French') {
                helpURL = result.data.contentFrench
            } else {
                helpURL = result.data.contentGerman
            }
            const a = document.createElement("a");
            a.setAttribute('href', helpURL);
            a.setAttribute('target', '_blank');
            a.click(); 
        })
        .catch(err => {
            // if(err.response.status===401){
            //     history.push('/login')
            // }
        })
    }

    getMaintenance = () => {
        var headers = SessionManager.shared().getAuthorizationHeader();
        var lang = localStorage.getItem('eijf_lang');
        var maintenancevalue = ''
        Axios.get(API.GetMaintenance, headers)
        .then(result => {

            switch (lang) {
                case 'English':
                    maintenancevalue = result.data.en
                    break;
                case 'Dutch':
                    maintenancevalue = result.data.de
                    break;
                case 'German':
                    maintenancevalue = result.data.nl
                    break;
                case 'French':
                    maintenancevalue = result.data.fr
                    break;
                default:
                    maintenancevalue = result.data.en
            }
            this.setState({
                MaintenanceData: maintenancevalue
            })
        })
        .catch(err => {
            if(err.response && err.response.status===401){
                history.push('/login')
            }
        })
    }

    showDocument = (docURL) => {
        window.open(docURL, "_blank"); 
    }
    render(){
        const {MaintenanceData} = this.state;

        return (
            <div className="order_div">
               
                <div className="content__header content__header--with-line">
                    <div id="google_translate_element"></div>
                    <h2 className="title">{trls("Maintenance")}</h2>
                </div>

                <div className="orders">
                    <Row>
                        <Col sm={12} className="has-search">
                            <div style={{display: 'flex', float: 'right'}} className="justify-content-right">
                                <Button variant="primary" className="float-right" onClick={this.getHelpText}><i className="fa fa-info-circle" aria-hidden="true"></i></Button> 
                            </div>
                        </Col>
                    </Row>

                    <div className="card border-news mt-4">
                        <div className="card-header border-news news-header">
                            <h5 className="title">{trls('Maintenance_Text')}</h5>
                        </div>
                        <div className="card-body">
                            <Col className="p-0">
                                <div>
                                    <div className="dashboard__bottom-item card">
                                        <div className="dashboard-news_content card-body ">
                                            {MaintenanceData  ? MaintenanceData : ''}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </div>
                </div>

                    <HelpTextModal
                    show={this.state.HelpTextModal}
                    onHide={() => this.setState({HelpTextModal: false})}
                    HelpTextTitle = {this.state.SalesinvoicesHelpTextTitle}
                    HelpTextDescription = {this.state.HelpTextDescription}
                    />
                </div>
            </div>
        );
    }
}
export default connect(mapStateToProps)(Maintenance);
