export const getUserToken = () => {
    return(window.localStorage.getItem('eijf_token'))
};

export const removeAuth = () => {
    localStorage.setItem('eijf_token', '');
    localStorage.setItem('eijf_userName', '');
    localStorage.setItem('eijf_role', '');
    localStorage.setItem('eijf_loggedUser', '');
    localStorage.setItem('admin_role', '');
    localStorage.setItem('admin_userName', '');
    localStorage.setItem('admin_token', '');
    localStorage.setItem('eijf_showPrice', '');
    localStorage.setItem('userType', '');
    localStorage.setItem('eijf_shippingType', '');
    localStorage.removeItem('eij_address_book');
    localStorage.setItem('eijf_shippingAddress', '');
    localStorage.setItem('welcome_message',  false);
    localStorage.setItem('eijf_loginAs', 0);
    return true
};

export const getAuth = () => {
    return(localStorage.getItem('eijf_token'))
};

export const getRole = () => {
    return(localStorage.getItem('eijf_role'))
};

export const getUserInfo = () => {
    let userInfo = [];
    userInfo.role = localStorage.getItem('eijf_role');
    userInfo.userName = localStorage.getItem('eijf_userName');
    userInfo.userToken = localStorage.getItem('eijf_token');
    userInfo.eijf_loggedUser = localStorage.getItem('eijf_loggedUser');
    userInfo.addressBook = localStorage.getItem('eij_address_book');
    userInfo.language = localStorage.getItem('eijf_language');
    userInfo.MaintenanceMode = localStorage.getItem('MaintenanceMode');
    userInfo.shippingType = localStorage.getItem('eijf_shippingType');
    userInfo.U_DBS_EXPORTKLANT = localStorage.getItem('U_DBS_EXPORTKLANT');
    
    return userInfo;
};

export const getLoggedUserInfo = () => {
    var loggedUserInfo = JSON.parse(localStorage.getItem("eijf_loggedUser"));
    return loggedUserInfo;
};

export const getAdminInfo = () => {
    let adminInfo = [];
    adminInfo.role = localStorage.getItem('admin_role');
    adminInfo.userName = localStorage.getItem('admin_userName');
    adminInfo.userToken = localStorage.getItem('admin_token');
    adminInfo.admin_eijf_loggedUser = localStorage.getItem('admin_eijf_loggedUser');
    adminInfo.admin_MaintenanceMode = localStorage.getItem('admin_MaintenanceMode');
    adminInfo.admin_U_DBS_EXPORTKLANT = localStorage.getItem('admin_U_DBS_EXPORTKLANT');
    adminInfo.admin_eij_address_book = localStorage.getItem('admin_eij_address_book');
    adminInfo.admin_eij_language = localStorage.getItem('admin_eij_language');
    adminInfo.admin_eijf_shippingType = localStorage.getItem('admin_eijf_shippingType');
    return adminInfo;
}

export const getUsetType = () => {
    return localStorage.getItem('userType');
}

