import React, {Component} from 'react'
import { Row, Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import Addnewsform from './addnewsform';
import $ from 'jquery';
import SessionManager from '../../factories/session_manage';
import API from '../../factories/api'
import Axios from 'axios';
import { BallBeat } from 'react-pure-loaders';
import { trls } from '../../factories/translate';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'datatables.net';
import * as Common from '../../factories/common';
import * as Auth from '../../factories/auth';
//import Filtercomponent from '../../components/filtercomponent';
import history from '../../history';
//import Parser from 'html-react-parser';
import Sweetalert from 'sweetalert';
//import Moment from 'moment/min/moment-with-locales'
import dayjs from 'dayjs'
import './news_manage_style.css'
import Slideshow from '../../components/slideshow';
const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
    
});

class Newsmanage extends Component {
    _isMounted = false
    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            slideFormFlag: false,
            originFilterData: [],
            filterFlag: false,
            languageOptions : [{"value":"English","label":"GB"},{"value":"Dutch","label":"NL"},{"value":"German","label":"DE"},{"value":"French","label":"FR"}],
            selectedLanguageOption : "GB",
            filterColunm: [
                /*
                {"label": 'Subject Dutch', "value": "subjectDutch", "type": 'text', "show": true},
                {"label": 'Subject English', "value": "subjectEnglish", "type": 'text', "show": true},
                {"label": 'Subject German', "value": "subjectGerman", "type": 'text', "show": true},
                {"label": 'Subject French', "value": "subjectFrench", "type": 'text', "show": true},
                {"label": 'Text Dutch', "value": "textDutch", "type": 'text', "show": true},
                {"label": 'Text English', "value": "textEnglish", "type": 'text', "show": true},
                {"label": 'Text German', "value": "textGerman", "type": 'text', "show": true},
                {"label": 'Text French', "value": "textFrench", "type": 'text', "show": true},
                {"label": 'Action', "value": "Action", "type": 'text', "show": true},*/
            ],
            userInfo: Auth.getUserInfo(), 
            mode: 'add',
            newsData: [],
            newsCurrent:[],
            lang: localStorage.getItem('eijf_lang')
        };
    }

    componentDidMount() {
        this.getNewsData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getNewsLanguage(locale){
        
        let title = '';
        let subtitle = '';
        let text = ''
        let list = [];
        let images = [];
        if(this.state.newsData){
            this.state.newsData.forEach((item, index) => {
                if(item.documents){
                    images = item.documents.filter(x => (x.documentUrl.includes('.jpg') || x.documentUrl.includes('.png')) && x.isPrimary)
                }
                switch(locale){
                    case "GB":
                    title = item.subjectEnglish;
                    subtitle = item.subTitleEnglish;
                    text = item.textEnglish;
                    break;
                    case "DE":
                    title = item.subjectGerman;
                    subtitle = item.subTitleGerman;
                    text = item.textGerman;
                    break;
                    case "NL":
                    title = item.subjectDutch;  
                    subtitle = item.subTitleDutch;
                    text = item.textDutch;
                    break;
                    case "FR":
                    title = item.subjectFrench; 
                    subtitle = item.subTitleFrench;
                    text = item.textFrench;
                    break; 
                    default:
                    title = item.subjectEnglish;
                    subtitle = item.subTitleEnglish;
                    text = item.textEnglish;
                }
                const record = {
                    title:title,
                    subtitle:subtitle,
                    text:text,
                    images:images,
                    item:item
                }
                list.push(record) 
            
            })
            this.setState({newsCurrent:list})
        }
    }
    
    getNewsData (data) {    
        this._isMounted = true;
        this.setState({loading: true})
        var headers = SessionManager.shared().getAuthorizationHeader();
        
        Axios.get(API.GetNews, headers).then(result => {
            let resultData;
            if(!data){
                resultData = result.data;
                //this.setState({newsData: result.data, originFilterData: result.data});
            }else{
                //this.setState({newsData: data});
                resultData = data;
            }

            this.setState({newsData: resultData});
            this.setState({loading:false})
            this.getNewsLanguage(this.state.selectedLanguageOption)
            

        }).catch(err => {
            this.displayError(err)
        })
    }
    // filter module
    filterOptionData = (filterOption) =>{
        let dataA = []
        dataA = Common.filterData(filterOption, this.state.originFilterData);
        if(!filterOption.length){
            dataA=null;
        }
        $('#project_table').dataTable().fnDestroy();
        this.getUserData(dataA);
    }

    changeFilter = () => {
        if(this.state.filterFlag){
            this.setState({filterFlag: false})
        }else{
            this.setState({filterFlag: true})
        }
    }
    // filter module
    newsUpdate = (id) => {
        var settings = {
            "url": API.GetNewsDataById+id,
            "method": "GET",
            "headers": {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+Auth.getUserToken(),
        }
        }
        $.ajax(settings).done(function (response) {
        })
        .then(response => {
            this.setState({newsUpdateData: response, mode:"update", slideFormFlag: true});
            Common.showSlideForm();
        })
        .catch(err => {
            this.displayError(err)
        });
    }

    newView = (id) => {
        var settings = {
            "url": API.GetNewsDataById+id,
            "method": "GET",
            "headers": {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+Auth.getUserToken(),
        }
        }
        $.ajax(settings).done(function (response) {
        })
        .then(response => {
            this.setState({newsUpdateData: response, mode:"view", slideFormFlag: true});
            Common.showSlideForm();
        })
        .catch(err => {
            this.displayError(err)
        });
    }

    newsDelete = (id) => {
        var settings = {
            "url": API.DeleteNews+id,
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+Auth.getUserToken(),
        }
        }
        $.ajax(settings).done(function (response) {
        })
        .then(response => {
            Sweetalert("Success!", {
                icon: "success",
            });
            this.getNewsData();     
        })
        .catch(err => {
            this.displayError(err)
        });
    }

    newsDeleteConfirm = (id) => {
        Sweetalert({
            title: "Are you sure?",
            text: trls("Are you sure to do this?"),
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                this.newsDelete(id)
            } else {
            }
        });
    }

    addNews = () => {
        this.setState({mode:"add", flag:false, slideFormFlag: true})
        Common.showSlideForm();
    }

    removeColumn = (value) => {
        let filterColunm = this.state.filterColunm;
        filterColunm = filterColunm.filter(function(item, key) {
            if(item.label===value){
            item.show = false;
            }
            return item;
        })
        this.setState({filterColunm: filterColunm})
    }

    showColumn = (value) => {
        let filterColum = this.state.filterColunm;
        filterColum = filterColum.filter((item, key)=>item.label===value);
        return filterColum[0].show;
    }

    displayError = (err) =>{
        if(err.response){
            if(err.response.status===401){
                history.push('/login')
            }
            else{
                Sweetalert({
                    text: "Something went wrong, please try again later",
                    icon: "error",
                })
            }
        }
    }

    render () {
        const {newsCurrent, newsData } = this.state;
        
        return (
            <div className="order_div">
                <div className="content__header content__header--with-line">
                    <h2 className="title">{trls('News')}</h2>
                </div>
                <div className="orders">
                    <Row>
                        <Col sm={6}>
                            {Auth.getRole()==="Administrator" || Auth.getRole()==="Customer" ?(
                                <Button variant="primary" onClick={()=>this.addNews()}><i className="fas fa-plus add-icon"></i>{trls('Add News')}</Button> 
                            ): <Button variant="primary" disabled onClick={()=>this.addNews()}><i className="fas fa-plus add-icon"></i>{trls('Add News')}</Button> }
                            
                        </Col>
                        <Col sm={6} className="has-search">
                            <div style={{display: 'flex', float: 'right'}}>
                                Edit language 
                                <select 
                                value={this.state.selectedLanguageOption}
                                onChange={
                                    (event)=>{
                                        this.setState({selectedLanguageOption:event.target.value})
                                        this.getNewsLanguage(event.target.value)
                                    }}>
                                {
                                    this.state.languageOptions.map((item,i)=>(
                                        <option key={item.label} value={item.label} >{item.value}</option>
                                    ))
                                }
                                </select>
                            </div>
                        </Col>
                    </Row>
                   
                    {newsData && !this.state.loading &&(<div className="news" >
                            {
                                newsCurrent.map((data,i) =>(
                                    <div className="news-item" id={i} key={i}> 
                                        <div className="news-image">
                                            <Slideshow images={data.images}/>
                                        </div>
                                        
                                        <div className="subtitle">{data.subtitle}</div>
                                        <h2 className="title">{data.title}</h2>
                                        <div className="date">{dayjs(data.item.startDate).format('DD MMMM YYYY')}</div>
                                        <div className="text">{data.text}</div>

                                        <i className="fas fa-pen add-icon" onClick={()=>this.newsUpdate(data.item.id)}><span className="action-title">{trls('Edit')}</span></i>
                                        <i className="fas fas fa-eye add-icon" onClick={()=>this.newView(data.item.id)}><span className="action-title">{trls('View')}</span></i>
                                        <i className="fas fa-trash-alt add-icon" onClick={()=>this.newsDeleteConfirm(data.item.id)}><span className="action-title">{trls('Delete')}</span></i>
                                    </div>
                                    
                                ))
                            }
                        </div>)}
                       
                   
                        { this.state.loading&& (
                            <div className="col-md-4 offset-md-4 col-xs-12 loading" style={{textAlign:"center"}}>
                                <BallBeat
                                    color={'#222A42'}
                                    loading={this.state.loading}
                                />
                            </div>
                        )}
                </div>
                
                {this.state.slideFormFlag ? (
                    <Addnewsform
                        show={this.state.modalShow}
                        mode={this.state.mode}
                        onHide={() => this.setState({slideFormFlag: false, newsUpdateData: [], mode: 'add'})}
                        onGetnewData={() => this.getNewsData()}
                        newsUpdateData={this.state.newsUpdateData}
                    /> 
                ): null}
            </div>
        )
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Newsmanage);
