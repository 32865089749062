export const TRANSLATIONS = {
  English: {
    "News History": "News History",
    "CalculatePatternMessage": "In order to deliver you quickly, it is important that you always enter the number of lengths and metres. An example: if you have a room-high fabric for two different windows, please enter the order as 2 lines, not as 1 line with the total number of meters. These lengths will be delivered from the same batch.",
    "The maximum number of samples is 5.": "The maximum number of samples is 5.",
    "NoItemMessage": "Item is no longer available",
    "Reference": "Reference",
    "TrackAndTrace": "Track & Trace",
    "AddressBook": "Address Book",
    "Username": 'Username',
    "Password": 'Password',
    "ConfirmPassword": 'Confirm Password',
    "Forgot_password": 'Forgot password?',
    "Sign_in": 'Login',
    "Orders": 'Orders',
    "Dashboard": 'Dashboard',
    "Sales_No": 'Order number',
    "Previous":'Previous',
    "Next":'Next',
    "Show_entries":'Show entries',
    "Show":'Show',
    "Entries":'entries',
    "Show_page":'Showing page',
    "PO_No": 'PO_No',
    "Order_Date": 'Date of order',
    "Shipping_Date": "ShippingDate",
    "Total": 'Total',
    "Currency": 'Currency',
    "Status": 'Status',
    "Download": 'Download',
    "Past_Due": 'Past Due',
    "Due_Soon": 'Due Soon',
    "Total_Outstanding": 'Total Outstanding',
    "Place_an_order": 'Order ',
    "Place_a_sample_order": 'Order a sample',
    "Search_Lines": 'Search Products and Create Order Lines',
    "Make_a_Payment": 'Make a Payment',
    "Credit_card": 'Credit card, PayPal, Worldpay, On Account',
    "Last_5_Orders": 'Last 5 Orders',
    "Next_5_Deliveries_Due": 'Next 5 Deliveries Due',
    "Outstanding_Payments": 'Outstanding Payments',
    "Sales_Number": 'Item Number',
    "Delivery_Number": 'Delivery Number',
    "Due_Date": 'Due Date',
    "Invoice_Number": 'Invoice Number',
    "Amount": 'Amount',
    "Enter_email": 'EnterEmail',
    "Back_to_Sign_in": 'Back to Sign in',
    "Next_Step": 'Next Step',
    "New_Password": 'New password',
    "Confirm_Password": 'Confirm password',
    "Item_Name": 'ItemName',
    "Description": 'Description',
    "Quantity": 'Number',
    "Shipped_Quantity": 'ShippedQuantity',
    "Price": 'Price',
    "Requested_Delivery_Date": 'Requested Delivery Date',
    "PO_Reference": 'PO Reference',
    "Billing_Address": 'Billing Address',
    "Shipping_Address": 'Shipping Address',
    "Order_Comments": 'Order Comments',
    "Product": 'Product',
    "Item_Price": 'ItemPrice',
    "Line_Total": 'Line Total',
    "Click_to_make_new_row": 'New order line',
    "Search": 'Search',
    "Submit_Order": 'Submit Order',
    "Make_a_payment": 'Make a payment',
    "Make_Payment": 'Make Payment',
    "Play": 'Play',
    "Type": 'Type',
    "Documet_Date": 'DocumentDate',
    "Invoice_No": 'Invoice No',
    "Business_partner": 'Customer partner',
    "Contact": 'Contact',
    "Ordertype": 'Order type',
    "Unit": 'Unit',
    "Delivery": 'Delivery #',
    "DeliveryDate": 'DeliveryDate',
    "Pay": 'Pay',
    "Your_order_reference": 'Your order reference',
    "Invoice": 'Invoice',
    "Invoice #": 'Invoice #',
    "InvoiceDate": 'InvoiceDate',
    "OrderNumber": 'OrderNumber',
    "Batch": 'Batch',
    "Invoices": 'Invoices',
    "CreditNotNo": 'Credit Note NO',
    "DateCreated": 'Date Created',
    "Balance": 'Balance',
    "Applied_by": 'Applied by',
    "Place_sample_order": 'Place sample order',
    "Save": 'Save',
    "Collection_or_historical_orders": 'Collection or historical orders',
    "Action": 'Action',
    "Product_code": 'Product code',
    "Jobs": 'Jobs',
    "Length_specified": 'Length specified',
    "Length_calculated": 'Length calculated',
    "Pattern": 'Pattern',
    "Order_rule": 'Order Line',
    "Add_stroke": 'Add stroke',
    "Remove_stroke": 'Remove stroke',
    "Calculate_pattern_length": 'Calculate pattern length',
    "Processing": 'Processing',
    "Cancel": 'Cancel',
    "Verspringend": 'Offset',
    "Accept_delivery": 'Accept Delivery',
    "No_partial_deliveries": 'No partial deliveries',
    "Go_back_to_the_order": 'Go back to the order',
    "Resume": 'Resume',
    "Allowance": 'Allowance',
    "Product_image": 'Product image',
    "Email": 'E-Mail',
    "E-Mail address order confirmation": 'E-Mail address order confirmation',
    "Order confirmation email address?": 'Is this the correct e-mail address for the order confirmation?',
    "Sign_up": 'Sign up',
    "have_an_account": "Don't have an account?",
    "User": 'User',
    "Users": 'Users',
    "Add_User": 'Add User',
    "UserName": 'UserName',
    "PhoneNumber": 'PhoneNumber',
    "Active": 'Active',
    "Result_on_page": 'result on page',
    "Results_of": 'results of',
    "Delete": 'Delete',
    "Edit": 'Edit',
    "Roles": 'Role',
    "UserCode": 'UserCode',
    "CustomerCode": 'CustomerCode',
    "FirstName": 'First name',
    "LastName": 'Last name',
    "keep_logged_in": 'Keep me logged in',
    "have_account": "Don't have account",
    "Add_order": 'Add Order',
    "ItemCode": 'ItemCode',
    "ItemDescription": 'ItemDescription',
    "DocNum": 'DocNum',
    "DocDate": 'DocDate',
    "cardName": 'cardName',
    "dbsCollection": 'dbsCollection',
    "Filter": 'Filter',
    "Quick_search": 'Quick search',
    "Add_filter": 'Add filter',
    "Deliveries": 'Deliveries',
    "Salesinvoices": 'Invoices/credit notes',
    "DocTotal": 'DocTotal',
    "Deactivate": 'Deactivate',
    "Returns": 'Returns',
    "Edit_User": 'Edit User',
    "Test": 'Test',
    "Login As": 'Login As',
    "Go_Back_Admin": 'Go back to Admin',
    "Customer_reference": 'Reference',
    "City_Country": 'City,Country',
    "Street": 'Street (max. 40 characters)',
    "Product_description": 'Product description',
    "Image": "Photo",
    "Expected_deliver_week": "Expected delivery week",
    "Order": 'Order',
    "Language": 'Language',
    "Select_language": 'Select your language',
    "Search_Item": 'Search Item',
    "Add_to_order": 'Add to order',
    "Orderline": 'Orderline',
    "Staggered": 'Staggered',
    "Rows": 'Number of lengths',
    "Row_length": 'Length in meters (90 cm = 0.9)',
    "Row_length_calculated": 'Fabric length calculated',
    "Total_row_length": 'Total fabric length calculated',
    "Total_lengh": 'Total length in meters',
    "Add_to_row": 'Add line',
    "PatternCalculate": 'Calculate',
    "Pattern_calculation": 'Pattern calculation',
    "Date": 'Date',
    "DocDueDate": 'DocDueDate',
    "OrderSummary": 'Order Summary',
    "Profile": 'Profile',
    "ResetPassword": 'ResetPassword',
    "Logout": 'Logout',
    "CustomerName": 'Customer name',
    "ShowPrice": 'Show price',
    "Return details": 'Return details',
    "Salesinvoice details": 'Salesinvoice details',
    "Delivery details": 'Delivery details',
    "NoPatternCalculation": 'Do not calculate pattern',
    "Pattern calculation needs to be filled for this product, do you want to delete the product from the order lines?": 'Pattern calculation needs to be filled for this product, do you want to delete the product from the order lines?',
    "Zipcode": "Zipcode",
    "City": "City",
    "StreetNo": "StreetNo",
    "News": "News",
    "Add News": "Add News",
    "Subject Dutch": "Subject Dutch",
    "Subject English": "Subject English",
    "Subject German": "Subject German",
    "Subject French": "Subject French",
    "Subtitle Dutch": "Subtitle Dutch",
    "Subtitle English": "Subtitle English",
    "Subtitle German": "Subtitle German",
    "Subtitle French": "Subtitle French",
    "Text Dutch": "Text Dutch",
    "Text English": "Text English",
    "Text German": "Text German",
    "Text French": "Text French",
    "StartDate": "StartDate",
    "EndDate": "EndDate",
    "Are you sure to do this?": "Are you sure to do this?",
    "View": "View",
    "Edit News": "Edit News",
    "View News": "View News",
    "Are you sure that you want to return this item?": "Are you sure that you want to return this item?",
    "Approve order": "Approve order",
    "I agree with the Terms and Conditions": "I agree with the Terms and Conditions",
    "I agree with the": "I agree with the",
    "Terms and Conditions":"Terms and Conditions",
    "No partial deliveries": "No partial deliveries",
    "Return #": 'Return #',
    "ReturnDate": 'ReturnDate',
    "Show All Users": 'Show All Users',
    "Add return": 'New return request',
    "Return": 'Return',
    "Are you sure?": 'Are you sure?',
    "New Item": 'New Item',
    "Name": "Name",
    "Reference_Header":"Reference Header",
    "Reference_Line":"Reference text",
    "Invoicenumber": "Document",
    "Invoicedate": "Date",
    "Item" : "Item",
    "Number" : "Number",
    "Reference Header" : "Reference Header",
    "Reference Line" : "Reference Line",
    "Delivery Number" : "Delivery Number ",
    "Delivery Date" : "Available",
    "Order Number" : "Order Number",
    "Order Type" : "Order Type",
    "Open_invoices": "Outstanding invoices/ credit notes",
    "Document": "Document",
    "OpenAmount": "Outstanding amount",
    "Days_Overdue": "Number of days due",
    "Outstanding_Invoices": "Outstanding Invoices",
    "Help_Text": "Help Text",
    "add_help_text": "Add Help Text",
    "page": "Page",
    "Title": "Title",
    "Subtitle":"Subtitle",
    "Text":"Text",
    "contentDutch": "contentDutch",
    "contentEnglish": "contentEnglish",
    "contentGerman": "contentGerman",
    "contentFrench": "contentFrench",
    "Edit_help_text": "Edit Help Text",
    "Documents": "Documents",
    "DocumentUrl": "DocumentUrl",
    "Add_Document": "Add Document",
    "Edit_Document": "Edit Document",
    "DocumentType": "DocumentType",
    "Add_DocumentType": "Add DocumentType",
    "documentLanguage": "documentLanguage",
    "Edit_DocumentType": "Edit DocumentType",
    "Confirm_Delete_Message": "Are you sure to delete this?",
    "Document_File": "Document File",
    "Not_Registered_Yet": "Customer, but not an account?",
    "Register_Here": "Request your account here",
    "Registration": "Request account",
    "Approve_Users": "Approve Users",
    "Approve": "Approve",
    "Commission":"Commission",
    "PickUpDate": "Collection date",
    "Comments": "Remarks",
    "Return_Item": "Return Item",
    "Settings": "Settings",
    "Notifications": "Notifications",
    "Terms_Of_Delivery": "Terms Of Delivery",
    "Add" : "Add",
    "Add_Notifications_Email": "Add Notification Email",
    "Update_Terms_Of_Delivery": "Update Terms Of Delivery",
    "Shipping_type": "Shipping type",
    "ErrorLogs": "ErrorLogs",
    "id": "id",
    "logLevel": "logLevel",
    "logEvent": "logEvent",
    "dateTime": "dateTime",
    "invokedByUserId": "invokedByUserId",
    "referenceId": "referenceId",
    "Message": "Message",
    "Country": "Country",

    "Registration_sucess_message": "Your account will be activated as soon as the admin approves it.",
    "CompanyName": "Company Name",
    "Address": "Address",
    "Back_to_Log_in": "Back to login",
    "ExcludeProductgroups" : "ExcludeProductgroups",
    "ExcludeProductgroups_success": "ExcludeProductgroups added successfully",
    "CustomerCodes": "CustomerCodes",
    "Add_New": "Add New",
    "Number_of_packages": "Number of cartons",
    "Partial": "Partial",
    "Maintenance": "Maintenance",
    "Maintenance_Mode": "Maintenance Mode",
    "Maintenance_Text": "Maintenance Text",
    "Direct": "Direct",
    "CustomerGroupCode": "CustomerGroupCode",
    "Action (download PDF)": "Action (download PDF)",
    "Status delivery": "Status delivery",
    "Action (T&T and PDF)": "Action (T&T and PDF)",
    "Submit Order": "Confirm Order",
    "Success": "Succeeded!",
    "Submit": "Confirm",
    "Order total": "Order total",
    "Order confirmation": "Order confirmation",
    "Sent": "Sent",
    "Return number": "Return number",
    "Return order": "Return order",
    "Current information": "Current information",
    "Document name": "Document name",
    "Request a return": "Return requests",
    "Submit return": "Submit return",
    "Collection":"Collection",
    "Partial_deliveries":"Partial deliveries",
    "Expenses":"Order costs",
    "login": "Log in",
    "CReference": 'Reference',
    "ChangePassword": 'Change password',
    "CurrentPassord": 'Current password',
    "StockInfo": "Stock check",
    "Calculate": "Start",
    "Check": "Check",
    "Stock Expected delivery week": "Expected delivery week",
    "Stork Direct": "Direct",
    "Incourant": "Item has been discontinued and is not sufficiently available",
    "Standard forwarder": "Standard forwarder",
    "One moment please": "A stock check will take approx. 10-15 seconds.",
    "Available": "Available",
    "Customer_yet": "You are not a customer yet, but would you like to become one?",
    "Contact_us_here": "Contact us here should be to",
    "Pdf": "PDF",
    "Open": "Open",
    "Close": "Closed",
    "Item has been discontinued and is not sufficiently available": "Item has been discontinued and is not sufficiently available",
    "Please check quantity": "Please check quantity",
    "Is this number correct?": "Is this number correct?"
  },

  Dutch: {
    "The maximum number of samples is 5.": "Het maximale aantal stalen is 5.",
    "CalculatePatternMessage": "Om je snel uit te kunnen leveren, is het van belang dat je altijd het aantal banen/stukken en meters invoert. Een voorbeeld: bij een kamerhoge stof voor twee verschillende ramen, graag de order als 2 regels invoeren, dus niet als 1 regel met het totale aantal meters. Deze stukken zullen uit hetzelfde kleurbad (batch) worden uitgeleverd.",
    "NoItemMessage": "Dit artikelnummer is niet meer leverbaar",
    "AddressBook": "Adresboek",
    "TrackAndTrace": "Track & Trace",
    "Username": 'Gebruikersnaam',
    "Password": 'Wachtwoord',
    "ConfirmPassword": 'Bevestig wachtwoord',
    "Forgot_password": 'Wachtwoord vergeten?',
    "Sign_in": 'Inlog',
    "Orders": 'Orders',
    "Dashboard": 'Dashboard',
    "Sales_No": 'Ordernummer',
    "Previous":'Vorige',
    "Next":'Volgende',
    "Show_entries":'Toon inzendingen',
    "Show":'Toon',
    "Entries":'Inzendingen',
    "Show_page":'Toon pagina',
    "PO_No": 'PO-nummer',
    "Order_Date": 'Orderdatum',
    "Shipping_Date": 'Verzend datum',
    "Total": 'Totaal',
    "Currency": 'Valuta',
    "Status": 'Status',
    "Download": 'Download',
    "Past_Due": 'Verlopen',
    "Due_Soon": 'Verwacht',
    "Total_Outstanding": 'Totaal uitstaand',
    "Place_an_order": 'Bestellen',
    "Place_a_sample_order": "Bestel een staal",
    "Search_Lines": 'Zoek een product en maak een order',
    "Make_a_Payment": 'Doe een betaling',
    "Credit_card": 'Creditcard, PayPal, Worldpay, op rekening',
    "Last_5_Orders": 'Laatste 5 orders',
    "Next_5_Deliveries_Due": 'Volgende 5 uitstaande leveringen',
    "Outstanding_Payments": 'Openstaande betalingen',
    "Sales_Number": 'Ordernummer',
    "Delivery_Number": 'Leveringsnummer',
    "Due_Date": 'Datum',
    "Invoice_Number": 'Factuurnummer',
    "Amount": 'Bedrag',
    "Enter_email": 'Vul e-mailadres in',
    "Back_to_Sign_in": 'Terug naar inlog',
    "Next_Step": 'Volgende stap',
    "New_Password": 'Nieuw wachtwoord',
    "Confirm_Password": 'Bevestig wachtwoord',
    "Item_Name": 'Collectie',
    "Description": 'Omschrijving',
    "Quantity": 'Aantal',
    "Shipped_Quantity": 'Verzonden aantal',
    "Price": 'Prijs',
    "Requested_Delivery_Date": 'Gevraagde leverdatum',
    "PO_Reference": 'PO referenie',
    "Billing_Address": 'Factuuradres',
    "Shipping_Address": 'Afleveradres',
    "Order_Comments": 'Opmerkingen order',
    "Product": 'Product',
    "Item_Price": 'Prijs artikel',
    "Line_Total": 'Totaal rij',
    "Click_to_make_new_row": 'Nieuwe orderregel',
    "Search": 'Zoek',
    "Submit_Order": 'Bevestig bestelling',
    "Make_a_payment": 'Doe betaling',
    "Make_Payment": 'Doe betaling',
    "Play": 'Speel',
    "Type": 'Type',
    "Documet_Date": 'Datum document',
    "Invoice_No": 'Factuurnummer',
    "Business_partner": 'Klantnaam',
    "Contact": 'Contact',
    "Ordertype": 'Ordertype',
    "Unit": 'Eenheid',
    "Delivery": 'Levering #',
    "DeliveryDate": 'Datum levering',
    "Pay": 'Betaal',
    "Collection": 'Collectie',
    "Your_order_reference": 'Uw bestlreferentie',
    "Invoice": 'Factuur',
    "Invoice #": 'Factuur #',
    "InvoiceDate": 'Factuurdatum',
    "OrderNumber": 'Ordernummer',
    "Batch": 'Partij',
    "Invoices": 'Facturen',
    "CreditNotNo": 'Creditnota nummer',
    "DateCreated": 'Datum aangemaakt',
    "Balance": 'Saldo',
    "Applied_by": 'Ingevoerd door',
    "Place_sample_order": 'Plaats sample order',
    "Save": 'Opslaan',
    "Collection_or_historical_orders": 'Collectie of bestelgeschiedenis',
    "Action": 'Actie',
    "Product_code": 'Artikel',
    "Jobs": 'Banen',
    "Length_specified": 'Geef lengte op',
    "Length_calculated": 'Gecalculeerde lengte',
    "Pattern": 'Patroon',
    "Order_rule": 'Orderregel',
    "Add_stroke": 'Baan toevoegen',
    "Remove_stroke": 'Verwijder baan',
    "Calculate_pattern_length": 'Bereken patroon lengte',
    "Processing": 'Verwerken',
    "Cancel": 'Annuleren',
    "Verspringend": 'Verspringend',
    "Accept_delivery": 'Bevestig levering',
    "No_partial_deliveries": 'Geen deellevering',
    "Go_back_to_the_order": 'Ga terug naar uw order',
    "Resume": 'Samenvatting',
    "Allowance": 'Toeslag',
    "Product_image": 'Productfoto',
    "Email": 'E-mail',
    "E-Mail address order confirmation": 'E-mailadres orderbevestiging',
    "Order confirmation email address?": 'Is dit het juiste e-mailadres voor de orderbevestiging?',
    "Sign_up": 'Inschrijven',
    "have_an_account": "U heeft geen account?",
    "User": 'Gebruiker',
    "Users": 'Gebruikers',
    "Add_User": 'Voeg gebruiker toe',
    "UserName": 'Gebruikersnaam',
    "PhoneNumber": 'Telefoonnummer',
    "Active": 'Actief',
    "Result_on_page": 'Pagina resultaat',
    "Results_of": 'Resultaten',
    "Delete": 'Verwijder',
    "Edit": 'Bewerk',
    "Role": 'Rol',
    "UserCode": 'Gebruikerscode',
    "CustomerCode": 'Klantcode',
    "FirstName": 'Voornaam',
    "LastName": 'Achternaam',
    "keep_logged_in": 'Blijf ingelogd',
    "have_account": "Geen account?",
    "Add_order": 'Order toevoegen',
    "ItemCode": 'Artikel',
    "ItemDescription": 'Artikelomschrijving',
    "DocNum": 'Doc nummer',
    "DocDate": 'Doc datum',
    "cardName": 'Kaart naam',
    "dbsCollection": 'Collectie',
    "Filter": 'Filter',
    "Quick_search": 'Snel zoeken',
    "Add_filter": 'Filter toevoegen',
    "Deliveries": 'Leveringen',
    "Salesinvoices": 'Facturen/credits',
    "DocTotal": 'Doc totaal',
    "Deactive": 'Deactiveren',
    "Returns": 'Retouren',
    "Edit_User": 'Bewerk gebruiker',
    "Test": 'Test',
    "Login As": 'Inlog Als',
    "Go_Back_Admin": 'Ga terug naar Admin',
    "Customer_reference": 'Commissie',
    "City_Country": 'Plaatsnaam, Land',
    "Street": 'Straat (max. 40 karakters)',
    "Product_description": 'Collectie',
    "Image": 'Foto',
    "Expected_deliver_week" : 'Verwachte leverweek',
    "Order": 'Order',
    "Language": 'Taal',
    "Select_language": 'Selecteer uw taal',
    "Search_Item": 'Zoek artikel',
    "Add_to_order": 'Voeg toe',
    "Orderline": 'Orderregel',
    "Staggered": 'Verspringend',
    "Rows": 'Aantal banen/stukken',
    "Row_length": 'Lengte in meters (90 cm = 0,9)',
    "Row_length_calculated": 'Lengte in meters (90 cm = 0,9)',
    "Total_row_length": 'Totale baanlengte berekend',
    "Total_lengh": 'Totale lengte in meters',
    "Add_to_row": 'Voeg regel toe',
    "PatternCalculate": 'Bereken',
    "Pattern_calculation": 'Patroon berekenen',
    "Date": 'Datum',
    "DocDueDate": 'Doc Vervaldatum',
    "OrderSummary": 'Order samenvatting',
    "Profile": 'Profiel',
    "ResetPassword": 'Wachtwoord opnieuw instellen',
    "Logout": 'Log uit',
    "CustomerName": 'Klantnaam',
    "ShowPrice": 'Toon prijs',
    "Return details": 'Retourgegevens',
    "Salesinvoice details": 'Factuurgegevens',
    "Delivery details": 'Verzendgegevens',
    "NoPatternCalculation": 'Geen patroon berekenen',
    "Pattern calculation needs to be filled for this product, do you want to delete the product from the order lines?": "Voor dit product dient 'patroonberekening' te worden ingevuld, wilt u het product uit de orderregels verwijderen?",
    "Zipcode": "Postcode",
    "City": "Plaatsnaam",
    "StreetNo": 'Straatnaam',
    "News": "Nieuws",
    "Add News": "Voeg nieuws toe",
    "Subject Dutch": "Onderwerp Nederlands",
    "Subject English": "Onderwerp Engels",
    "Subject German": "Onderwerp Duits",
    "Subject French": "Onderwerp Frans",
    "Subtitle Dutch": "Subtitel Nederlands",
    "Subtitle English": "Subtitel Engels",
    "Subtitle German": "Subtitel Duits",
    "Subtitle French": "Subtitel Frans",
    "Text Dutch": "Tekst Nederlands",
    "Text English": "Tekst Engels",
    "Text German": "Tekst Duits",
    "Text French": 'Tekst Frans',
    "StartDate": "Startdatum",
    "EndDate": "Einddatum",
    "Are you sure to do this?": "Weet u zeker dat u dit wilt doen?",
    "View": "Bekijken",
    "Edit News": "Bewerk nieuws",
    "View News": "Bekijk nieuws",
    "Are you sure that you want to return this item?": "Weet u zeker dat u dit artikel wilt retourneren?",
    "Approve order": "Bevestig order",
    "I agree with the Terms and Conditions": "Ik ga akkoord met de voorwaarden",
    "I agree with the": "Ik ga akkoord met de",
    "Terms and Conditions":"voorwaarden",
    "No partial deliveries": "Geen gedeelde leveringen",
    "Return #": 'Retour #',
    "ReturnDate": 'Retourdatum',
    "Show All Users": 'Toon alle gebruikers',
    "Reference": 'Referentie',
    "Add return": 'Nieuwe retouraanvraag',
    "Return": 'Retour',
    "Are you sure?": 'Weet u het zeker?',
    "New Item": 'Nieuw artikel',
    "Name": "Naam",
    "Reference_Header":"Referentie",
    "Reference_Line":"Referentie tekst",
    "Invoicenumber": "Document",
    "Invoicedate": "Datum",
    "Item": "Artikel",
    "Open_invoices": "Open facturen/credits", 
    "Document": "Document",
    "OpenAmount": "Openstaand bedrag",
    "Days_Overdue": "Aantal dagen open",
    "Outstanding_Invoices": "Openstaande facturen",
    "Help_Text": "Help tekst",
    "add_help_text": "Voeg help tekst toe",
    "page": "Pagina",
    "Title": "Titel",
    "Subtitle":"Subtitel",
    "Text":"Tekst",
    "contentDutch": "Nederlands",
    "contentEnglish": "Engels",
    "contentGerman": "Duits",
    "contentFrench": "Frans",
    "Edit_help_text": "Help tekst bewerken",
    "Documents": "Actuele informatie",
    "DocumentUrl": "Url document",
    "Add_Document": "Voeg document toe",
    "Edit_Document": "Bewerk document",
    "DocumentType": "Type document",
    "Add_DocumentType": "Voeg type document toe",
    "documentLanguage": "Taal document",
    "Edit_DocumentType": "Type document bewerken",
    "Confirm_Delete_Message": "Weet u zeker dat u dit wilt verwijderen?",
    "Document_File": "Bestand document",
    "Not_Registered_Yet": "Klant, maar nog geen account?",
    "Register_Here": "Vraag dan hier je account aan",
    "Registration": "Account aanvragen",
    "Approve_Users": "Gebruikers goedkeuren",
    "Approve": "Goedkeuren",
    "PickUpDate": "Afhaaldatum",
    "Comments": "Opmerkingen",
    "Return_Item": "Retour aanvragen",
    "Settings": "Instellingen",
    "Notifications": "Meldingen",
    "Terms_Of_Delivery": "Leveringsvoorwaarden",
    "Add" : "Voeg toe",
    "Add_Notifications_Email": "Voeg e-mail melding toe",
    "Update_Terms_Of_Delivery": "Update leveringsvoorwaarden",
    "Delivery Date" : "Beschikbaar",
    "Shipping_type": "Verzendwijze",
    "ErrorLogs": "Error logboek",
    "id": "id",
    "logLevel": "Log niveau",
    "logEvent": "Log gebeurtenis",
    "dateTime": "Datum Tijd",
    "invokedByUserId": "Aangeroepen door gebruikers-id",
    "referenceId": "Referentie nummer",
    "Message": "Bericht",
    "Country":"Land",
    "News History": "Nieuws historie",
    "Registration_sucess_message": "Uw account zal worden geactiveerd na goedkeuring van de beheerder.",
    "CompanyName": "Bedrijfsnaam",
    "Address": "Adres",
    "Back_to_Log_in": "Terug naar aanmelden",
    "ExcludeProductgroups" : "Sluit productgroepen uit",
    "ExcludeProductgroups_success": "Uitgesloten productgroepen succesvol toegevoegd",
    "CustomerCodes": "Klantcodes",
    "Add_New": "Nieuw toevoegen",
    "Number_of_packages": "Aantal colli",
    "Partial": "Gedeeltelijk",
    "Maintenance": "Onderhoud",
    "Maintenance_Mode": "Onderhoudsmodus",
    "Maintenance_Text": "Onderhoudtekst",
    "Direct": "Direct",
    "CustomerGroupCode": "Klantgroep code",
    "Action (download PDF)": "Actie (download PDF)",
    "Status delivery": "Status Levering",
    "Action (T&T and PDF)": "Actie (T&T and PDF)",
    "Reference Header" : "Referentie Header",
    "Reference Line" : "Referentielijn",
    "Order Type" : "Order Type",
    "Commission": "Commissie",
    "Submit Order": "Ga door",
    "Success": "Gelukt!",
    "Submit": "Bevestig",
    "Order total": "Order totaal",
    "Order confirmation": "Orderbevestiging",
    "Sent": "Verstuurd",
    "Return number": "Retournummer",
    "Return order": "Retouropdracht",
    "Current information": "Actuele informatie",
    "Document name": "Naam document",
    "Request a return": "Retour aanvragen",
    "Submit return": "Bevestig retour",
    "Partial_deliveries":"Deellevering",
    "Expenses":"Ordertoeslag",
    "login": "Aanmelden",
    "CReference": 'Referentie',
    "ChangePassword": 'Wachtwoord wijzigen',
    "CurrentPassord": 'Huidig wachtwoord',
    "StockInfo": "Voorraad opvragen",
    "Calculate": "Starten",
    "Check": "Opvragen",
    "Stock Expected delivery week": "Verwachte week van levering",
    "Stork Direct": "Direct",
    "Incourant": "Artikel is uitlopend en onvoldoende op voorraad",
    "Standard forwarder": "Standaard vervoerder",
    "One moment please": "Het opvragen van voorraad duurt ongeveer 10-15 seconden.",
    "Available": "Beschikbaar",
    "Customer_yet": "Nog geen klant, maar wil je dat graag worden?",
    "Contact_us_here": "Neem hier contact op met",
    "Pdf": "PDF",
    "Open": "Open",
    "Close": "Afgesloten",
    "Item has been discontinued and is not sufficiently available": "Artikel is uitlopend en onvoldoende op voorraad",
    "Please check quantity": "Graag aantal controleren",
    "Is this number correct?": "Klopt dit aantal?"
  },

  German: {
    "The maximum number of samples is 5.": "Die maximale Anzahl der Muster beträgt 5.",
    "CalculatePatternMessage": "Um Ihnen schnell liefern zu können, ist es wichtig, dass Sie immer die Anzahl Stofflängen und Meter angeben. Ein Beispiel: Wenn Sie einen raumhohen Stoff für zwei verschiedene Fenster eingeben, füllen Sie die Bestellung bitte 2 Zeilen aus, nicht 1 Zeile mit der Gesamtzahl der Meter. Diese Stofflängen werden aus derselben Anfertigung geliefert.",
    "NoItemMessage": "Artikel is nicht mehr lieferbahr",
    "AddressBook": "Adressbuch",
    "TrackAndTrace": "Track & Trace",
    "Username": 'Nutzername',
    "Password": 'Passwort',
    "ConfirmPassword": 'Bevestig wachtwoord',
    "Forgot_password": 'Passwort vergessen?',
    "Sign_in": 'Einloggen',
    "Orders": 'Bestellungen',
    "Dashboard": 'Instrumententafel',
    "Sales_No": 'Auftragsnummer',
    "Previous":'Bisherige',
    "Next":'Nächster',
    "Show_entries":'Show Einträge',
    "Show":'Show',
    "Entries":'Einträge',
    "Show_page":'Show Seite',
    "PO_No": 'PO_No',
    "Order_Date": 'Auftragsdatum',
    "Shipping_Date": 'Versand Date',
    "Total": 'Gesamt',
    "Currency": 'Währung',
    "Status": 'Status',
    "Download": 'Herunterladen',
    "Past_Due": 'Vergangenheit Fällig',
    "Due_Soon": 'Fällig Bald',
    "Total_Outstanding": 'Gesamt Hervorragend',
    "Place_an_order": 'Bestellen',
    "Place_a_sample_order": "Muster bestellen",
    "Search_Lines": 'Suche Linien',
    "Make_a_Payment": 'Machen ein Zahlung',
    "Credit_card": 'Anerkennung Karte',
    "Last_5_Orders": 'Zuletzt 5 Aufträge',
    "Next_5_Deliveries_Due": 'Nächster 5 Lieferungen Fällig',
    "Outstanding_Payments": 'Hervorragend Zahlungen',
    "Sales_Number": 'Der Umsatz Nummer',
    "Delivery_Number": 'Lieferung Nummer',
    "Due_Date": 'Datum',
    "Invoice_Number": 'Rechnungsnummer',
    "Amount": 'Betrag',
    "Enter_email": 'Eingeben Email',
    "Back_to_Sign_in": 'Zurück zu Zeichen ',
    "Next_Step": 'Nächster Schritt',
    "New_Password": 'Neues Passwort',
    "Confirm_Password": 'Passwort bestätigen',
    "Item_Name": 'Article Name',
    "Description": 'Umschreibung',
    "Quantity": 'Anzahl',
    "Shipped_Quantity": 'Versand erfolgt Menge',
    "Price": 'Preis',
    "Requested_Delivery_Date": 'Angefordert Lieferung Datum',
    "PO_Reference": 'Po Referenz',
    "Billing_Address": 'Rechnungsadresse',
    "Shipping_Address": 'Lieferadresse',
    "Order_Comments": 'Bestellung Bemerkungen',
    "Product": 'Product',
    "Item_Price": 'Item Preis',
    "Line_Total": 'Linie Gesamt',
    "Click_to_make_new_row": 'Neue Auftragsposition',
    "Search": 'Suche',
    "Submit_Order": 'Einreichen  Bestellung',
    "Make_a_payment": 'Machen ein Zahlung',
    "Make_Payment": 'Machen Zahlung',
    "Play": 'Abspielen',
    "Type": 'Art',
    "Documet_Date": 'Dokumentieren Datum',
    "Invoice_No": 'Rechnung Nein',
    "Business_partner": 'Kundenname',
    "Contact": 'Kontakt',
    "Ordertype": 'Auftragsart',
    "Unit": 'Einheit',
    "Delivery": 'Lieferung #',
    "DeliveryDate": 'LieferungDatum',
    "Pay": 'Zahlen',
    "Collection": 'Kollektion',
    "Your_order_reference": 'Ihre Bestellnummer',
    "Invoice": 'Rechnung',
    "Invoice #": 'Rechnung #',
    "InvoiceDate": 'RechnungDatum',
    "OrderNumber": 'BestellungNummer',
    "Batch": 'Anfertigung',
    "Invoices": 'Rechnungen',
    "CreditNotNo": 'Anerkennung Nicht Nein',
    "DateCreated": 'Date erstellt',
    "Balance": 'Balance',
    "Applied_by": 'Durch Anwendung',
    "Place_sample_order": "Passer la commande d'échantillon",
    "Save": 'Speichern',
    "Collection_or_historical_orders": 'Sammlung oder historische Ordnungen',
    "Action": 'Handlung',
    "Product_code": 'Artikel',
    "Jobs": 'Arbeitsplätze',
    "Length_specified": 'Länge angegeben',
    "Length_calculated": 'Länge berechnet',
    "Pattern": 'Muster',
    "Order_rule": 'Auftragsposition',
    "Add_stroke": 'Strich hinzufügen',
    "Remove_stroke": 'Hub entfernen',
    "Calculate_pattern_length": 'Berechnen Sie die Musterlänge',
    "Processing": 'Wird bearbeitet',
    "Cancel": 'Stornieren',
    "Verspringend": 'versetzt',
    "Accept_delivery": 'Lieferung annehmen',
    "No_partial_deliveries": 'Teillieferung',
    "Go_back_to_the_order": 'Gehen Sie zurück zur Bestellung',
    "Resume": 'Weitermachen',
    "Allowance": 'Beihilfe',
    "Product_image": 'Produktbild',
    "Email": 'Email',
    "E-Mail address order confirmation": 'Email Adresse Auftragsbestätigung',
    "Order confirmation email address?": 'Ist dies die richtige E-Mail-Adresse für die Auftragsbestätigung?',
    "Sign_up": 'Anmelden',
    "have_an_account": 'Sie haben noch keinen Account?',
    "User": 'Nutzer',
    "Users": 'Benutzer',
    "Add_User": 'Nutzer hinzufügen',
    "UserName": 'Nutzername',
    "PhoneNumber": 'Telefonnummer',
    "Active": 'Aktiv',
    "Result_on_page": 'Ergebnis auf Seite',
    "Results_of": 'Ergebnisse von',
    "Delete": 'Löschen',
    "Edit": 'Bearbeiten',
    "Roles": 'Rollen',
    "UserCode": 'Benutzercode',
    "CustomerCode": 'Kundencode',
    "FirstName": 'Vorname',
    "LastName": 'Nachname',
    "keep_logged_in": 'Lass mich angemeldet',
    "have_account": 'Ich habe kein Konto',
    "Add_order": 'Auftrag hinzufügen',
    "ItemCode": 'ArtikelCode',
    "ItemDescription": 'ArtikelBeschreibung',
    "DocNum": 'DocNum',
    "DocDate": 'DocDatum',
    "cardName": 'KarteName',
    "dbsCollection": 'dbsSammlung',
    "Filter": 'Filter',
    "Quick_search": 'schnell suchen',
    "Add_filter": 'HinzufügenFilter',
    "Deliveries": 'Lieferungen',
    "Salesinvoices": 'Rechnungen/Gutschriften',
    "DocTotal": 'DocGesamt',
    "Deactivate": 'DeaktivierenSie',
    "Returns": 'Kehrt zurück',
    "Edit_User": 'Bearbeiten Nutzer',
    "Test": 'Prüfung',
    "Login As": 'Anmelden als',
    "Go_Back_Admin": 'Gehe zurück Admin',
    "Customer_reference": 'Referenz',
    "City_Country": 'Stadt Land',
    "Street": 'Straße (max. 40 Zeichen)',
    "Product_description": 'Produkt Beschreibung',
    "Image": 'Foto',
    "Expected_deliver_week": 'Voraussichtliche Lieferwoche',
    "Order": 'Bestellen',
    "Language": 'Sprache',
    "Select_language": 'Wähle deine Sprache',
    "Search_Item": 'Artikel suchen',
    "Add_to_order": 'Hinzufügen',
    "Orderline": 'Bestelllinie',
    "Staggered": 'Gestaffelt',
    "Rows": 'Anzahl Stofflängen',
    "Row_length": 'Länge in Metern (90 cm = 0,9)',
    "Row_length_calculated": 'Stoffbahnlänge berechnet',
    "Total_row_length": 'Totale Stoffbahnlänge berechnet',
    "Total_lengh": 'Gesamtlänge in Metern',
    "Add_to_row": 'Position hinzufügen',
    "PatternCalculate": 'Berechnen',
    "Pattern_calculation": 'Musterberechnung',
    "Date": 'Datum',
    "DocDueDate": 'DocFälligDatum',
    "OrderSummary": 'Auftrag Zusammenfassung',
    'Profile': 'Profil',
    "ResetPassword": 'ZurücksetzenPasswort',
    "Logout": 'Ausloggen',
    "CustomerName": 'Kundenname',
    "ShowPrice": 'Preis anzeigen',
    "Return details": 'Details zurückgeben',
    "Salesinvoice details": 'Details zur Verkaufsrechnung',
    "Delivery details": 'Lieferdetails',
    "NoPatternCalculation": 'Kein Rapport berechnen',
    "Patroonberekening moet worden ingevuld voor dit product, wilt u het product uit de orderregels verwijderen?": "Die Musterberechnung muss für dieses Produkt ausgefüllt werden. Möchten Sie das Produkt aus den Bestellpositionen löschen?",
    "Zipcode": "Postleitzahl",
    "City": "Ort",
    "StreetNo": "StraßeNein",
    "News": "Nachrichten",
    "Add News": "Nachrichten Hinzufügen",
    "Subject Dutch": 'Fach Niederländisch',
    "Subject English": "Fach Englisch",
    "Subject German": "Fach Deutsch",
    "Subject French": "Fach Französisch",
    "Subtitle Dutch": "Untertitel Niederländisch",
    "Subtitle English": "Untertitel Englisch",
    "Subtitle German": "Untertitel Deutsch",
    "Subtitle French": "Untertitel Französisch",
    "Text Dutch": "Text Nederlands",
    "Text English": "Text Engels",
    "Text German": "Text Duits",
    "Text French": 'Text Frans',
    "StartDate": "AnfangsDatum",
    "EndDate": "EndeDatum",
    "Are you sure to do this?": "Bist du sicher das zu tun?",
    "View": "Aussicht",
    "Edit News": "Nachrichten Bearbeiten",
    "View News": "Nachrichten Anzeigen",
    "Are you sure that you want to return this item?": "Sind Sie sicher, dass Sie diesen Artikel zurückgeben möchten?",
    "Approve order": "Bestellung genehmigen",
    "I agree with the Terms and Conditions": "Ich stimme den Nutzungsbedingungen zu",
    "I agree with the": "Ich stimme dem ",
    "Terms and Conditions":"Geschäftsbedingungen",
    "No partial deliveries": "Keine Teillieferungen",
    "Return #": 'Return #',
    "ReturnDate": 'ReturnDate',
    "Show All Users": 'Alle Benutzer anzeigen',
    "Reference": 'Referenz',
    "Add return": 'Neue Anfrage Rücksendung',
    "Return": 'Rücksendung',
    "Are you sure?": 'Bist du sicher?',
    "New Item": 'Neuer Artik',
    "Name": "Name",
    "Reference_Header":"Referenzheader",
    "Reference_Line":"Text Referenz",
    "Invoicenumber": "Dokument",
    "Invoicedate": "Datum",
    "Item": "Artikel",
    "Open_invoices": "Offenstehende Rechnungen/Gutschriften",
    "Document": "Dokument",
    "OpenAmount": "Offener Betrag",
    "Days_Overdue": "Anzahl Tage ausstehend",
    "Outstanding_Invoices": "Ausstehende Rechnungen",
    "Help_Text": "Hilfstext",
    "add_help_text": "Hilfetext hinzufügen",
    "page": "Seite",
    "Title": "Titel",
    "Subtitle":"Untertitel",
    "Text":"Text",
    "contentDutch": "contentDutch",
    "contentEnglish": "contentEnglish",
    "contentGerman": "contentGerman",
    "contentFrench": "contentFrench",
    "Edit_help_text": "Hilfetext bearbeiten",
    "Documents": "Unterlagen",
    "DocumentUrl": "DocumentUrl",
    "Add_Document": "Dokument hinzufügen",
    "Edit_Document": "Dokument bearbeiten",
    "DocumentType": "DocumentType",
    "Add_DocumentType": "DocumentType hinzufügen",
    "documentLanguage": "documentLanguage",
    "Edit_DocumentType": "DocumentType bearbeiten",
    "Confirm_Delete_Message": "Sind Sie sicher, dies zu löschen?",
    "Document_File": "Document Datei",
    "Not_Registered_Yet": "Kunde, aber noch kein Konto?",
    "Register_Here": "Fordern Sie hier Ihr Konto an",
    "Registration": "Anmeldung Ihres Kundenportal",
    "Approve_Users": "Benutzer genehmigen",
    "Approve": "Genehmigen",
    "Commission":"Kommission",
    "PickUpDate": "Abholtermin",
    "Comments": "Bemerkungen",
    "Return_Item": "Artikel zurückgeben",
    "Settings": "die Einstellungen",
    "Notifications": "Benachrichtigungen",
    "Terms_Of_Delivery": "Lieferbedingungen",
    "Add" : "Hinzufügen",
    "Add_Notifications_Email": "Benachrichtigungs-E-Mail hinzufügen",
    "Update_Terms_Of_Delivery": "Lieferbedingungen aktualisieren",
    "Delivery Date" : "Verfügbar",
    "Shipping_type": "Versandart",
    "ErrorLogs": "ErrorLogs",
    "id": "id",
    "logLevel": "logLevel",
    "logEvent": "logEvent",
    "dateTime": "Terminzeit",
    "invokedByUserId": "invokedByUserId",
    "referenceId": "Referenz ID",
    "Message": "Botschaft",
    "Country":"Land",

    "Registration_sucess_message": "Ihr Konto wird aktiviert, sobald der Administrator es genehmigt.",
    "CompanyName": "Name der Firma",
    "Address": "Adresse",
    "Back_to_Log_in": "Zurück zur Anmeldung",
    "ExcludeProductgroups" : "ExcludeProductgroups",
    "ExcludeProductgroups_success": "ExcludeProductgroups added successfully",
    "CustomerCodes": "CustomerCodes",
    "Add_New": "Add New",
    "Number_of_packages": "Anzahl Pakete",
    "Partial": "Partial",
    "Maintenance": "Instandhaltung",
    "Maintenance_Mode": "Wartungsmodus",
    "Maintenance_Text": "Wartungstext",
    "Direct":"Direkte",
    "CustomerGroupCode": "KundeGruppeCode",
    "Action (download PDF)": "Aktion (Herunterladen PDF)",
    "Status delivery": "Status Lieferung",
    "Action (T&T and PDF)": "Aktion (T&T and PDF)",
    "Reference Header" : "Referenz-Header",
    "Reference Line" : "Bezugslinie",
    "Order Type" : "Auftragsart",
    "Submit Order": "Bestätigen",
    "Success": "Erfolgreich",
    "Submit": "Bestätigen",
    "Order total": "Auftragssumme",
    "Order confirmation": "Auftragsbestätigung",
    "Sent": "Geschickt",
    "Return number": "Rücksendungsnummer",
    "Return order": "Auftrag zur Rücksendung",
    "Current information": "Aktuelle Auskünfte",
    "Document name": "Name Dokument",
    "Request a return": "Rückgabe",
    "Submit return": "Rückgabe bestätigen",
    "Expenses":"Versandkosten",
    "login": "Anmelden",
    "CReference": 'Referenz',
    "ChangePassword": 'Passwort ändern',
    "CurrentPassord": 'Jetziges Passwort',
    "StockInfo": "Vorrat nachprüfen",
    "Calculate": "Anfangen",
    "Check": "Prüfen",
    "Stock Expected delivery week": "Voraussichtliche Lieferwoche",
    "Stork Direct": "Sofort",
    "Incourant": "Artikel ist ausgelaufen und nicht ausreichend vorrätig",
    "Standard forwarder": "Standard forwarder",
    "One moment please": "Eine Vorratanfrage dauert ungefähr 10-15 Sekunden.",
    "Available": "Erhältlich",
    "Customer_yet": "Sie sind noch kein Kunde, möchten es aber werden?",
    "Contact_us_here": "Kontaktieren Sie uns hier an",
    "Pdf": "PDF",
    "Open": "Offen",
    "Close": "Abgeschlossen",
    "Item has been discontinued and is not sufficiently available": "Artikel ist ausgelaufen und nicht ausreichend vorrätig",
    "Please check quantity": "Bitte überprüfen Sie die Menge",
    "Is this number correct?": "Stimmt diese Anzahl?",
    "News History": "Nachrichten Geschichte"
  },

  French: {
    "The maximum number of samples is 5.": "Le nombre maximal d'échantillons est 5.",
    "CalculatePatternMessage": "Pour que la livraison soit rapide, il est important que vous indiquiez toujours le nombre de lés/pièces et de mètres. Exemple : si vous avez un tissu de la hauteur d'une pièce pour deux fenêtres différentes, veuillez saisir la commande sur 2 lignes et non sur 1 ligne avec le nombre total de mètres. Ces pièces seront livrées à partir du même bain.",
    "NoItemMessage": "l’article n’est plus disponsible",
    "AddressBook": "Carnet d'adresses",
    "TrackAndTrace": "Suivi des envois",
    "Username": "Nom d'utilisateur",
    "Password": 'Mot de passe',
    "ConfirmPassword": 'Confirmez le mot de passe',
    "Forgot_password": 'Mot de passe oublié ?',
    "Sign_in": "S'identifierg",
    "Orders": 'Commandes',
    "Dashboard": 'Tableau de bord',
    "Sales_No": 'Ventes No',
    "Previous":'Précédent',
    "Next":'Suivant',
    "Show_entries":'Afficher entrées ',
    "Show":'Afficher',
    "Entries":'entrées',
    "Show_page":'Afficher page ',
    "PO_No": 'PO_No',
    "Order_Date": 'Date de la commande',
    "Shipping_Date": 'livraison Date',
    "Total": 'Total',
    "Currency": 'Valuta',
    "Status": 'Statut',
    "Download": 'Télécharger',
    "Past_Due": 'Passé  Du',
    "Due_Soon": 'Du  Bientôt',
    "Total_Outstanding": 'Total Exceptionnel',
    "Place_an_order": 'Commander',
    "Place_a_sample_order": "Commander un échantillon",
    "Search_Lines": 'ChercherLignes',
    "Make_a_Payment": 'Faire une Paiement',
    "Credit_card": 'Crédit carte',
    "Last_5_Orders": 'Dernier 5 Ordres',
    "Next_5_Deliveries_Due": 'Prochain 5 Livraisons Du',
    "Outstanding_Payments": 'Exceptionnel Paiements',
    "Sales_Number": 'Ventes Nombre',
    "Delivery_Number": 'Livraison Nombre',
    "Due_Date": 'Du Date',
    "Invoice_Number": "Facture d'achat Nombre",
    "Amount": 'Montant',
    "Enter_email": 'Entrer email',
    "Back_to_Sign_in": 'Retour à ‘se connecter’',
    "Next_Step": 'étape suivante',
    "New_Password": 'Nouveau mot de passe',
    "Confirm_Password": 'Confirmer mot de passe',
    "Item_Name": 'Article Nom',
    "Description": 'Description',
    "Quantity": 'Nombre',
    "Shipped_Quantity": 'Expédié Quantité',
    "Price": 'Prix',
    "Requested_Delivery_Date": 'Demandé Livraison Date',
    "PO_Reference": 'Po Reference',
    "Billing_Address": 'Adresse de facturation',
    "Shipping_Address": 'Adresse de livraison',
    "Order_Comments": 'Commande Comments',
    "Product": 'Produit',
    "Item_Price": 'Article Prix',
    "Line_Total": 'Ligne Total',
    "Click_to_make_new_row": 'Nouvelle ligne de commande',
    "Search": 'Recherche',
    "Submit_Order": 'Soumettre Commande',
    "Make_a_payment": 'Faire une Paiement',
    "Make_Payment": 'Faire Paiement',
    "Play": 'Jouer',
    "Type": 'Type',
    "Documet_Date": 'Document Date',
    "Invoice_No": "Facture d'achat  No",
    "Business_partner": 'Nom client', 
    "Contact": 'Contact',
    "Ordertype": 'Type de commande',
    "Unit": 'Unité',
    "Delivery": 'Disponible',
    "DeliveryDate": 'LivraisonDate',
    "Pay": 'Payer',
    "Collection": 'Collection',
    "Your_order_reference": 'Votre reférence de commande',
    "Invoice": "Facture",
    "Invoice #": "Facture d'achat #",
    "InvoiceDate": 'RechnungDate',
    "OrderNumber": 'CommandeNombre',
    "Batch": 'Bain',
    "Invoices": 'Factures',
    "CreditNotNo": 'Crédit no dog Non',
    "DateCreated": 'Date Créé',
    "Balance": 'Équilibre',
    "Applied_by": 'Appliqué par',
    "Place_sample_order": 'Musterbestellung aufgeben',
    "Save": 'Sauvegarder',
    "Collection_or_historical_orders": 'Collection ou commandes historiques',
    "Action": 'Action',
    "Product_code": 'Article',
    "Jobs": 'Travaux',
    "Length_specified": 'Longueur spécifiée',
    "Length_calculated": 'Longueur calculé',
    "Pattern": 'Raccord',
    "Order_rule": 'Ligne de commande',
    "Add_stroke": 'Ajouter Coup',
    "Remove_stroke": 'Retirer Coup',
    "Calculate_pattern_length": 'Calculer modèle longueur',
    "Processing": 'En traitement',
    "Cancel": 'Annuler',
    "Verspringend": 'Verspringend',
    "Accept_delivery": "J'accepte Livraison",
    "No_partial_deliveries": 'Non Partiel Livraisons',
    "Go_back_to_the_order": 'Revenir à la commande',
    "Resume": 'Reprendre',
    "Allowance": 'Allocation',
    "Product_image": 'Produit image',
    "Email": 'Email',
    "E-Mail address order confirmation": 'Adresse email confirmation de commande',
    "Order confirmation email address?": "Est-ce l'adresse email correcte pour la confirmation de la commande?",
    "Sign_up": "S'inscrire",
    "have_an_account": "Vous n'avez pas de compte?",
    "User": 'Utilisatrice',
    "Users": 'Utilisatrices',
    "Add_User": 'Ajouter un utilisateur',
    "UserName": "Nom d'utilisateur",
    "PhoneNumber": "Numéro de téléphone",
    "Active": 'Active',
    "Result_on_page": "Résultat sur la page",
    "Results_of": 'Resultats de',
    "Delete": 'Supprimer',
    "Edit": "Éditer",
    "Roles": "Les rôles",
    "UserCode": "Code d'utilisateur",
    "CustomerCode": 'Code client',
    "FirstName": 'Prénom',
    "LastName": 'Nom de famille',
    "keep_logged_in": "Rester connecté",
    "have_account": "Je n'ai pas de compte",
    "Add_order": 'Ajouter une commande',
    "ItemCode": "Code de l'article",
    "ItemDescription": "Description de l'objet",
    "DocNum": 'DocNum',
    "DocDate": 'DocDate',
    "cardName": 'Nom de la carte',
    "dbsCollection": 'dbsCollection',
    "Filter": 'Filtre',
    "Quick_search": 'Recherche rapide',
    "Add_filter": 'Ajouter un filtre',
    "Deliveries": 'Livraisons',
    "Salesinvoices": 'Factures/avoirs',
    "DocTotal": 'DocTotal',
    "Deactivate": 'Désactiver',
    "Returns": 'Retours',
    "Edit_User": "Modifier l'utilisateur",
    "Test": 'Tester',
    "Login As": "Se connecter en tant que",
    "Go_Back_Admin": 'Retour administrateur',
    "Customer_reference": 'Référence client',
    "City_Country": 'Ville Pays',
    "Street": 'Rue (40 caractères max.)',
    "Product_description": 'Description du produit',
    "Image": 'Photo',
    "Expected_deliver_week": 'Semaine de livraison prévue',
    "Order": 'Ordre',
    "Language": 'Langue',
    "Select_language": 'Choisissez votre langue',
    "Search_Item": "Recherche de l’article",
    "Add_to_order": 'Ajouter à la commande',
    "Orderline": 'Order look like',
    "Staggered": 'Décalé',
    "Rows": 'Nombre de lés/pièces',
    "Row_length": 'Longueur en mètres (90 cm = 0,9)',
    "Row_length_calculated": 'Longueur calculée',
    "Total_row_length": 'Longueur calculée totale',
    "Total_lengh": ' Longueur totale en mètres',
    "Add_to_row": 'Ajouter ligne',
    "PatternCalculate": 'Calculer',
    "Pattern_calculation": 'Calculate the model',
    "Date": 'Date',
    "DocDueDate": 'DocDueDate',
    "OrderSummary": 'Ordre Sommaire',
    "Profile": 'Profil',
    "ResetPassword": 'Réinitialiser Mot de passe',
    "Logout": 'Se déconnecter',
    "CustomerName": 'Nom de client',
    "ShowPrice": 'Montrer prix',
    "Return details": 'Détails de retour',
    "Salesinvoice details": 'Détails de la facture de vente',
    "Delivery details": 'Détails de livraison',
    "NoPatternCalculation": 'Sans tenir compte du raccord',
    "Pattern calculation needs to be filled for this product, do you want to delete the product from the order lines?": "Le calcul du modèle doit être rempli pour ce produit, voulez-vous supprimer le produit des lignes de commande?",
    "Zipcode": "Code postale",
    "City": "Ville",
    "StreetNo": "Rue non",
    "News": "Nouvelles",
    "Add News": "Ajouter des nouvelles",
    "Subject Dutch": "Matière Néerlandais",
    "Subject English": "Sujet anglais",
    "Subject German": "Sujet allemand",
    "Subject French": "Sujet Française",
    "Subtitle Dutch": "sous-titre néerlandais",
    "Subtitle English": "sous-titre anglais",
    "Subtitle German": "sous-titre allemand",
    "Subtitle French": "sous-titre Française",
    "Text Dutch": "Texte Nederlands",
    "Text English": "Texte Engels",
    "Text German": "Texte Duits",
    "Text French": 'Texte Frans',
    "StartDate": "Date de début",
    "EndDate": "Date de fin",
    "Are you sure to do this?": "Êtes-vous sûr de faire cela?",
    "View": "Vue",
    "Edit News": "Éditer Nouvelles",
    "View News": "Vue Nouvelles",
    "Are you sure that you want to return this item?": "Êtes-vous sûr de vouloir retourner cet article?",
    "Approve order": "Approuver la commande",
    "I agree with the Terms and Conditions": "J'accepte les termes et conditions",
    "I agree with the": "J'accepte les",
    "Terms and Conditions":"termes et conditions",
    "No partial deliveries": "Pas de livraisons partielles",
    "Return #": 'Return #',
    "ReturnDate": 'ReturnDate',
    "Show All Users": 'Afficher tous les utilisateurs',
    "Reference": 'Référence',
    "Add return": 'Nouvelle demande de retour',
    "Return": 'Revenir',
    "Are you sure?": 'Êtes-vous sûr?',
    "New Item": 'Nouvel Article',
    "Name": "Nom",
    "Reference_Header":"En-tête de référence",
    "Reference_Line":"Texte de référence",
    "Invoicenumber": "Document",
    "Invoicedate": "Date",
    "Item": "Article",
    "Open_invoices": "Factures/avoirs ouvertes",
    "Document": "Document",
    "OpenAmount": "Montant ouvert",
    "Days_Overdue": "Nombre de jours impayés",
    "Outstanding_Invoices": "Factures impayées",
    "Help_Text": "Texte d'aide",
    "add_help_text": "Ajouter un texte d'aide",
    "page": "Page",
    "Title": "Titre",
    "Subtitle":"Sous-titre",
    "Text":"Texte",
    "contentDutch": "contentDutch",
    "contentEnglish": "contentEnglish",
    "contentGerman": "contentGerman",
    "contentFrench": "contentFrench",
    "Edit_help_text": "Modifier le texte d'aide",
    "Documents": "Des documents",
    "DocumentUrl": "DocumentUrl",
    "Add_Document": "Ajouter un document",
    "Edit_Document": "Modifier un document",
    "DocumentType": "DocumentType",
    "Add_DocumentType": "Ajouter un DocumentType",
    "documentLanguage": "documentLanguage",
    "Edit_DocumentType": "Modifier DocumentType",
    "Confirm_Delete_Message": "Êtes-vous sûr de vouloir le supprimer?",
    "Document_File": "Fichier de document",
    "Client, mais pas encore de compte ?": "Pas encore de compte?",
    "Register_Here": "Demandez votre compte ici",
    "Registration": "Créer un compte",
    "Approve_Users": "Approuver les utilisateurs",
    "Approve": "Approuver",
    "Commission":"Commission",
    "PickUpDate": "Date de Reprise",
    "Comments": "Remarques",
    "Return_Item": "Retourner l'objet",
    "Settings": "Réglages",
    "Notifications": "Notifications",
    "Terms_Of_Delivery": "Conditions de livraison",
    "Add" : "Ajouter",
    "Add_Notifications_Email": "Ajouter un e-mail de notification",
    "Update_Terms_Of_Delivery": "Mettre à jour les conditions de livraison",
    "Delivery Date" : "Date de livraison",
    "Shipping_type": "Type d'expédition",
    "ErrorLogs": "ErrorLogs",
    "id": "id",
    "logLevel": "logLevel",
    "logEvent": "logEvent",
    "dateTime": "dateTime",
    "invokedByUserId": "invokedByUserId",
    "referenceId": "Pièce d'identité",
    "Message": "Message",
    "Country":"Pays",
    "News History": "histoire de l'actualité",
    "Registration_sucess_message": "Votre compte sera activé dès que l'administrateur l'approuvera.",
    "CompanyName": "Nom de la compagnie",
    "Address": "Adresse",
    "Back_to_Log_in": "Retourner vers ‘se connecter’",
    "ExcludeProductgroups" : "ExcludeProductgroups",
    "ExcludeProductgroups_success": "ExcludeProductgroups added successfully",
    "CustomerCodes": "CustomerCodes",
    "Add_New": "Ajouter à la commande",
    "Number_of_packages": "Nombre de colis",
    "Partial": "Partial",
    "Maintenance": "Entretien",
    "Maintenance_Mode": "Mode de Maintenance",
    "Maintenance_Text": "Texte de maintenance",
    "Direct":"Direct",
    "CustomerGroupCode": "ClientGrouperCode",
    "Action (download PDF)": "Action (Télécharger PDF)",
    "Status delivery": "Statute Livraison",
    "Action (T&T and PDF)": "Action (T&T and PDF)",
    "Reference Header" : "En-tête de référence",
    "Reference Line" : "Ligne de référence",
    "Order Type" : "Type de commande",
    "Submit Order": "Soumettre la commande",
    "Success": "Réussi!",
    "Submit": "Confirmer",
    "Order total": "Montant total de la commande",
    "Order confirmation": "Confirmation de commande",
    "Sent": "Envoyé",
    "Return number": "Numéro de retour",
    "Return order": "Retour",
    "Current information": "Information actuelle",
    "Document name": "Nom document",
    "Request a return": "Demander un retour",
    "Submit return": "Confirmer retour",
    "Partial_deliveries":"Livraison partielle",
    "Expenses":"Frais",
    "login": "Se connecter",
    "CReference": 'Référence client',
    "ChangePassword": 'Changer mot de passe',
    "CurrentPassord": 'Mot de passe actuel',
    "StockInfo": "Demande de stock",
    "Calculate": "Commencer",
    "Check": "Vérifier",
    "Stock Expected delivery week": "Semaine de livraison prévue",
    "Stork Direct": "Tout de suite",
    "Incourant": "L'article a été épuisé et est en rupture de stock",
    "Standard forwarder": "Standard forwarder",
    "One moment please": "Une demande de stock prend environ 10-15 secondes.",
    "Available": "Disponible",
    "Customer_yet": "Pas encore client, mais aimeriez-vous le devenir ?",
    "Contact_us_here": "Contactez-nous ici à",
    "Pdf": "PDF",
    "Open": "Ouvert",
    "Close": "Fermé",
    "Item has been discontinued and is not sufficiently available": "L'article a été épuisé et est en rupture de stock",
    "Please check quantity": "Veuillez vérifier la quantité",
    "Is this number correct?": "Ce nombre est-il correct?"
  }
};
