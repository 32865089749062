/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
import React, { Component } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { connect } from "react-redux";
import { trls } from "../../factories/translate";
import API from "../../factories/api";
import SessionManager from "../../factories/session_manage";
import Axios from "axios";
import $ from "jquery";
import history from "../../history";

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({});

class AddDocumentForm extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.myInput = React.createRef();
    this.state = {
      documentType: [],
      documentTypeId: 0,
      documentFile: "",
      documentUrl: "",
      customerCode: [],
      customerCodes: [],
      documentName: "",
      customerGroup: [],
      openInBrowser: false,
    };
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {}

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this._isMounted = true;
    var headers = SessionManager.shared().getAuthorizationHeader();
    let params = {
      name: this.state.documentName ? this.state.documentName : "",
      nameDutch: this.state.documentName ? this.state.documentName : "",
      nameFrench: this.state.documentName ? this.state.documentName : "",
      nameGerman: this.state.documentName ? this.state.documentName : "",
      // "documentTypeId": this.state.documentTypeId ? this.state.documentTypeId.value :'',
      documentUrl: "",
      customerCodes: this.state.customerCodes ? this.state.customerCodes : [],
      customerGroupCodes: this.state.customerGroup
        ? this.state.customerGroup
        : [],
    };
    Axios.post(API.PostDocuments, params, headers)
      .then((result) => {
        if (this._isMounted) {
          if (this.state.documentFile !== "") {
            var formData = new FormData();
            console.log("formData", this.state.documentFile);
            formData.append("file", this.state.documentFile);
            let params = formData;
            Axios.post(
              API.PostDocumentsfileBydocumentTypeId +
                "?documentName=" +
                this.state.documentName +
                "&openInBrowser=" +
                this.state.openInBrowser,
              params,
              headers
            )
              .then((result) => {
                this.setState({ documentUrl: result.data.documentUrl });
                // if(this.state.customerCodes.length != 0){
                //     var id =result.data.id
                //     let PutParams ={
                //         "id":id,
                //         "name": this.state.documentName,
                //         // "documentTypeId": this.state.documentTypeId.value,
                //         "documentUrl": '',
                //         "customerCodes": this.state.customerCodes,
                //         "customerGroupCode": this.state.customerGroup[0] ? this.state.customerGroup[0].value : ''
                //     }

                //     Axios.put(API.UpdateDocuments+id, PutParams, headers)
                //     .then(result => {
                //     })
                // }
                this.props.onHide();
                this.props.onGetUser();
                this.removeState();
              })
              .catch((err) => {
                if (err.response.status === 401) {
                  history.push("/login");
                }
                if (err.response.status === 400) {
                  $(".add-error").removeClass("d-none");
                  $(".add-error").text(err.response.data);
                }
              });
          } else {
            this.props.onHide();
            this.props.onGetUser();
            this.removeState();
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          history.push("/login");
        }
        if (err.response.status === 400) {
          $(".add-error").removeClass("d-none");
          $(".add-error").text(err.response.data);
        }
      });
  };

  removeState = () => {
    this.setState({
      documentTypeId: "",
      documentUrl: "",
      documentFile: "",
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    $(".add-error").addClass("d-none");
    this.setState({
      documentFile: e.target.files[0],
    });
  };

  hideModel = () => {
    this.props.onHide();
    this.removeState();
  };

  onShow = () => {
    var tempDocumentTypeData = [];
    var tempCustomerCodesData = [];

    this.props.DocumentTypeData.forEach((element) => {
      tempDocumentTypeData.push({ value: element.id, label: element.name });
    });
    this.props.CustomerCodesData.forEach((element) => {
      tempCustomerCodesData.push({
        value: element.CardCode,
        label: element.CardCode,
      });
    });
    this.setState({
      documentType: tempDocumentTypeData,
      customerCode: tempCustomerCodesData,
      customerCodes: [],
    });
  };

  customerCodeChange = (val) => {
    let tempCustomerCodes = [];
    val.forEach((element) => {
      tempCustomerCodes.push(element.value);
    });
    this.setState({ customerCodes: tempCustomerCodes });
  };

  customerGroupCodeChange = (val) => {
    let tempCustomerCodes = [];
    val.forEach((element) => {
      tempCustomerCodes.push(String(element.value));
    });
    this.setState({ customerGroup: tempCustomerCodes });
  };

  render() {
    const { customerGroupList } = this.props;
    return (
      <Modal
        show={this.props.show}
        onHide={this.hideModel}
        onShow={this.onShow}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {trls("Add_Document")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="alert alert-danger add-error d-none"
            role="alert"
          ></div>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group as={Row} controlId="formPlaintextPassword">
              <Col className="product-text">
                <Form.Control
                  type="text"
                  name="name"
                  onChange={(e) =>
                    this.setState({ documentName: e.target.value })
                  }
                  required
                  placeholder={trls("Name")}
                />
                <label className="placeholder-label">{trls("Name")}</label>
              </Col>
            </Form.Group>
            {/* <Form.Group as={Row} controlId="documentType">
                        <Col>
                            <Select
                                name="documentType"
                                // placeholder= 'select page'
                                options={this.state.documentType}
                                onChange={val => this.setState({documentTypeId: val})}
                                defaultValue = ''
                            />
                            <label className="placeholder-label">{trls('documentType')}</label>
                        </Col>
                    </Form.Group> */}
            <Form.Group as={Row} controlId="documentFile">
              <Col className="product-text d-flex align-items-center">
                <Form.Check
                  type="checkbox"
                  label="Open"
                  onChange={(evt) =>
                    this.setState({ openInBrowser: evt.target.checked })
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="documentFile">
              <Col className="product-text">
                <div className="form-control">
                  <Form.Control
                    onChange={this.handleChange}
                    type="file"
                    name="documentFile"
                  />
                  <label className="placeholder-label">
                    {trls("Document_File")}
                  </label>
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="documentType">
              <Col>
                <Select
                  options={this.state.customerCode}
                  isMulti
                  onChange={(val) => this.customerCodeChange(val)}
                  defaultValue=""
                />
                <label className="placeholder-label">
                  {trls("CustomerCode")}
                </label>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="customerGroup">
              <Col>
                <Select
                  options={customerGroupList}
                  isMulti
                  onChange={(val) => this.customerGroupCodeChange(val)}
                  defaultValue=""
                />
                <label className="placeholder-label">
                  {trls("CustomerGroupCode")}
                </label>
              </Col>
            </Form.Group>
            <Form.Group style={{ textAlign: "center" }}>
              <Button type="Submit">{trls("Save")}</Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddDocumentForm);
