import React, {Component} from 'react'
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import SessionManager from '../factories/session_manage';
import API from '../factories/api'
// import * as Queries  from '../../factories/queries'
import Axios from 'axios';
import Pageloadspiiner from '../components/page_load_spinner';
import Sidebar from '../components/sidebar';
import Header from '../components/header';
import Footer from '../components/footer';
import Dashboard from '../pages/Dashboard/dashboard_manage';
import User from '../pages/User/user_register';
import Order from '../pages/Order/order_manage';
import Orderdetail from '../pages/Order/order_detail';
import Deliveries from '../pages/Deliveries/deliveries_manage';
import Deliverydetail from '../pages/Deliveries/delivery_detail';
import Salesinvoices from '../pages/Salesinvoices/salesinvoices_manage';
import Salesinvoicedetail from '../pages/Salesinvoices/salesinvoice_deail';
import Returns from '../pages/Returns/returns_manage';
import Returndetail from '../pages/Returns/return_detail';
import Placemanage from '../pages/Placeorder/place_manage';
import Placesamplemanage from '../pages/Placesampleorder/place_manage';
import Newsmanage from '../pages/News/news_manage';
import NewsHistory from '../pages/News/news_history';
import { connect } from 'react-redux';
import { Switch,Router, Route } from 'react-router-dom';
import history from '../history';
import * as Auth from '../factories/auth';
import OutstandingInvoices from '../pages/OutstandingInvoices/outstanding_invoice'
import HelpText from '../pages/HelpText/helptext_manage';
import Documents from '../pages/Documents/documents_manage';
import DocumentType from '../pages/DocumentType/documentType_manage';
import RegistrationRequest from '../pages/RegistrationRequest/Registration_Request_manage';
import AddReturns from '../pages/Returns/add_returns';
import Settings from '../pages/Settings/settings_manage';
import ErrorLogs from '../pages/ErrorLog/error_logs';
import CustomerDocuments from '../pages/Documents/customer_document';
import Maintenance from '../pages/Maintenance/maintenance_manage';
import ShippingAddressModal from '../components/shippingaddress_modal'
import StockinfoManage from '../pages/StockInfo/stockinfo_manage';


window.localStorage.setItem('AWT', true);

const mapStateToProps = state => ({ 
  ...state.auth,
});

class Layout extends Component {
  constructor(props){
    super(props);
    this.state = {
        userInfo: Auth.getUserInfo(),
        addressShow: false,
        loading: true,
        shippingAddressList: [],
        shippingAddressType: 1,
    }
  }

  componentDidMount () {
    // var userRole = localStorage.getItem('eijf_role');
    // var shippingAddress = localStorage.getItem('eijf_shippingAddress');
    // if(!shippingAddress && userRole==="Customer") {
      var U_DBS_EXPORTKLANT = localStorage.getItem('U_DBS_EXPORTKLANT');
      if(U_DBS_EXPORTKLANT==="Y"){
        this.getShippingAddress();
      }
      
    // }
  }

  getShippingAddress = () => {
    var headers = SessionManager.shared().getAuthorizationHeader();
    Axios.get(API.GetNrSapShippingAddresses, headers)
    .then(result => {
        if(result.data){
          if(result.data[0].nrSapShippingAddresses>1){
            Axios.get(API.GetSPSapShippingAddresses, headers)
            .then(result => {
              this.setState({addressShow: true, loading: false, shippingAddressList: result.data, shippingAddressType: 1});
            })
          } else {
            this.setState({shippingAddressType: 0});
          }
        }
    })
    .catch(err => {
      if(err.response.status===401){
          history.push('/login')
      }
    });
  }

  componentDidUpdate () {
    var U_DBS_EXPORTKLANT = localStorage.getItem('U_DBS_EXPORTKLANT');
    if(localStorage.getItem('eijf_loginAs')==="1") {
      this.setState({addressShow: false, loading: true}, ()=>{
        if(U_DBS_EXPORTKLANT==="Y"){
          this.getShippingAddress();
        }
        localStorage.setItem('eijf_loginAs', 0)
      })
    }
  }

  render () {
    const { userInfo, addressShow, loading, shippingAddressList, shippingAddressType } = this.state;
    var U_DBS_EXPORTKLANT = localStorage.getItem('U_DBS_EXPORTKLANT');
    var userRole = localStorage.getItem('eijf_role');
    userInfo.role = userRole;
    var shippingAddress = localStorage.getItem('eijf_shippingAddress');
    if( U_DBS_EXPORTKLANT!=="Y" || shippingAddress || (!shippingAddress && !shippingAddressType) || userRole!=="Customer") {
      return (
        <Row style={{height:"100%", display: "flex"}}>
        <Sidebar/>
          <Col style={{paddingLeft:0, paddingRight:0, width: "75%"}}>
            <Header/>
            <div id = "alert_placeholder" className="px-4"></div>
            <Router history={history}>
              <Switch>
                <Route path="/dashboard" component={Dashboard}/>
                {(userInfo.role==="Administrator" || userInfo.role==="Sales") && (
                  <Route path="/user" component={User}/>
                )}
                {userInfo.role!=="Administrator" && (
                  <Route path="/orders" component={Order}/>
                )}
                {userInfo.role!=="Administrator" && (
                  <Route path="/order-detail" component={Orderdetail}/>
                )}
                {userInfo.role!=="Administrator" && (
                  <Route path="/deliveries" component={Deliveries}/>
                )}
                {userInfo.role!=="Administrator" && (
                  <Route path="/delivery-detail" component={Deliverydetail}/>
                )}
                {userInfo.role!=="Administrator" && (
                  <Route path="/salesinvoices" component={Salesinvoices}/>
                )}
                {userInfo.role!=="Administrator" && (
                  <Route path="/salesinvoice-deail" component={Salesinvoicedetail}/>
                )}
                {userInfo.role!=="Administrator" && (
                  <Route path="/returns" component={Returns}/> 
                )}
                {userInfo.role!=="Administrator" && (
                  <Route path="/return-detail" component={Returndetail}/> 
                )}
                {userInfo.role!=="Administrator" && (
                  <Route path="/placeorder" component={Placemanage}/>
                )} 
                {userInfo.role!=="Administrator" && (
                  <Route path="/placesampleorder" component={Placesamplemanage}/>
                )} 
                {userInfo.role!=="Administrator" && (
                  <Route path="/customerDocuments" component={CustomerDocuments}/>
                )}
                {userInfo.role!=="Administrator" && (
                  <Route path="/outstandingInvoices" component={OutstandingInvoices}/>
                )} 
                {userInfo.role!=="Administrator" && (
                  <Route path="/stockinfo" component={StockinfoManage}/>
                )} 
                {userInfo.role==="Administrator" && (
                  <Route path="/help-text" component={HelpText}/>
                )}
                {userInfo.role==="Administrator" && (
                  <Route path="/documents" component={Documents}/>
                )}
                {userInfo.role==="Administrator" && (
                  <Route path="/document-type" component={DocumentType}/>
                )}
                {userInfo.role==="Administrator" && (
                  <Route path="/registration-request" component={RegistrationRequest}/>
                )}
                {userInfo.role!=="Administrator" && (
                  <Route path="/add-returns" component={AddReturns}/>
                )} 
                {userInfo.role==="Administrator" && (
                  <Route path="/settings" component={Settings}/>
                )}          
                {userInfo.role==="Administrator" && (
                  <Route path="/error-logs" component={ErrorLogs}/>
                )} 
                {(userInfo.role!=="Administrator" && Auth.getUserInfo().MaintenanceMode === "true") && (
                  <Route path="/Maintenance" component={Maintenance}/>
                )}
                {userInfo.role==="Administrator" && (
                  <Route path="/newshistory" component={NewsHistory}/>
                )}  
                {userInfo.role==="Administrator" && (
                  <Switch>
                      <Route path="/news" component={Newsmanage}/>
                  </Switch>
                )}
              </Switch>
            </Router>
            <Footer/>
          </Col>
          <div className="fade-display"></div>
      </Row>
    )
  } else {
    return (
      <div>
        <ShippingAddressModal
              show={addressShow}
              onHide={() => this.setState({addressShow: false})}      
              shippingAddressList={shippingAddressList}      
            />
        <Pageloadspiiner loading = { !shippingAddress ? loading : false} text={''}/>
      </div>
    )
  }
  };
}
export default connect(mapStateToProps)(Layout);
