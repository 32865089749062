/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, {Component} from 'react'
import { trls } from '../../factories/translate';
import { Container } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { Form, Row, Col, Button} from 'react-bootstrap';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import * as Common from '../../factories/common';
import * as Auth from '../../factories/auth';
import currentWeekNumber from 'current-week-number';
import Pageloadspiiner from '../../components/page_load_spinner';
import Select from 'react-select';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
});
class Productpriceform extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            userInfo: Auth.getLoggedUserInfo(),
            approveActive: false,
            approveLoading: false
        };
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
    }


    approveOrder = () => {
        this.setState({approveLoading: true});
        this.props.approveOrder();
    }

    render(){
        const { userInfo, approveActive } = this.state;
        const { orderDetailData, orderExpenses, orderApproveFlag, ShippingMethods, totalAmount, CountryName, changeEmail, countries, BillingCountry } = this.props;
        let approveLoading = this.state.approveLoading;
        let totalAmountMon = 0;
        if(orderExpenses.expense){
            totalAmountMon = totalAmount + orderExpenses.expense;
        } else {
            totalAmountMon = totalAmount;
        }

        let country = null;
        if(CountryName) {
            country = CountryName;
        } else if(orderDetailData.user_shippingAddress) {
            if(orderDetailData.user_shippingAddress.country) {
                if(!countries) {
                    country = orderDetailData.user_shippingAddress.country;
                } else {
                    const countriesFiltered = countries.filter((e) => e.countryCode === orderDetailData.user_shippingAddress.country);
                    if(countriesFiltered.length) {
                        
                        country = countries.filter((e) => e.countryCode === orderDetailData.user_shippingAddress.country)[0].countryInEnglish
                    }
                }
            }
        }

        if(orderApproveFlag){
            approveLoading = true;
        }
        let showPrice = localStorage.getItem('eijf_showPrice')==="true" ? true : false;

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                onShow={this.props.onShow}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {trls('OrderSummary')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="order_div">
                        <Container>
                            <Form className="container product-form pl-0">
                                <Row className="order__info-bill">
                                    <Col sm={6} style={{paddingLeft: 0, paddingTop: 10}}>
                                        <Form.Group as={Row} controlId="formPlaintextPassword">
                                            <Form.Label column sm="4" className="p-0 d-flex align-items-center form-label col-form-label">
                                                {trls("Customer_reference")}  
                                            </Form.Label>
                                            <Col sm="8" className="product-text d-flex align-items-center col-sm-8">
                                                <Form.Control type="text" name="reference" required readOnly defaultValue={orderDetailData ? orderDetailData.user_customerreference : ''} placeholder={trls('Customer_reference')} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextPassword">
                                            <Form.Label column sm="4" className="p-0 d-flex align-items-center form-label col-form-label">
                                                {trls("Business_partner")}  
                                            </Form.Label>
                                            <Col sm="8" className="product-text d-flex align-items-center col-sm-8">
                                                <Form.Control type="text" name="reference" required readOnly defaultValue={orderDetailData ? orderDetailData.businessPartnerOption : ''}  />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextPassword" className="mb-0">
                                            <Form.Label column sm="4" className="p-0 d-flex align-items-center form-label col-form-label">
                                                <span className="white-space-normal">{trls("E-Mail address order confirmation")}</span>
                                            </Form.Label>
                                            <Col sm="8" className="product-text d-flex align-items-center col-sm-8">
                                                <Form.Control type="text" name="email" required defaultValue={orderDetailData ? orderDetailData.email : ''} placeholder={trls('Email')} onChange={e=>changeEmail(e.target.value)} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextPassword">
                                            <Col sm={{span: 8, offset: 4}}>
                                                <span className="color-orange">{trls("Order confirmation email address?")}</span>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextPassword">
                                            <Form.Label column sm="4" className="p-0 d-flex align-items-center form-label col-form-label">
                                                    {trls("DocDate")}  
                                                </Form.Label>
                                                <Col sm="8" className="product-text d-flex align-items-center col-sm-8">
                                                    <Form.Control type="text" name="contact" readOnly defaultValue={Common.formatDate(new Date())} required  />
                                                </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6} className = "bill-shipping__address">
                                        <div className="place-order__address">
                                            <p className="address-header">{trls('Billing_Address')}</p>
                                            <p>{orderDetailData.user_billAddress ? orderDetailData.user_billAddress.Block : '' }</p>
                                            <p>{orderDetailData.user_billAddress ? orderDetailData.user_billAddress.Street : '' }</p>
                                            <p>{orderDetailData.user_billAddress ? orderDetailData.user_billAddress.ZipCode + " " + orderDetailData.user_billAddress.City : ''}</p>
                                            <p>{BillingCountry ? BillingCountry : (CountryName ? CountryName : (orderDetailData.user_billAddress ? orderDetailData.user_billAddress.Country : ''))}</p>
                                        </div>
                                        <div className="place-order__address">
                                            <p className="address-header">{trls('Shipping_Address')}</p>
                                            {orderDetailData.user_shippingAddress ? 
                                                <div>
                                                    <p>{orderDetailData.user_shippingAddress.name ? orderDetailData.user_shippingAddress.name:''}</p>
                                                    <p>{orderDetailData.user_shippingAddress.address ? orderDetailData.user_shippingAddress.address : '' }</p>
                                                    <p>{orderDetailData.user_shippingAddress.Street ? orderDetailData.user_shippingAddress.Street : '' }</p>
                                                    <p>{orderDetailData.user_shippingAddress.zipCode ? orderDetailData.user_shippingAddress.zipCode: '' } {orderDetailData.user_shippingAddress.city ? orderDetailData.user_shippingAddress.city : ''}</p>
                                                    <p>{country ? country : (CountryName ? CountryName : '')}</p>
                                                </div>
                                                :
                                                false
                                            }
                                        </div>
                                    </Col>
                                </Row>                   
                            </Form>
                            
                            <div className="table-responsive">
                                <table id="example" className="place-and-orders__table table table--striped prurprice-dataTable order_detail-table" width="100%">
                                <thead>
                                    <tr>
                                        <th>{trls("Product_code")}</th>
                                        <th>{trls("Product_description")}</th>
                                        {/* <th>{trls("Unit")}</th> */}
                                        <th>{trls("Quantity")}</th>
                                        {showPrice ? (
                                            <th>{trls("Price")}</th>
                                        ): null}
                                        {showPrice ? (
                                            <th>{trls("Amount")}</th>
                                        ): null}
                                        <th>{trls("Image")}</th>
                                        <th>{trls("Customer_reference")}</th>
                                        {/* <th>{trls("Expected_deliver_week")}</th> */}
                                        {/* <th>{trls("Action")}</th> */}
                                    </tr>
                                </thead>
                                {orderDetailData.documentRowArray &&(<tbody>
                                    {
                                        orderDetailData.documentRowArray.map((data,index) =>(
                                        
                                        <tr id={index} key={index}>
                                            <td style={{display: "flex"}}>
                                                <Form.Control id={"itemCode"+data.rowId} type="text" name="productcode" disabled autoComplete="off" required style={{width: '80%'}} placeholder={trls('Product_code')} defaultValue={data.ItemCode ? (data.ItemCode.charAt(0) === 'S'?data.ItemCode.substring(1):data.ItemCode) : ''}/>
                                                <i className="fas fa-search place-order__itemcode-icon"></i>
                                            </td>
                                            <td>
                                                <Form.Control type="text" name="description" readOnly required defaultValue = {data.ItemName ? data.ItemName : ''} placeholder={trls('Description')} />
                                            </td>
                                            <td>
                                                <Row style={{justifyContent: "space-around"}}>
                                                    <Form.Control type="text" name="quantity" style={{width: '80%'}} disabled required placeholder={trls('Quantity')} defaultValue = {data.order_quantity ? data.order_quantity : ''}/>
                                                </Row>
                                            </td>
                                            {
                                            showPrice ? (
                                                <td>
                                                    {data.Price ? Common.formatMoney(data.Price) : ''}
                                                </td> 
                                            ): null}
                                            {showPrice ? (
                                                <td>
                                                     {data.OpenAmount ? Common.formatMoney(data.OpenAmount) : ''}
                                                </td>
                                            ): null}
                                            <td>
                                                {/* {data.picture&&(
                                                    <img src={ data.Image ? "data:image/png;base64," + data.picture : ''} className = "image__zoom" alt={index}></img>
                                                ) 
                                                } */}
                                            </td>
                                            <td>
                                                <Form.Control type="text" name="customerReference" disabled required placeholder={trls('Customer_reference')} defaultValue={data.order_customerreference ? data.order_customerreference : ''} />
                                            </td>
                                            {/* <td>
                                                {data.order_deliveryWeek ?  (data.order_deliveryWeek == currentWeekNumber(new Date()) ? 'Available'  : data.order_deliveryWeek) : '' }
                                            </td> */}
                                        </tr>
                                    ))
                                    }
                                </tbody>)}
                            </table>
                        </div>
                        <Row>
                            <Col className="place-order_summary-check-div">
                                {/* <Form.Check type="checkbox" label={trls("No partial deliveries")} className="place-order_summary-check"/> */}
                                <Row>
                                    <Form.Check type="checkbox" label={trls("I agree with the")} className="place-order_summary-check" onChange={(evt)=>this.setState({approveActive: evt.target.checked})}/>
                                    <a href= {this.props.Terms_And_Conditions} target='_blank'>&nbsp;{trls("Terms and Conditions")}</a>
                                </Row>
                            </Col>
                            <Col sm={4} style={{float: 'right', paddingLeft: 0, paddingRight: 0}}>
                                {/* {showPrice ? (
                                <div className="info-block info-block--green">
                                    <span className="txt-bold">Expenses</span>
                                    <span>{orderExpenses.expense ? Common.formatMoney(orderExpenses.expense) : ''}</span>
                                </div>
                                ) : null} */}
                                {showPrice ? (
                                <div className="info-block info-block--green">
                                    <span className="txt-bold">{trls("Order total")}</span>
                                    <span>{Common.formatMoney(totalAmountMon)}</span>
                                </div>
                                ) : null}
                                
                                <Button type="button" className="place-submit__order summary-submit" disabled={!approveActive || orderApproveFlag? true : false} onClick={()=>this.approveOrder()}>{trls("Approve order")}</Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Modal.Body>
            <Pageloadspiiner loading = {approveLoading} text={''}/>
        </Modal>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Productpriceform);