/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import { trls } from "../../factories/translate";
import { Row, Col, Button, Form } from "react-bootstrap";
import SessionManager from "../../factories/session_manage";
import API from "../../factories/api";
import Axios from "axios";
import $ from "jquery";
import { BallBeat } from "react-pure-loaders";
import "react-datepicker/dist/react-datepicker.css";
import history from "../../history";
import * as Common from "../../factories/common";
import Pageloadspiiner from "../../components/page_load_spinner";
import Sweetalert from "sweetalert";
import * as authAction from "../../actions/authAction";
import Pagination from "../../components/pagination_default20";
import * as Auth from "../../factories/auth";
import Filtercomponent from "../../components/filtercomponent";
import HelpTextModal from "../Dashboard/HelpText";

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  blankdispatch: (blankFlag) => dispatch(authAction.blankdispatch(blankFlag)),
});

class Ordermanage extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      ordersData: [],
      originFilterData: [],
      // orderField: '',
      orderNumber: false,
      orderDate: false,
      itemCode: false,
      pageSize: 20,
      page: 0,
      filterColunm: [
        { label: "ItemCode", value: "Itemcode", type: "text", show: true },
        {
          label: "Collection",
          value: "Item description",
          type: "text",
          show: true,
        },
        { label: "Quantity", value: "Quantity", type: "text", show: true },
        {
          label: "Reference_Header",
          value: "Reference header",
          type: "text",
          show: true,
        },
        {
          label: "Commission",
          value: "Reference line",
          type: "text",
          show: true,
        },
        // {"label": 'Delivery Date', "value": "Delivery date", "type": 'date', "show": true},
        { label: "Currency", value: "Currency", type: "text", show: true },
        { label: "Price", value: "Price", type: "text", show: true },
        {
          label: "OrderNumber",
          value: "Ordernumber",
          type: "text",
          show: true,
        },
        { label: "Order_Date", value: "Orderdate", type: "date", show: true },
        { label: "Available", value: "Available", type: "text", show: true },
        { label: "Batch", value: "Batch", type: "text", show: true },
        { label: "Ordertype", value: "Ordertype", type: "text", show: true },
        {
          label: "Order confirmation",
          value: "Order confirmation",
          type: "text",
          show: true,
        },
      ],
      originFilterData: [],
      pageLodingFlag: false,
      obj: this,
      recordNum: 0,
      searchText: "",
      filterDataFlag: false,
      HelpTextModal: false,
      filterFlag: false,
      searchText: "",
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    let obj = this;
    $("#search").on("keyup", function (event) {
      if (event.keyCode === 13 || event.key === "Enter") {
        obj.setState({ searchText: this.value }, () => {
          obj.getOrdersData(20, 1, this.value);
          obj.setState({ searchText: this.value });
        });
      }
    });
    this.getOrdersData(20, 1, "");
  }

  getOrdersData = (pageSize, pageNumber, searchText) => {
    this.setState({ loading: true, recordNum: 0 });
    var headers = SessionManager.shared().getAuthorizationHeader();
    var params = {
      skip: pageSize * (pageNumber - 1),
      quickSearch: searchText,
      shipToCode: localStorage.getItem("eijf_shippingAddress"),
    };
    Axios.post(API.GetOrderLines, params, headers)
      .then((result) => {
        let orderData = result.data.value;
        if (!orderData.length) {
          this.setState({ ordersData: [], loading: false, recordNum: 0 });
        }
        orderData.sort(function (a, b) {
          return new Date(b.Orderdatum) - new Date(a.Orderdatum);
        });
        orderData.sort(function (a, b) {
          return Number(b.Ordernumber) - Number(a.Ordernumber);
        });
        this.setState({
          ordersData: orderData,
          loading: false,
          recordNum: result.data["@odata.count"],
        });
        // $("#order-table").dataTable().fnDestroy();
        // var table = $("#order-table").DataTable({
        //   language: {
        //     lengthMenu: trls("Show") + " _MENU_ " + trls("Result_on_page"),
        //     zeroRecords: "Nothing found - sorry",
        //     info:
        //       trls("Show_page") + " _PAGE_ " + trls("Results_of") + " _PAGES_",
        //     infoEmpty: "No records available",
        //     infoFiltered: "(filtered from _MAX_ total records)",
        //     search: trls("Search"),
        //     paginate: {
        //       previous: trls("Previous"),
        //       next: trls("Next"),
        //     },
        //   },
        //   bSort: false,
        //   dom: 't<"bottom-datatable" lip>',
        //   bInfo: false,
        //   bPaginate: false,
        // });
      })
      .catch((err) => {
        // if(err.response.status===401){
        //     history.push('/login')
        // }
      });
  };
  //filter option
  // filterOptionData = (filterOption) =>{
  //     const { originFilterData } = this.state;
  //     this.setState({loading: true})
  //     let dataA = []
  //     dataA = Common.filterData(filterOption, originFilterData);
  //     if(!filterOption.length){
  //         dataA=null;
  //     }
  //     setTimeout(() => {
  //         if(!filterOption.length){
  //             dataA=null;
  //             this.setState({ordersData: originFilterData, loading: false});
  //         } else {
  //             this.setState({ordersData: dataA, loading: false});
  //         }
  //         $('.filter').on( 'keyup', function () {
  //             table.search( this.value ).draw();
  //         } );
  //         $('#order-table').dataTable().fnDestroy();
  //         var table = $('#order-table').DataTable(
  //             {
  //                 "language": {
  //                     "lengthMenu": trls("Show")+" _MENU_ "+trls("Result_on_page"),
  //                     "zeroRecords": "Nothing found - sorry",
  //                     "info": trls("Show_page")+" _PAGE_ "+trls('Results_of')+" _PAGES_",
  //                     "infoEmpty": "No records available",
  //                     "infoFiltered": "(filtered from _MAX_ total records)",
  //                     "search": trls('Search'),
  //                     "paginate": {
  //                         "previous": trls('Previous'),
  //                         "next": trls('Next')
  //                     }
  //                 },
  //                     "bSort": false,
  //                     "dom": 't<"bottom-datatable" lip>'
  //             }
  //         );
  //     });
  // }

  // changeFilter = () => {
  //     if(this.state.filterFlag){
  //         this.setState({filterFlag: false})
  //     }else{
  //         this.setState({filterFlag: true})
  //     }
  // }
  //filter option

  getHelpText = () => {
    var pageName = "Orders";
    var headers = SessionManager.shared().getAuthorizationHeader();
    var lang = localStorage.getItem("eijf_lang");
    Axios.get(API.GetHelpTextByName + pageName, headers)
      .then((result) => {
        let helpURL = "";
        if (lang === "Dutch") {
          helpURL = result.data.contentDutch;
        } else if (lang === "English") {
          helpURL = result.data.contentEnglish;
        } else if (lang === "French") {
          helpURL = result.data.contentFrench;
        } else {
          helpURL = result.data.contentGerman;
        }
        const a = document.createElement("a");
        a.setAttribute("href", helpURL);
        a.setAttribute("target", "_blank");
        a.click();
      })
      .catch((err) => {
        // if(err.response.status===401){
        //     history.push('/login')
        // }
      });
  };

  showOrderDetail = (orderId) => {
    history.push({
      pathname: "/order-detail/" + orderId,
      state: { id: orderId, newSubmit: true },
    });
  };

  removeColumn = (value) => {
    let filterColunm = this.state.filterColunm;
    filterColunm = filterColunm.filter(function (item, key) {
      if (item.label === value) {
        item.show = false;
      }
      return item;
    });
    this.setState({ filterColunm: filterColunm });
  };

  showColumn = (value) => {
    let filterColum = this.state.filterColunm;
    filterColum = filterColum.filter((item, key) => item.label === value);
    return filterColum[0].show;
  };

  getFileDownLoad = (data) => {
    this.setState({ pageLodingFlag: true });
    this._isMounted = true;
    let params = {
      objectId: data.PDFObjectId,
      keyValue: data.PDFKeyValue,
    };
    console.log("params", data);
    var headers = SessionManager.shared().getAuthorizationHeader();
    Axios.post(API.GetDownloadFile, params, headers)
      .then((result) => {
        if (result.data.pdf) {
          this.setState({ pageLodingFlag: false });
          this.downloadWithName(
            "data:application/octet-stream;charset=utf-16le;base64," +
              result.data.pdf,
            data.Itemcode + ".pdf"
          );
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          history.push("/login");
        }
      });
  };

  downloadWithName = (uri, name) => {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.click();
  };

  openPlaceOrder = () => {
    history.push("/placeorder");
    this.props.blankdispatch(this.props.blankFlag);
  };

  showPlaceOrder = (orderId) => {
    history.push("/order-detail/" + orderId);
  };

  returnOrderConform = (data) => {
    Sweetalert({
      title: "Are you sure?",
      text: trls("Are you sure that you want to return this item?"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.returnOrder(data);
      } else {
      }
    });
  };

  returnOrder = (data) => {
    this._isMounted = true;
    let documentLineArray = [];
    let lineArray = [];

    lineArray = {
      ItemCode: data.ItemCode,
      Quantity: data.Quantity,
      Price: data.Price,
    };
    documentLineArray.push(lineArray);
    let params = {
      requestData: {
        CardCode: data.CardCode,
        DocDate: Common.formatDate1(data.DocDate),
        DocDueDate: Common.formatDate1(data.DocDueDate),
        Reference1: "",
        BillingAddress: {
          ShipToStreet: null,
          ShipToStreetNo: null,
          ShipToBlock: null,
          ShipToBuilding: "",
          ShipToCity: null,
          ShipToZipCode: null,
          ShipToCounty: null,
          ShipToState: null,
          ShipToCountry: null,
          ShipToAddressType: null,
          BillToStreet: null,
          BillToStreetNo: null,
          BillToBlock: null,
          BillToBuilding: "",
          BillToCity: null,
          BillToZipCode: null,
          BillToCounty: null,
          BillToState: null,
          BillToCountry: "NL",
          BillToAddressType: null,
          ShipToGlobalLocationNumber: null,
          BillToGlobalLocationNumber: null,
          ShipToAddress2: null,
          ShipToAddress3: null,
          BillToAddress2: null,
          BillToAddress3: null,
          PlaceOfSupply: null,
          PurchasePlaceOfSupply: null,
        },
        DocumentLines: documentLineArray,
      },
      parameters: {},
    };
    var headers = SessionManager.shared().getAuthorizationHeader();
    Axios.post(API.ReturnOrder, params, headers)
      .then((result) => {
        Sweetalert("Success!", {
          icon: "success",
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          history.push("/login");
        }
      });
  };

  render() {
    const { filterColunm, ordersData, pageLodingFlag, searchText } = this.state;
    let filterData = [
      { label: trls("ItemCode"), value: "Itemcode", type: "text" },
      { label: trls("Collection"), value: "Item description", type: "text" },
      { label: trls("Quantity"), value: "Quantity", type: "text" },
      {
        label: trls("Reference_Header"),
        value: "Reference header",
        type: "text",
      },
      { label: trls("Commission"), value: "Reference line", type: "text" },
      // {"label": trls('Delivery Date'), "value": "Delivery date", "type": 'date'},
      { label: trls("Currency"), value: "Currency", type: "text" },
      { label: trls("Price"), value: "Price", type: "text" },
      { label: trls("OrderNumber"), value: "OrderNumber", type: "text" },
      { label: trls("Order_Date"), value: "Orderdate", type: "date" },
      {
        label: trls("Available"),
        value: "Available",
        type: "text",
        show: true,
      },
      { label: trls("Batch"), value: "Batch", type: "text" },
      { label: trls("Ordertype"), value: "Ordertype", type: "text" },
    ];
    return (
      <div className="order_div">
        <div className="content__header content__header--with-line">
          <div id="google_translate_element"></div>
          <h2 className="title">{trls("Orders")}</h2>
        </div>

        <div className="orders">
          <Row>
            <Col sm={6}>
              <Button variant="primary" onClick={() => this.openPlaceOrder()}>
                <i className="fas fa-plus add-icon"></i>
                {trls("Add_order")}
              </Button>
            </Col>
            <Col sm={6} className="has-search">
              <div style={{ display: "flex", float: "right" }}>
                <Button
                  variant="primary"
                  className="float-right"
                  onClick={this.getHelpText}
                >
                  <i className="fa fa-info-circle" aria-hidden="true"></i>
                </Button>
                {/* <Button style={{marginLeft: 20}} variant="light" onClick={()=>this.changeFilter()}><i className="fas fa-filter add-icon"></i>{trls('Filter')}</Button> */}
                <div style={{ marginLeft: 20 }}>
                  <span className="fa fa-search form-control-feedback"></span>
                  <Form.Control
                    id="search"
                    className="form-control filter"
                    type="text"
                    name="number"
                    placeholder={trls("Quick_search")}
                  />
                </div>
              </div>
            </Col>
            {/* {filterColunm.length&&(
                            <Filtercomponent
                                onHide={()=>this.setState({filterFlag: false})}
                                filterData={filterData}
                                onFilterData={(filterOption)=>this.filterOptionData(filterOption)}
                                showFlag={this.state.filterFlag}
                            />
                        )} */}
          </Row>
          <div className="table-responsive credit-history">
            {ordersData && (
              <table
                id="order-table"
                className="place-and-orders__table table"
                width="100%"
              >
                <thead>
                  <tr>
                    {filterColunm.map((item, key) => (
                      <th
                        className={!item.show ? "filter-show__hide" : ""}
                        key={key}
                        style={{ textAlign: "left !important" }}
                      >
                        {trls(item.label) ? trls(item.label) : ""}
                        {/* <Contextmenu
                                                triggerTitle = {item.label}
                                                addFilterColumn = {(value)=>this.addFilterColumn(value)}
                                                removeColumn = {(value)=>this.removeColumn(value)}
                                            /> */}
                      </th>
                    ))}
                  </tr>
                </thead>
                {ordersData && !this.state.loading && (
                  <tbody>
                    {ordersData.map((data, i) => (
                      <tr id={i} key={i}>
                        {/* <td className={!this.showColumn(filterColunm[0].label) ? "filter-show__hide" : ''} ><span id={data.id} className="action-div" onClick={()=>this.showPlaceOrder(data.Ordernumber)}>{data['Itemcode']}</span></td> */}
                        <td
                          className={
                            !this.showColumn(filterColunm[0].label)
                              ? "filter-show__hide"
                              : ""
                          }
                        >
                          {data["Artikel"]}
                        </td>
                        <td
                          className={
                            !this.showColumn(filterColunm[1].label)
                              ? "filter-show__hide"
                              : ""
                          }
                        >
                          <div>{data["Collectie"]}</div>
                        </td>
                        <td
                          className={
                            !this.showColumn(filterColunm[2].label)
                              ? "filter-show__hide"
                              : ""
                          }
                        >
                          {data["Aantal"]}
                        </td>
                        <td
                          className={
                            !this.showColumn(filterColunm[3].label)
                              ? "filter-show__hide"
                              : ""
                          }
                        >
                          {data["Referentie"]}
                        </td>
                        <td
                          className={
                            !this.showColumn(filterColunm[4].label)
                              ? "filter-show__hide"
                              : ""
                          }
                        >
                          {data["Commissie"]}
                        </td>
                        {/* <td className={!this.showColumn(filterColunm[5].label) ? "filter-show__hide" : ''} style={{width: "100px"}}>{data['Delivery date']}</td> */}
                        <td
                          className={
                            !this.showColumn(filterColunm[5].label)
                              ? "filter-show__hide"
                              : ""
                          }
                          style={{ minWidth: "80px" }}
                        >
                          {data["Valuta"]}
                        </td>
                        <td
                          className={
                            !this.showColumn(filterColunm[6].label)
                              ? "filter-show__hide"
                              : ""
                          }
                          style={{ minWidth: "80px" }}
                        >
                          {data["Prijs"]}
                        </td>
                        <td
                          className={
                            !this.showColumn(filterColunm[7].label)
                              ? "filter-show__hide"
                              : ""
                          }
                        >
                          {data["Ordernummer"]}
                        </td>
                        <td
                          className={
                            !this.showColumn(filterColunm[8].label)
                              ? "filter-show__hide"
                              : ""
                          }
                          style={{ width: "100px" }}
                        >
                          {Common.formatDate(data["Orderdatum"])}
                        </td>
                        <td
                          className={
                            !this.showColumn(filterColunm[9].label)
                              ? "filter-show__hide"
                              : ""
                          }
                        >
                          {data["Beschikbaar"]}
                        </td>
                        <td
                          className={
                            !this.showColumn(filterColunm[10].label)
                              ? "filter-show__hide"
                              : ""
                          }
                        >
                          {data["Partij"]}
                        </td>
                        <td
                          className={
                            !this.showColumn(filterColunm[11].label)
                              ? "filter-show__hide"
                              : ""
                          }
                        >
                          {data["Ordertype"]}
                        </td>
                        <td
                          className={
                            !this.showColumn(filterColunm[12].label)
                              ? "filter-show__hide"
                              : ""
                          }
                        >
                          <Row
                            style={{
                              justifyContent: "space-around",
                              width: 70,
                            }}
                          >
                            <i
                              className="far fa-file-pdf add-icon"
                              onClick={() => this.getFileDownLoad(data)}
                            >
                              <span className="action-title"></span>
                            </i>
                            {/* <i className="fas fa-undo add-icon" onClick={()=>this.returnOrderConform(data)}><span className="action-title"></span></i> */}
                          </Row>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            )}
            <Pagination
              recordNum={this.state.recordNum}
              getData={(pageSize, page) =>
                this.getOrdersData(pageSize, page, searchText)
              }
            />
            {this.state.loading && (
              <div
                className="col-md-4 offset-md-4 col-xs-12 loading"
                style={{ textAlign: "center" }}
              >
                <BallBeat color={"#222A42"} loading={this.state.loading} />
              </div>
            )}
            <HelpTextModal
              show={this.state.HelpTextModal}
              onHide={() => this.setState({ HelpTextModal: false })}
              HelpTextTitle={this.state.OrdersHelpTextTitle}
              HelpTextDescription={this.state.HelpTextDescription}
            />
          </div>
        </div>
        <Pageloadspiiner loading={pageLodingFlag} text={""} />
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Ordermanage);
