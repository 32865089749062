/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Modal, Button, Form, Row, Col, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { trls } from "../../factories/translate";
import "react-datepicker/dist/react-datepicker.css";
import API from "../../factories/api";
import * as Common from "../../factories/common";
import Axios from "axios";
import * as Auth from "../../factories/auth";
import SessionManager from "../../factories/session_manage";
import $ from "jquery";
import Pageloadspiiner from "../../components/page_load_spinner";
import history from "../../history";
import Sweetalert from "sweetalert";

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({});

class Newitemform extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    const { editOrderRowData } = this.props;
    this.state = {
      itemCode: editOrderRowData.ItemCode ? editOrderRowData.ItemCode : "",
      itemData: [],
      itemFlag: false,
      pageLodingFlag: false,
      quantity: editOrderRowData.order_quantity
        ? editOrderRowData.order_quantity
        : 0,
      customerReference: editOrderRowData.order_customerreference
        ? editOrderRowData.order_customerreference
        : "",
      itemEnable: false,
      deliveryWeek: 0,
      deliveryWeekShow: false
    };
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    $(document).on("keypress", ":input", function (e) {
      if (e.which === 13) e.preventDefault();
    });
    $("input[name='itemcode']").focus();
    $(".modal").removeAttr( "tabindex" )
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this._isMounted = true;
    const clientFormData = new FormData(event.target);
    const data = {};
    for (let key of clientFormData.keys()) {
      data[key] = clientFormData.get(key);
    }
    this.setState({pageLodingFlag: true})
    this.props.onGetStockOrderHeader().then((result) => {
      const {
        patternCalculateCheck,
        itemQuantityData,
        editPatternCalcuRow,
        orderLineId,
        orderHeaderId,
      } = this.props;
      const { itemFlag } = this.state;
      
      let quantity = 0;
      if (
        (patternCalculateCheck && !editPatternCalcuRow.rowId) ||
        (itemFlag && !itemQuantityData)
      ) {
        quantity = parseFloat(data.quantity);
      } else if (itemQuantityData) {
        quantity = parseFloat(itemQuantityData);
      }
  
      var headers = SessionManager.shared().getAuthorizationHeader();
      let params = {
        orderlineid: orderLineId,
        productcode: data.itemcode ? data.itemcode : "",
        quantity: quantity ? Number(quantity.toFixed(2)) : 0,
      }
      if (orderLineId)
       {
        Axios.post(API.UpdateStockOrderLine, params, headers)
        .then((result) => {
          var settings = {
            url: API.GetStockInfo+String(orderHeaderId),
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + Auth.getUserToken(),
            },
          };
          $.ajax(settings)
          .then((response) => {
            this.setState({deliveryWeek: response, pageLodingFlag: false, deliveryWeekShow: true})
          })
          .catch((err) => {
            this.setState({pageLodingFlag: false})
            this.displayError()
            if (err.response.status === 401) {
              history.push("/login");
            }
          });
        })
        .catch((err) => {
          if (err.response.status === 401) {
            history.push("/login");
          }
        });
      }
    })
  };

  displayError = () => {
    Sweetalert({
      text: "Something went wrong, please contact the system administrator",
      icon: "error",
    })
  }

  getItemData = () => {
    var headers = SessionManager.shared().getAuthorizationHeader();
    this._isMounted = true;
    let itemCodeData = "";
    const { itemCode } = this.state;
    const { setItemCodeFlag, itemData, onGetStockOrderHeader } = this.props;
    
    if (setItemCodeFlag) {
      itemCodeData = itemData.ItemCode;
    } else {
      itemCodeData = itemCode;
    }
    let code = 0;
    if (itemCodeData !== "") {
      code = itemCodeData;
      this.setState({ pageLodingFlag: true });
      var settings = {
        url: API.GetItemDataByItemCode + itemCodeData,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Auth.getUserToken(),
        },
      };
      $.ajax(settings)
        .done(function (response) {})
        .then((response) => {
          this.setState(
            {
              itemData: response,
              itemFlag: true,
              pageLodingFlag: false,
              itemEnable: true,
            },
            () => {
              this.props.checkPatternCalculate(itemCodeData);
              $(".fade").attr("tabindex", "disable");
            }
          );
          if (!response) {
            this.setState({ pageLodingFlag: false, itemEnable: false });
            $(".fade").attr("tabindex", "disable");
            this.props.searchItemForm(itemCodeData);
          }
        })
        .catch((err) => {
          this.setState({ pageLodingFlag: false, itemEnable: false });
          $(".fade").attr("tabindex", "disable");
          this.props.searchItemForm(itemCodeData);
        });
    }
  };

  showSearchItem = () => {
    let itemCodeData = "";
    const { itemCode } = this.state;
    const { setItemCodeFlag, itemData } = this.props;
    if (setItemCodeFlag) {
      itemCodeData = itemData.ItemCode;
    } else {
      itemCodeData = itemCode;
    }
    this.props.searchItemForm(itemCodeData);
    $(".fade").attr("tabindex", "disable");
  };

  changeItemCode = (e) => {
    this.setState({ itemCode: e.target.value });
    this.setState({ deliveryWeek: 0, deliveryWeekShow: false});
    this.props.onSetItemCodeFlag();
  };

  onHide = (mode) => {
    this.setState({
        itemCode: '',
        itemData: [],
        itemFlag: false,
        pageLodingFlag: false,
        quantity: ''
    })
    const { orderLineId } = this.props;
    this.props.onHide(orderLineId, mode);
}

  editPatternCalculate = () => {
    $(".fade").attr("tabindex", "disable");
    this.props.calculatePattern();
  };

  render() {
    const {
      quantity,
      itemFlag,
      itemCode,
      pageLodingFlag,
      customerReference,
      deliveryWeek,
      itemEnable,
    } = this.state;
    const {
      itemQuantityData,
      itemData,
      patternCalculateCheck,
      setItemCodeFlag,
      itemSearchformFlag,
      editPatternCalcuRow,
      noItemMsg,
    } = this.props;
    // const { itemQuantityData, itemData, patternCalculateCheck, setItemCodeFlag, itemSearchformFlag, slidePatternFormFlag, editPatternCalcuRow, noItemMsg } = this.props;
    let checkWeek = ''
    if (deliveryWeek === "Direct") {
      checkWeek = trls('Stork Direct')
    } else if (deliveryWeek === 'Incourant') {
      checkWeek = trls('Incourant')
    } else {
      checkWeek = deliveryWeek
    }
    return (
      <Modal
        show={this.props.show}
        onHide={() => this.onHide(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="false"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {trls("StockInfo")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group as={Row} controlId="formPlaintextPassword">
              <Col className="product-text">
                {noItemMsg ? (
                  <Form.Control
                    type="text"
                    name="itemcode"
                    autoComplete="off"
                    autoFocus
                    required
                    disabled={itemSearchformFlag}
                    className={
                      !itemEnable
                        ? "place-order__product-code active"
                        : "place-order__product-code"
                    }
                    placeholder={trls("Product_code")}
                    onChange={(e) => this.changeItemCode(e)}
                    onBlur={this.getItemData}
                  />
                ) : (
                  <Form.Control
                    type="text"
                    name="itemcode"
                    autoComplete="off"
                    autoFocus
                    required
                    disabled={itemSearchformFlag}
                    value={setItemCodeFlag ? itemData.ItemCode : itemCode}
                    className={
                      !itemEnable
                        ? "place-order__product-code active"
                        : "place-order__product-code"
                    }
                    placeholder={trls("Product_code")}
                    onChange={(e) => this.changeItemCode(e)}
                    onBlur={this.getItemData}
                  />
                )}

                <label className="placeholder-label">
                  {trls("Product_code")}
                </label>
                <i
                  className="fas fa-search place-an-order__loop"
                  aria-hidden="true"
                  onClick={this.showSearchItem}
                ></i>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintextPassword">
              <Col className="product-text">
                <Form.Control
                  type="text"
                  name="quantity"
                  required
                  disabled={
                    !patternCalculateCheck ||
                    itemSearchformFlag ||
                    (editPatternCalcuRow.rowId && !itemFlag)
                      ? true
                      : false
                  }
                  value={
                    !patternCalculateCheck || itemQuantityData
                      ? itemQuantityData
                      : quantity
                  }
                  placeholder={trls("Quantity")}
                  onChange={(e) => this.setState({ quantity: e.target.value })}
                />
                <label className="placeholder-label">{trls("Quantity")}</label>
                {editPatternCalcuRow.rowId && !itemFlag && (
                  <i
                    className="fas fa-pen place-an-order__loop"
                    aria-hidden="true"
                    onClick={() => this.editPatternCalculate()}
                  ></i>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintextPassword">
              {this.state.deliveryWeekShow && (
                <Col>
                  <span style={{paddingRight: 10}}>{trls('Stock Expected delivery week')}:</span>{checkWeek}
                </Col>
              )}
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintextPassword">
              <Col className="product-text">
                {noItemMsg && (
                  <Alert variant="secondary">
                    <p>{noItemMsg}</p>
                  </Alert>
                )}
              </Col>
            </Form.Group>
            <Form.Group style={{ textAlign: "center" }}>
              <Button
                type="Submit"
                style={{ width: "10%" }}
                disabled={noItemMsg}
              >
                {trls("Check")}
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Pageloadspiiner loading={pageLodingFlag} text={trls('One moment please')}/>
      </Modal>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Newitemform);
