import React, {Component} from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import { trls } from '../../factories/translate';
import API from '../../factories/api';
import SessionManager from '../../factories/session_manage';
import Axios from 'axios';

const mapStateToProps = state => ({ 
    ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({

});

class AddHelperTextForm extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.myInput = React.createRef(); 
        this.state = {  
            page: '',
            title: '',
            contentDutch : '',
            contentEnglish : '',
            contentGerman : '',
            contentFrench : '',
            pageName:  [
                        {"value":"News","label":trls('News')},
                        {"value":"Place_an_order","label":trls('Place_an_order')},
                        {"value":"Place_a_sample_order","label":trls('Place_a_sample_order')},
                        {"value":"Orders","label":trls('Orders')},
                        {"value":"Deliveries","label":trls('Deliveries')},
                        {"value":"Salesinvoices","label":trls('Salesinvoices')},
                        {"value":"Open_invoices","label":trls('Open_invoices')},
                        {"value":"Returns","label":trls('Returns')},
                        {"value":"Documents","label":trls('Documents')},
                        {"value":"Maintenance","label":trls('Maintenance')}
                    ],
            page_val: ''
        };
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    
    componentDidMount() {
    }

    handleSubmit = (e, formData, inputs) => {
        this._isMounted = true;
        e.preventDefault();
        var headers = SessionManager.shared().getAuthorizationHeader();

        let params ={
            "page": this.state.page_val.value,
            "title": this.state.title,
            "contentDutch": this.state.contentDutch,
            "contentEnglish": this.state.contentEnglish,
            "contentGerman": this.state.contentGerman,
            "contentFrench": this.state.contentFrench
        }

        Axios.post(API.PostHelpText, params, headers)
        .then(result => {
            if(this._isMounted){
                this.props.onHide()
                this.props.onGetUser()
                this.removeState();
            }
        })
        .catch(err => {
        });
    }

    removeState = () => {
        this.setState({
            page: '',
            title: '',
            contentDutch : '',
            contentEnglish : '',
            contentGerman : '',
            contentFrench : '',
            page_val: ''
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    hideModel = () => {
        this.props.onHide();
        this.removeState()
    }

    render(){
        return (
            <Modal
                show={this.props.show}
                onHide={this.hideModel}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop= "static"
                centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {trls('add_help_text')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit = { this.handleSubmit }>
                    <Form.Group as={Row} controlId="page_name">
                        <Col>
                            <Select
                                name="page_name"
                                // placeholder= 'select page'
                                options={this.state.pageName}
                                onChange={val => this.setState({page_val: val})}
                                defaultValue = ''
                            />
                            <label className="placeholder-label">{trls('page')}</label>
                              
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="title">
                        <Col className="product-text">
                            <Form.Control value={this.state.title} onChange={this.handleChange} type="text" name="title" required />
                            <label className="placeholder-label">{trls('Title')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="contentDutch">
                        <Col className="product-text">
                            <Form.Control onChange={this.handleChange} type="file" name="documentFile" style={{marginTop: 10}} />
                            <label className="placeholder-label">{trls('Document_File')}</label>
                            {/* <Form.Control type="text" name="contentDutch" required value={this.state.contentDutch} onChange={this.handleChange}/>
                            <label className="placeholder-label">{trls('contentDutch')}</label> */}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="contentEnglish">
                        <Col className="product-text">
                            <Form.Control type="text" name="contentEnglish" required value={this.state.contentEnglish} onChange={this.handleChange}/>
                            <label className="placeholder-label">{trls('contentEnglish')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="contentGerman">
                        <Col className="product-text">
                            <Form.Control type="text" name="contentGerman" required value={this.state.contentGerman} onChange={this.handleChange}/>
                            <label className="placeholder-label">{trls('contentGerman')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="contentFrench">
                        <Col className="product-text">
                            <Form.Control type="text" name="contentFrench" required value={this.state.contentFrench} onChange={this.handleChange}/>
                            <label className="placeholder-label">{trls('contentFrench')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group style={{textAlign:"center"}}>
                        <Button type="Submit" >{trls("Save")}</Button>
                    </Form.Group>
                </Form>    
            </Modal.Body>
            </Modal>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddHelperTextForm);