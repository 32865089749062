/* eslint-disable no-unused-vars */
import React, {Component} from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { trls } from '../../factories/translate';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import API from '../../factories/api'
import Axios from 'axios';
import SessionManager from '../../factories/session_manage';

const mapStateToProps = state => ({ 
    ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({

});

class Shippingaddressform extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {  
            CountriesOptions:[],
            defaultValue:{}
        };
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    
    componentDidMount() {
    }

    handleSubmit = (event) => {
        let shippingAddress = this.props.shippingAddress;
        this._isMounted = true;
        event.preventDefault();
        const clientFormData = new FormData(event.target);
        const data = {};
        for (let key of clientFormData.keys()) {
            data[key] = clientFormData.get(key);
        }
        if(shippingAddress){
            shippingAddress.address = data.address;
            shippingAddress.city = data.city;
            shippingAddress.zipCode = data.zipCode;
            shippingAddress.name = data.name;
            shippingAddress.country = data.country;

            if(this.props.U_DBS_EXPORTKLANT === 'Y' || this.props.U_EIJFF_PORTAL_ANDERLAND === 'Y'){
                shippingAddress.country = data.country;
            }
        }
        this.props.setShippingAddress(shippingAddress);
        this.props.onHide();
    }

    onShowModel = () => {
        const {  Countries, shippingAddress } = this.props;
        var tempCountries = [];

        if(Countries.length >0){
            (Countries).forEach(element => {
                tempCountries.push({'value':element.countryCode, 'label': element.countryInEnglish})
            });
        }
        this.setState({CountriesOptions: tempCountries});
        var countryCode = shippingAddress ? shippingAddress.country : ''
        var headers = SessionManager.shared().getAuthorizationHeader();
        
        Axios.get(API.GetCountryName + countryCode, headers)
        .then(response => {
            this.setState({
                defaultValue: {value:response.data.countryCode,label: response.data.countryInEnglish}
            })
        })
        .catch(err => {
        });
    }

    render(){
        const { shippingAddress, editShippingAddress, U_DBS_EXPORTKLANT, CountryName, U_EIJFF_PORTAL_ANDERLAND } = this.props;
        const {CountriesOptions,defaultValue} = this.state;
        return (
            <Modal
                show={this.props.show}
                onHide={()=>this.props.onHide()}
                onShow={()=> this.onShowModel()}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop= "static"
                centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {trls('Shipping_Address')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit = { this.handleSubmit }>
                    <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Col className="product-text">
                            <Form.Control type="text" name="name" defaultValue={shippingAddress ? shippingAddress.name: ''} required placeholder={trls('Name')}/>
                            <label className="placeholder-label">{trls('Name')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Col className="product-text">
                            <Form.Control type="text" maxLength="40" name="address" defaultValue={shippingAddress ? shippingAddress.address: ''} required placeholder={trls('Street')}/>
                            <label className="placeholder-label">{trls('Street')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Col className="product-text">
                            <Form.Control type="text" name="zipCode" defaultValue={shippingAddress ? shippingAddress.zipCode : ''} required placeholder={trls('Zipcode')}/>
                            <label className="placeholder-label">{trls('Zipcode')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Col className="product-text">
                            <Form.Control type="text" name="city" defaultValue={shippingAddress ? shippingAddress.city : ''} required placeholder={trls('City')}/>
                            <label className="placeholder-label">{trls('City')}</label>
                        </Col>
                    </Form.Group>
                    {U_EIJFF_PORTAL_ANDERLAND === 'Y' ? 
                        <Form.Group as={Row} controlId="formPlaintextPassword">
                            <Col sm="12" className="product-text">
                                <Select
                                    name="country"
                                    placeholder={trls('Country')}
                                    options={CountriesOptions}
                                    defaultValue={defaultValue}
                                    required
                                />
                            <label className="placeholder-label">{trls('Country')}</label>

                            </Col>
                        </Form.Group>
                    :
                        <Form.Group as={Row} controlId="formPlaintextPassword">
                            <Col className="product-text">
                                <Form.Control type="text" name="country" defaultValue={CountryName ? CountryName : (shippingAddress.country ? shippingAddress.country : shippingAddress.countryName)} required placeholder={trls('Country')}
                                readOnly/>
                                <label className="placeholder-label">{trls('Country')}</label>
                            </Col>
                        </Form.Group>
                    }

                    <Form.Group style={{textAlign:"center"}}>
                        <Button type="Submit" style={{width: "10%"}}>{trls("Save")}</Button>
                    </Form.Group>
                </Form>    
            </Modal.Body>
            </Modal>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Shippingaddressform);