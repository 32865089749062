/* eslint-disable no-unused-vars */
import { Link } from 'react-router-dom';
import React from 'react';
import SessionManager from '../../factories/session_manage';
import { connect } from 'react-redux';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Message from '../../components/message';
import { trls } from '../../factories/translate';
import API from '../../factories/api'
import $ from 'jquery';
import * as Auth from '../../factories/auth';
import Select from 'react-select';
import Axios from 'axios';
import history from '../../history';
import ReactFlagsSelect from 'react-flags-select';
import * as authAction  from '../../actions/authAction';


const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
    changeLan: (params) =>
    dispatch(authAction.changeLan(params))
});

class Registration extends React.Component {

  constructor() {   
    super();
    this.state = {  
        sendEamilFlag: false,
    //   userInfo: Auth.getUserInfo(),
    //   loginUser: false,
        countries: [],
        lang:  [{"value":"English","label":"GB"},{"value":"Dutch","label":"NL"},{"value":"German","label":"DE"},{"value":"French","label":"FR"}],
        selectLangValue: window.localStorage.getItem('eijf_lang'),

    };
  }

  componentDidMount() {
    var tempCountries = [];
    Axios.get('https://restcountries.eu/rest/v2/all')
    .then(result => {
        (result.data).forEach(element => {
            tempCountries.push({'value':element.name, 'label': element.name})
        });
        this.setState({countries: tempCountries});
    })
    .catch();
}


handleSubmit = (event) => {
    event.preventDefault();
    const params = {};
    const clientFormData = new FormData(event.target);
   
    for (let key of clientFormData.keys()) {
        params[key] = clientFormData.get(key);
    }

    Axios.post(API.PostRegistration, params)
    .then(result => {
        $('.registration-container').addClass("d-none")
        $('.alert-success').removeClass("d-none")
       
    })
    .catch(err=>{
        if(err.response.data){
            if(err.response.data.Email) {
                $('.alert-error').text(err.response.data.Email[0])
                $('.alert-error').removeClass("d-none")
            }
            
        }
    });
  }

  changeLangauge = (val) => {
    let lang = this.state.lang;
    lang = lang.filter((item, key)=>item.label===val);
    this.props.changeLan(lang[0]);
    this.setState({selectLangValue: lang[0].value});
}

  render() {
    const { loggedUserInfo, lang, selectLangValue } = this.state;
    let selectLang = lang.filter((item, key)=>item.value===selectLangValue);
    return (
      <div className="container">
          <div className="col-xl-5 col-lg-7 col-md-12  vertical-center">
              <Row>
                  <div className="login-side-div">
                      <img src='https://www.eijffinger.com/Themes/Eijffinger/Content/images/logo.svg' alt="appzmakerz" className="login-logo-img"></img>
                      <p className="login-side__footer-text">eijffinger.com</p>
                  </div>
                  <Col>
                  <div className="container mb-2 ">
                    <p className="login-title py-3">{trls('Registration')}</p>
                    <div style={{display: 'flex', justifyContent: 'end'}}>
                        <ReactFlagsSelect
                            countries={["GB", "NL", "DE", "FR"]}
                            selected={selectLang[0].label}
                            showSelectedLabel={false}
                            showOptionLabel={false}
                            fullWidth={false}
                            selectedSize={14}
                            optionsSize={14}
                            onSelect={(val) => this.changeLangauge(val)}
                        />
                    </div>
                  </div>
                  
                  <Form className="container registration-form" onSubmit = { this.handleSubmit } autoComplete="off">
                            
                            <div className="alert alert-success mb-4 d-none">{trls('Registration_sucess_message')}</div>
                            <div className="alert alert-danger alert-error mb-4 d-none"></div>
                            <div className="registration-container">
                                <Form.Group controlId="firstName">
                                    <Col className="login-form__control">
                                        <Form.Control type="text" name="firstName" className=""  required/>
                                        <label className="placeholder-label__login">{trls('FirstName')}</label>
                                    </Col>
                                </Form.Group>
                                <Form.Group controlId="lastName">
                                    <Col className="login-form__control">
                                        <Form.Control type="text" name="lastName" className=""  required/>
                                        <label className="placeholder-label__login">{trls('LastName')}</label>
                                    </Col>
                                </Form.Group>
                                <Form.Group controlId="companyName">
                                    <Col className="login-form__control">
                                        <Form.Control type="text" name="companyName" className=""  required/>
                                        <label className="placeholder-label__login">{trls('CompanyName')}</label>
                                    </Col>
                                </Form.Group>
                                <Form.Group controlId="address">
                                    <Col className="login-form__control">
                                        <Form.Control as="textarea" name="address" rows="3" style={{resize: 'none'}} required/>
                                        <label className="placeholder-label__login">{trls('Address')}</label>
                                    </Col>
                                
                                </Form.Group>
                                <Form.Group controlId="zipCode">
                                    <Col className="login-form__control">
                                        <Form.Control type="text" name="zipCode"  required/>
                                        <label className="placeholder-label__login">{trls('Zipcode')}</label>
                                    </Col>
                                </Form.Group>
                                <Form.Group controlId="city">
                                    <Col className="login-form__control">
                                        <Form.Control type="text" name="city" className=""  required/>
                                        <label className="placeholder-label__login">{trls('City')}</label>
                                    </Col>
                                </Form.Group>
                                <Form.Group controlId="email">
                                    <Col className="login-form__control">
                                        <Form.Control type="email" name="email" className="registration-input-email"  required/>
                                        <label className="placeholder-label__login">{trls('Email')}</label>
                                    </Col>
                                </Form.Group>
                                <Form.Group  controlId="country">
                                    <Col className="login-form__control">
                                        <Form.Control type="text" name="country" className=""  required/>
                                        {/* <Select
                                            name="country"
                                            // placeholder= 'select page'
                                            options={this.state.countries}
                                            onChange={val => this.setState({documentTypeId: val})}
                                            defaultValue = ''
                                        /> */}
                                        <label className="placeholder-label__login">{trls('Country')}</label>
                                        
                                    </Col>
                                </Form.Group>
                                <Button variant="primary" type="submit" style={{width: "100%", height: 42, marginBottom: 10}} onClick={()=>this.setState({modalResumeShow: true})}>{trls('Save')}</Button>
                            </div>
                            <p className="text-xs-center" style={{textAlign: "center"}}>
                                <Link to="/login" className="back-to_signin">
                                    {trls('Back_to_Log_in')}
                                </Link>
                            </p>
                            <Message message={this.props.error} type={"error"}/>
                        </Form>
                  </Col>
              </Row>
          </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
