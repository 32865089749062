/* eslint-disable no-unused-vars */
import React, {Component} from 'react'
import { connect } from 'react-redux';
import { trls } from '../../factories/translate';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import API from '../../factories/api'
import Axios from 'axios';
import * as Auth from '../../factories/auth'
import SessionManager from '../../factories/session_manage';
import { BallBeat } from 'react-pure-loaders';
import ReturnsItemsearchform from './return_Item_searchform';
import Pageloadspiiner from '../../components/page_load_spinner';
import history from '../../history';
import AddReturnsForm from './add_returns_form';
import DatePicker from "react-datepicker";
import Moment from 'moment'
import $ from 'jquery';
import Sweetalert from 'sweetalert';

const mapStateToProps = state => ({ 
    ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({

});

class AddReturns extends Component {
    
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {  
            rows: [],
            businessPartnerOption: [],
            itemData: [],
            itemCode: '',
            itemQuantity: 0,
            itemPriceData: [],
            itemQuantityData: 0,
            itemProductCodeData: [],
            pageLodingFlag: false,
            userInfo: Auth.getUserInfo(), 
            rowId: 0,
            slideItemFormFlag: false,
            addRow: false,
            orderLineNumber: 0,
            currentUserInfo: Auth.getLoggedUserInfo(),
            customer_reference: '',
            orderDetailData: [],
            orderExpenses: [],
            showDetailModal: false,
            itemCodeList: [],
            stockItemData: [],
            orderSubmitFlag: false,
            orderApproveFlag: false,
            docEntry: '',
            orderLineNum: 0,
            itemCustomerRefData: [],
            quantityFocusFlag: false,
            showNewItemModal: false,
            setItemCodeFlag: false,
            editOrderRowFlag: false,
            editOrderRowData: [],
            editRowId: '',
            quantity:null,
            submitEnable:true,
            PickUpDate: new Date(),
            NumberOfPackages: ''

        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this.getCustomerData();
    }

    getCustomerData = () => {
        this._isMounted = true;
        let params = {};
        var headers = SessionManager.shared().getAuthorizationHeader();
        Axios.post(API.GetCustomerData, params, headers)
        .then(result => {
            this.setState({CardCode:result.data.value[0].CardCode})
            if(this._isMounted){
                if(result.data.value.length){
                    let addressData = this.getShippingAddressOptionData(result.data.value);
                    this.setState({
                        businessPartnerOption: result.data.value, 
                        billAddress: addressData[0][0],
                    });
                }
            }
        })
        .catch(err => {
            // if(err.response.status===401){
            //     history.push('/login')
            // }
        });
    }

    onSubmitOrder = (e) => {
        e.preventDefault();
        this.setState({pageLodingFlag: true});
        this._isMounted = true;
        const { rows, currentUserInfo, PickUpDate, NumberOfPackages } = this.state;
        let documentLineArray = []
        let params = [];
        if(rows){
            var lineArray = {}

            rows.map((row, index)=>{
                lineArray = {
                    "U_DBS_CUSTREF" : row.commission ? row.commission : 0 ,
                    "ItemCode": row.itemCode ? row.itemCode : '',
                    "U_DBS_RETURNREMARK": row.comments ? row.comments : '',
                    "Quantity": row.quantity,
                    "U_PMX_LOCO": "RETOURVERK",
                    "U_PMX_QYSC": "RELEASED"

                }
                documentLineArray.push(lineArray);
                return row;
            })
        }
        this.setState({pageLodingFlag: true});
        var headers = SessionManager.shared().getAuthorizationHeader();
        params = {
            "requestData": {
                "DocumentLines": documentLineArray,
                "U_AantalPackages": NumberOfPackages,
                "CardCode": this.state.CardCode,
                "DocDueDate": Moment(PickUpDate).format('YYYY-MM-DD'),
            },
            "parameters": {
            },
            
        }
        
        Axios.post(API.PostReturn, params, headers)
        .then(result => {
            var settings = {
                "url": API.ReturnLoadfromsap+result.data.DocEntry,
                "method": "POST",
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+Auth.getUserToken(),
            }
            }
            $.ajax(settings).done(function (response) {
            })
            .then(response => {
                this.setState({pageLodingFlag: false});
                Sweetalert(trls('Success'), {
                    icon: "success",
                }).then((value) => {
                    history.push('/returns')
                });
            })
            .catch(err => {
                this.setState({pageLodingFlag: false});
            });
            // Axios.post(API.Loadfromsap+result.data.DocEntry, headers)
            // .then(result => {
            //     this.setState({pageLodingFlag: false});
            //     history.push('/returns')
            // })
            // .catch(err => {
            //     this.setState({pageLodingFlag: false});
            // });
        })
        .catch(err => {
            this.setState({pageLodingFlag: false});
        });
    }


    setOrderLineData = (deliveriesData) => {
        let documentLineData = [];
        deliveriesData.map((data, index)=>{
            data.DocumentLines.map((documentLine, key)=>{
                if(documentLine.TreeType==="iSalesTree"){
                    documentLineData.push(documentLine);
                }
                return documentLine;
            })
            data.DocumentLines = documentLineData;
            return data;
        });
        return deliveriesData;
    }


    removeOrderRow = (rowId) => {
        const { rows } = this.state;
        let rowsArr = rows.filter((item, key) => item.rowId !== rowId);
        this.setState({
            rows: rowsArr,
        });
    }

    searchItemForm = (itemCode, orderLineNumber) => {
        let orderLineNum  = this.state.orderLineNumber;
        this.setState({slideItemFormFlag: true, itemCode: itemCode,  orderLineNumber: orderLineNumber ? orderLineNumber : orderLineNum})
    }

    setOrderItem = (itemList) => {
        this.setState({itemData: itemList, itemCode: itemList.ItemCode, setItemCodeFlag: true});
    }

    addOrderRowData = (rowData) => {
        const { rowId } = this.state;
        let rows = this.state.rows;
        rowData.rowId = rowId;
        rows.push(rowData);
        this.setState({rows: rows});
    }

    newAddOrderLine = () => {
        let rowId = this.state.rowId;
        this.setState({showNewItemModal: true, rowId: rowId+1, orderLineNumber: rowId+1, editOrderRowFlag: false, editOrderRowData: [], setItemCodeFlag: false, })
    }

    editOrderRowLine = ( editOrderData, editRowId) => {
        this.setState({editOrderRowFlag: true, editOrderRowData: editOrderData, showNewItemModal: true, editRowId: editRowId})
    }

    updateOrderRowLine = (itemRowData) => {
        const { editRowId } = this.state;
        let updateRowData = [];
        let rows = this.state.rows;
        rows.map((row, index)=>{
            if(row.rowId === editRowId){
                itemRowData.rowId = editRowId;
                row = itemRowData;
            }
            updateRowData.push(row);
            return row
        })
        this.setState({rows: updateRowData, itemData: itemRowData, itemCode: itemRowData.ItemCode});
    }

    handleDateChange =(date) =>{
        this.setState({
            PickUpDate: date
        })
    }


    render(){   
        let totalAmount = 0;
        const { 
            pageLodingFlag, 
            slideItemFormFlag,
            itemQuantityData,
            rows,
            itemData,
            setItemCodeFlag,
            editOrderRowFlag,
            editOrderRowData,
            showNewItemModal,
            NumberOfPackages,
            PickUpDate
        } = this.state; 

        return (
            <div className="order_div">
                <div className="content__header content__header--with-line">
                    <div id="google_translate_element"></div>
                    <h2 className="title">{trls("Request a return")}</h2>
                </div>
                <Container>
                <Form onSubmit = { this.onSubmitOrder }>
                    <Row >
                        <Col sm={6} style={{paddingLeft: 0, paddingTop: 10}}>
                            <Form.Group as={Row} controlId="NumberOfPackages" className="mb-0">
                                <Form.Label column sm="4">
                                    {trls("Number_of_packages")}  
                                </Form.Label>
                                <Col sm="8" className="product-text">
                                    <Form.Control type="number" name="NumberOfPackages" required autoFocus min="1" max="10" autoComplete="off"
                                    value={ NumberOfPackages} onChange={(e)=>this.setState({NumberOfPackages: e.target.value>10 ? 10 : e.target.value})}/>
                                </Col>
                            </Form.Group>
                        
                        </Col>
                        <Col sm={6} style={{paddingLeft: 0, paddingTop: 10}}>
                        <Form.Group as={Row} controlId="formPlaintextPassword">
                                <Form.Label column sm="4">
                                    {trls('PickUpDate')} 
                                </Form.Label>
                                <Col className="product-text">
                                    <div className="form-control">
                                        <DatePicker autoComplete="off"
                                        style={{border:"transparent"}}
                                        defaultShow={PickUpDate}
                                        selected={ PickUpDate  }
                                        onChange={ this.handleDateChange }
                                        name="PickUpDate"
                                        dateFormat="MM/dd/yyyy"
                                        />
                                    </div>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="table-responsive order-row mt-4">
                        <table id="example" className="place-and-orders__table table table--striped prurprice-dataTable" width="100%">
                        <thead>
                            <tr>
                                <th>{trls("Product_code")}</th>
                                <th>{trls('Quantity')}</th>
                                <th>{trls('Commission')}</th>
                                <th>{trls('Comments')}</th>
                                <th>{trls("Action")}</th>
                            </tr>
                        </thead>
                        {rows &&(<tbody>
                            {
                                rows.map((data,index) =>(
                                <tr id={index} key={index}>
                                    <td>
                                        <Form.Control id="itemCode" type="text" name="productcode" readOnly placeholder={trls('Product_code')} value={data.itemCode ? data.itemCode : ''}/>
                                    </td>
                                    <td>
                                        <Form.Control type="text" name="quantity" className="place_an_orrder-quantity" ref="quantity" readOnly value={data.quantity ? data.quantity : ''} />
                                    </td>
                                    <td >
                                        <Form.Control type="text" name="commission" className="place_an_orrder-commission-y" readOnly value={data.commission ? data.commission : ''} />
                                    </td>
                                    <td>
                                        <Form.Control type="text" name="comments" className="place_an_orrder-comments" ref="comments" readOnly value={data.comments ? data.comments : ''} title={data.comments ? data.comments : null} />
                                    </td>
                                    <td>
                                        <Row style={{justifyContent: "space-around"}}>
                                            <i className="fas fa-pen add-icon" onClick={()=>this.editOrderRowLine(data, data.rowId)}></i>
                                            <i className="fas fa-trash-alt add-icon" onClick = {()=>this.removeOrderRow(data.rowId)}></i>
                                        </Row>
                                    </td>
                                </tr>
                            ))
                            }
                        </tbody>)}
                        </table>
                        { this.state.loading&& (
                        <div className="col-md-4 offset-md-4 col-xs-12 loading" style={{textAlign:"center"}}>
                            <BallBeat
                                color={'#222A42'}
                                loading={this.state.loading}
                            />
                        </div>
                        )}
                    </div>
                    <div>
                        <Button variant="light" onClick={()=>this.newAddOrderLine()}><i className="fas fa-plus add-icon"></i>{trls('Add return')}</Button> 
                    </div>
                
                {/* <Col sm={4} className="info-block info-block--green">
                    <span className="txt-bold">Order Total</span>
                    {showPrice ? (
                        <span>{Common.formatMoney(totalAmount)}</span>
                    ): null}
                </Col> */}
                <div style={{textAlign: "right", height: 50}}>
                    <Button type="submit" className="place-submit__order" disabled={rows.length<=0}>{trls('Submit return')}</Button>
                </div>
                </Form>
            </Container>
            {showNewItemModal && (
                <AddReturnsForm
                    show={this.state.showNewItemModal}
                    onHide={() => this.setState({showNewItemModal: false, itemQuantityData: '', itemData: '', setItemCodeFlag: false, ReturnsItemsearchformFlag: false})}
                    getItemData={()=>this.getItemData()}
                    searchItemForm={(itemCode)=>this.searchItemForm(itemCode)}
                    onSetItemCodeFlag={()=>this.setState({setItemCodeFlag: false})}
                    onAddOrderRow={(rowData)=>this.addOrderRowData(rowData)}
                    itemQuantityData={itemQuantityData}
                    itemData={itemData}
                    setItemCodeFlag={setItemCodeFlag}
                    ReturnsItemsearchformFlag={slideItemFormFlag}
                    editOrderRowFlag={editOrderRowFlag}
                    editOrderRowData={editOrderRowData}
                    // editPatternCalcuRow={editPatternCalcuRow}
                    updateOrderRowLine={(itemData)=>this.updateOrderRowLine(itemData)}
                />
            )}
            
            {slideItemFormFlag ? (
                <ReturnsItemsearchform
                    onHide={() => this.setState({slideItemFormFlag: false, temQuantityData: ''})}
                    onSetItemData={(itemList) => this.setOrderItem(itemList)}
                    itemCode={this.state.itemCode}
                /> 
            ): null}

            <Pageloadspiiner loading = {pageLodingFlag} text={''}/>
        </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddReturns);