/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import { trls } from "../../factories/translate";
import { Row, Col, Button, Form } from "react-bootstrap";
import SessionManager from "../../factories/session_manage";
import API from "../../factories/api";
import Axios from "axios";
import $ from "jquery";
import { BallBeat } from "react-pure-loaders";
import "react-datepicker/dist/react-datepicker.css";
import "datatables.net";
import history from "../../history";
import * as Common from "../../factories/common";
import Filtercomponent from "../../components/filtercomponent";
import Returnorders from "./retunrorders_form";
import * as Auth from "../../factories/auth";
import * as authAction from "../../actions/authAction";
import HelpTextModal from "../Dashboard/HelpText";
import { Document, Page } from "react-pdf";
import Pagination from "../../components/pagination_default20";

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  blankdispatch: (blankFlag) => dispatch(authAction.blankdispatch(blankFlag)),
});

class Deliveriesmanage extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      returnsData: [],
      deliveriesData: [],
      originFilterData: [],
      ordersData: [],
      filterColunm: [
        { label: "Return number", value: "DocNum", type: "text", show: true },
        { label: "ItemCode", value: "Product", type: "text", show: true },
        { label: "Collection", value: "Collectie", type: "text", show: true },
        { label: "Quantity", value: "Quantity", type: "text", show: true },
        {
          label: "Reference_Line",
          value: "Reference Line",
          type: "text",
          show: true,
        },
        { label: "PickUpDate", value: "ShipDate", type: "date", show: true },
        { label: "Status", value: "LineStatus", type: "text", show: true },
        { label: "Return order", value: "Action", type: "text", show: true },
      ],
      showOrdersForm: false,
      HelpTextModal: false,
      pdf: "",
      recordNum: 0,
      searchText: ''
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    let obj = this;
    $("#search").on("keyup", function (event) {
      if (event.keyCode === 13 || event.key === "Enter") {
        obj.setState({ searchText: this.value }, () => {
          obj.setState({searchText: this.value})
          obj.getReturnsData(20, 1, this.value);
        });
      }
    });
    this.getReturnsData(20, 1, '');
  }

  getHelpText = () => {
    var pageName = "Returns";
    var headers = SessionManager.shared().getAuthorizationHeader();
    var lang = localStorage.getItem("eijf_lang");
    Axios.get(API.GetHelpTextByName + pageName, headers)
      .then((result) => {
        let helpURL = "";
        if (lang === "Dutch") {
          helpURL = result.data.contentDutch;
        } else if (lang === "English") {
          helpURL = result.data.contentEnglish;
        } else if (lang === "French") {
          helpURL = result.data.contentFrench;
        } else {
          helpURL = result.data.contentGerman;
        }
        const a = document.createElement("a");
        a.setAttribute("href", helpURL);
        a.setAttribute("target", "_blank");
        a.click();
      })
      .catch((err) => {
        // if(err.response.status===401){
        //     history.push('/login')
        // }
      });
  };

  // getOrdersData (pageSize, page) {
  //     this._isMounted = true;
  //     this.setState({loading: true});
  //     var settings = {
  //         "url": API.GetOrdersData,
  //         "method": "POST",
  //         "headers": {
  //             "Content-Type": "application/json",
  //             "Authorization": "Bearer "+Auth.getUserToken(),
  //         },
  //         "data": JSON.stringify({"top":pageSize,"skip":page})
  //     }
  //     $.ajax(settings).done(function (response) {
  //     })
  //     .then(response => {
  //         if(this._isMounted){
  //             this.setState({ordersData: response.value});
  //             this.setState({loading:false})
  //         }
  //     });
  // }
  getDeliveriesData = () => {
    this._isMounted = true;
    this.setState({ loading: true });
    let params = {};
    var headers = SessionManager.shared().getAuthorizationHeader();
    Axios.post(API.GetDeliveriesData, params, headers)
      .then((result) => {
        if (this._isMounted) {
          this.setState({ loading: false });
          let deliveriesDataList = this.setDeliveriesData(result.data.value);
          this.setState({
            deliveriesData: deliveriesDataList,
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          history.push("/login");
        }
      });
  };

  getReturnsData = (pageSize, pageNumber, searchText) => {
    this._isMounted = true;
    this.setState({ loading: true, recordNum: 0 });
    var headers = SessionManager.shared().getAuthorizationHeader();
    var params = {
      skip: pageSize * (pageNumber - 1),
      quickSearch: searchText,
      shipToCode: localStorage.getItem('eijf_shippingAddress')
    };
    Axios.post(API.GetReturtLines, params, headers)
      .then((result) => {
        if (this._isMounted) {
          let returnsDataList = result.data.value;
          returnsDataList.sort(function (a, b) {
            return parseInt(b["DocNum"]) - parseInt(a["DocNum"]);
          });
          this.setState({
            returnsData: returnsDataList,
            originFilterData: returnsDataList,
            loading: false,
            recordNum: result.data["@odata.count"],
          });
          // $('.filter').on( 'keyup', function () {
          //     table.search( this.value ).draw();
          // } );
          // $('#returns-table').dataTable().fnDestroy();
          // var table = $('#returns-table').DataTable(
          // {
          //     "language": {
          //         "lengthMenu": trls("Show")+" _MENU_ "+trls("Result_on_page"),
          //         "zeroRecords": "Nothing found - sorry",
          //         "info": trls("Show_page")+" _PAGE_ "+trls('Results_of')+" _PAGES_",
          //         "infoEmpty": "No records available",
          //         "infoFiltered": "(filtered from _MAX_ total records)",
          //         "search": trls('Search'),
          //         "paginate": {
          //             "previous": trls('Previous'),
          //             "next": trls('Next')
          //         }
          //     },
          //         "dom": 't<"bottom-datatable" lip>',
          //         "bSort": false,
          //     }
          // );
        }
      })
      .catch((err) => {
        // if(err.response.status===401){
        //     history.push('/login')
        // }
      });
  };

  // setReturnsData = (deliveriesData) => {
  //     let returnDeliveriesData = [];
  //     let documentLineData = [];
  //     deliveriesData.map((data, index)=>{
  //         data.DocumentLines.map((documentLine, key)=>{
  //             if(documentLine.TreeType==="iSalesTree"){
  //                 documentLineData = documentLine;
  //                 documentLineData.DocDate = data.DocDate;
  //                 documentLineData.CardName = data.CardName;
  //                 documentLineData.DocNum = data.DocNum;
  //                 returnDeliveriesData.push(documentLineData);
  //             }
  //             return documentLine;
  //         })
  //         return data;
  //     });
  //     return returnDeliveriesData;
  // }

  // setDeliveriesData = (deliveriesData) => {
  //     let returnDeliveriesData = [];
  //     let documentLineData = [];
  //     deliveriesData.map((data, index)=>{
  //         data.DocumentLines.map((documentLine, key)=>{
  //             if(documentLine.TreeType==="iSalesTree"){
  //                 documentLineData = documentLine;
  //                 documentLineData.DocDate = data.DocDate;
  //                 documentLineData.CardName = data.CardName;
  //                 documentLineData.DocNum = data.DocNum;
  //                 documentLineData.NumAtCard = data.NumAtCard;
  //                 if(data.DocumentPackages.length>0){
  //                     documentLineData.TrackAndTrace = data.DocumentPackages[0].U_DBS_TrackAndTrace;
  //                 } else {
  //                     documentLineData.TrackAndTrace = null;
  //                 }

  //                 returnDeliveriesData.push(documentLineData);
  //             }
  //             return documentLine;
  //         })
  //         return data;
  //     });
  //     return returnDeliveriesData;
  // }

  // showPlaceOrder = (docNumber) => {
  //     history.push({
  //         pathname: '/return-detail/'+docNumber,
  //         state: { id: docNumber, newSubmit:true }
  //     })
  // }

  removeColumn = (value) => {
    let filterColunm = this.state.filterColunm;
    filterColunm = filterColunm.filter(function (item, key) {
      if (item.label === value) {
        item.show = false;
      }
      return item;
    });
    this.setState({ filterColunm: filterColunm });
  };

  showColumn = (value) => {
    let filterColum = this.state.filterColunm;
    filterColum = filterColum.filter((item, key) => item.label === value);
    return filterColum[0].show;
  };

  // filter module
  filterOptionData = (filterOption) => {
    const { originFilterData } = this.state;
    this.setState({ loading: true });
    let dataA = [];
    dataA = Common.filterData(filterOption, originFilterData);
    setTimeout(() => {
      if (!filterOption.length) {
        dataA = null;
        this.setState({ returnsData: originFilterData, loading: false });
      } else {
        this.setState({ returnsData: dataA, loading: false });
      }
      $(".fitler").on("keyup", function () {
        table.search(this.value).draw();
      });
      $("#returns-table").dataTable().fnDestroy();
      var table = $("#returns-table").DataTable({
        language: {
          lengthMenu: trls("Show") + " _MENU_ " + trls("Result_on_page"),
          zeroRecords: "Nothing found - sorry",
          info:
            trls("Show_page") + " _PAGE_ " + trls("Results_of") + " _PAGES_",
          infoEmpty: "No records available",
          infoFiltered: "(filtered from _MAX_ total records)",
          search: trls("Search"),
          paginate: {
            previous: trls("Previous"),
            next: trls("Next"),
          },
        },
        dom: 't<"bottom-datatable" lip>',
        bSort: false,
      });
    });
  };

  changeFilter = () => {
    if (this.state.filterFlag) {
      this.setState({ filterFlag: false });
    } else {
      this.setState({ filterFlag: true });
    }
  };
  // filter module
  getFileDownLoad = (data) => {
    this.setState({ pageLodingFlag: true });
    this._isMounted = true;
    let params = {
      objectId: data["PDFObjectId"],
      keyValue: data["PDFKeyValue"],
    };
    var headers = SessionManager.shared().getAuthorizationHeader();
    Axios.post(API.GetDownloadFile, params, headers)
      .then((result) => {
        if (result.data.pdf) {
          this.setState({ pageLodingFlag: false });
          this.downloadWithName(
            "data:application/octet-stream;charset=utf-16le;base64," +
              result.data.pdf,
            data["Retournummer"] + ".pdf"
          );
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          history.push("/login");
        }
      });
  };

  downloadWithName = (uri, name) => {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.click();
  };

  addReturns = () => {
    history.push("/add-returns");
    // this.props.blankdispatch(this.props.blankFlag);
  };

  render() {
    const { filterColunm, returnsData, deliveriesData, ordersData, searchText } =
      this.state;
    let filterData = [
      {
        label: trls("Return number"),
        value: "DocNum",
        type: "text",
        show: true,
      },
      { label: trls("ItemCode"), value: "ItemCode", type: "text", show: true },
      { label: trls("Item_Name"), value: "ItemName", type: "text", show: true },
      { label: trls("Quantity"), value: "Quantity", type: "text", show: true },
      {
        label: trls("Reference_Line"),
        value: "Reference Line",
        type: "text",
        show: true,
      },
      {
        label: trls("PickUpDate"),
        value: "Pick-up date",
        type: "date",
        show: true,
      },
      { label: trls("Status"), value: "Status", type: "text", show: true },
    ];
    return (
      <div className="order_div">
        <div className="content__header content__header--with-line">
          <div id="google_translate_element"></div>
          <h2 className="title">{trls("Returns")}</h2>
        </div>

        <div className="orders">
          <Row>
            <Col sm={6}>
              {/* <Button variant="primary" onClick={()=>this.setState({showOrdersForm: true})}><i className="fas fa-plus add-icon"></i>{trls('Add return')}</Button>  */}
              <Button variant="primary" onClick={() => this.addReturns()}>
                <i className="fas fa-plus add-icon"></i>
                {trls("Add return")}
              </Button>
            </Col>
            <Col sm={6} className="has-search">
              <div style={{ display: "flex", float: "right" }}>
                <Button
                  variant="primary"
                  className="float-right"
                  onClick={this.getHelpText}
                >
                  <i className="fa fa-info-circle" aria-hidden="true"></i>
                </Button>
                {/* <Button style={{marginLeft: 20}} variant="light" onClick={()=>this.changeFilter()}><i className="fas fa-filter add-icon"></i>{trls('Filter')}</Button> */}
                <div style={{ marginLeft: 20 }}>
                  <span className="fa fa-search form-control-feedback"></span>
                  <Form.Control
                    id="search"
                    className="form-control filter"
                    type="text"
                    name="number"
                    placeholder={trls("Quick_search")}
                  />
                </div>
              </div>
            </Col>
            {filterColunm.length && (
              <Filtercomponent
                onHide={() => this.setState({ filterFlag: false })}
                filterData={filterData}
                onFilterData={(filterOption) =>
                  this.filterOptionData(filterOption)
                }
                showFlag={this.state.filterFlag}
              />
            )}
          </Row>
          <div className="table-responsive credit-history">
            <table
              id="returns-table"
              className="place-and-orders__table table"
              width="100%"
            >
              <thead>
                <tr>
                  {filterColunm.map((item, key) => (
                    <th
                      className={!item.show ? "filter-show__hide" : ""}
                      key={key}
                    >
                      {trls(item.label)}
                    </th>
                  ))}
                </tr>
              </thead>
              {returnsData && !this.state.loading && (
                <tbody>
                  {returnsData.map((data, i) => (
                    <tr id={i} key={i}>
                      <td
                        className={
                          !this.showColumn(filterColunm[0].label)
                            ? "filter-show__hide"
                            : ""
                        }
                      >
                        {data["Retournummer"]}
                      </td>
                      <td
                        className={
                          !this.showColumn(filterColunm[1].label)
                            ? "filter-show__hide"
                            : ""
                        }
                      >
                        <img
                          src={
                            data.picture
                              ? "data:image/png;base64," + data.picture
                              : ""
                          }
                          alt={data.picture ? i : ""}
                          className="image__zoom d-none"
                        ></img>{" "}
                        {data["Artikel"]}
                      </td>
                      <td
                        className={
                          !this.showColumn(filterColunm[2].label)
                            ? "filter-show__hide"
                            : ""
                        }
                      >
                        {data["Collectie"]}
                      </td>
                      <td
                        className={
                          !this.showColumn(filterColunm[3].label)
                            ? "filter-show__hide"
                            : ""
                        }
                      >
                        {data["Aantal"]}
                      </td>
                      <td
                        className={
                          !this.showColumn(filterColunm[4].label)
                            ? "filter-show__hide"
                            : ""
                        }
                      >
                        {data["Referentie_tekst"]}
                      </td>
                      <td
                        className={
                          !this.showColumn(filterColunm[5].label)
                            ? "filter-show__hide"
                            : ""
                        }
                      >
                        {Common.formatDate(data["Afhaaldatum"])}
                      </td>
                      <td
                        className={
                          !this.showColumn(filterColunm[6].label)
                            ? "filter-show__hide"
                            : ""
                        }
                      >
                        {data["Status"] === "C" ? trls("Close") : trls("Open")}
                      </td>
                      <td
                        className={
                          !this.showColumn(filterColunm[7].label)
                            ? "filter-show__hide"
                            : ""
                        }
                      >
                        <Row
                          style={{ justifyContent: "space-around", width: 70 }}
                        >
                          <i
                            className="far fa-file-pdf add-icon"
                            onClick={() => this.getFileDownLoad(data)}
                          >
                            <span className="action-title"></span>
                          </i>
                        </Row>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            <Pagination
              recordNum={this.state.recordNum}
              getData={(pageSize, page) => this.getReturnsData(pageSize, page, searchText)}
            />
            {this.state.loading && (
              <div
                className="col-md-4 offset-md-4 col-xs-12 loading"
                style={{ textAlign: "center" }}
              >
                <BallBeat color={"#222A42"} loading={this.state.loading} />
              </div>
            )}
          </div>
          {this.state.showOrdersForm &&
            deliveriesData.length > 0 &&
            ordersData.length > 0 && (
              <Returnorders
                show={this.state.showOrdersForm}
                onHide={() => this.setState({ showOrdersForm: false })}
                deliveriesData={deliveriesData}
                ordersData={ordersData}
              />
            )}
          <HelpTextModal
            show={this.state.HelpTextModal}
            onHide={() => this.setState({ HelpTextModal: false })}
            HelpTextTitle={this.state.ReturnsHelpTextTitle}
            HelpTextDescription={this.state.HelpTextDescription}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Deliveriesmanage);
