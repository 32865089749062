export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
export const FETCH_LOGIN_FAIL = 'FETCH_LOGIN_FAIL';
export const FETCH_SALES_GETDATA = 'FETCH_SALES_GETDATA';
export const FETCH_CUSTOMER_DATA = 'FETCH_CUSTOMER_DATA';
export const FETCH_SALES_SAVEDATA = 'FETCH_SALES_SAVEDATA';
export const FETCH_GETSALES_DATA = 'FETCH_GETSALES_DATA';
export const FETCH_GETSALESITEM_DATA = 'FETCH_GETSALESITEM_DATA';
export const FETCH_BlANK_DATA = 'FETCH_BlANK_DATA';
export const FETCH_SERVER_FAIL = 'FETCH_SERVER_FAIL';
export const FETCH_LANGUAGE_DATA = 'FETCH_LANGUAGE_DATA';
export const FETCH_PAGE_LOADING = 'FETCH_PAGE_LOADING'
export const FETCH_SALES_SALESUPLODFILE = 'FETCH_SALES_SALESUPLODFILE'
export const FETCH_SALES_BLANKFILE = 'FETCH_SALES_BLANKFILE'
export const ADMIN_FLAG = 'ADMIN_FLAG'