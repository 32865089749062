/* eslint-disable no-unused-vars */
import React, {Component} from 'react'
import { Row, Col, Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import $ from 'jquery';
import SessionManager from '../../factories/session_manage';
import API from '../../factories/api'
import Axios from 'axios';
import { BallBeat } from 'react-pure-loaders';
import { trls } from '../../factories/translate';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'datatables.net';
import * as Common from '../../factories/common';
import * as Auth from '../../factories/auth';
import * as authAction  from '../../actions/authAction';
import history from '../../history';
import AddDocumentTypeForm from './add_documentType';
import UpdateDocumentTypeForm from './update_documentType';

class DocumentType extends Component {
    _isMounted = false
    constructor(props) {
        super(props);
        this.state = {
            DocumentTypeData:[],
            DocumentTypeUpdateData:[],
            loading:true,
            slideFormFlag: false,
            originFilterData: [],
            filterFlag: false,
            filterColunm: [
                {"label": 'Name', "value": "name", "type": 'text', "show": true},
                {"label": 'documentLanguage', "value": "documentLanguage", "type": 'text', "show": true},
                {"label": 'Edit', "value": "active", "type": 'text', "show": true},
                {"label": 'Delete', "value": "action", "type": 'text', "show": true},
            ],
            userInfo: Auth.getUserInfo(), 
            userType: '',
            showAllUser: false,
            customer_groupList: []
            // updateHelpTextModal: false
        };
    }

    componentDidMount() {
        this._isMounted=true;
        this.getDocumentTypeData();
        this.getCustomerGroup();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps = nextProps => {
        this.setState({
            userType: nextProps.userType
        });
    };

    showalert(alerttype,message) {
        $("#alertdiv").remove();
        $('#alert_placeholder').append('<div id="alertdiv" class="alert ' +  alerttype + '" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>' + message + '</div>');
        setTimeout(function() {
            $("#alertdiv").remove();
        }, 3000);
     }
    
    getDocumentTypeData (data) {    
        this._isMounted = true;
        this.setState({loading: true})
        var headers = SessionManager.shared().getAuthorizationHeader();
        Axios.get(API.GetAllDocumentType, headers)
        .then(result => {
                if(this._isMounted){
                    if(!data){
                        this.setState({DocumentTypeData: result.data, originFilterData: result.data});
                    }else{
                        this.setState({DocumentTypeData: data});
                    }
                    this.setState({loading:false})
                    
                }
        })
        .catch(err => {
            // if(err.response.status===401){
            //     history.push('/login')
            // }
            // if(err.response.status===400){
            //     this.showalert("alert-danger", err.response.data);
            // }
        })
        
    }

    getCustomerGroup = () => {
        this._isMounted = true;
        var headers = SessionManager.shared().getAuthorizationHeader();
        Axios.post(API.GetCustomerGroup, {}, headers)
        .then(result => {
            if(this._isMounted){
                var groupTempList = []
                result.data.value.forEach(element => {
                    groupTempList.push({"value":element.Code,"label":element.Name})
                });
                this.setState({customerGroupList: groupTempList});
            }
        })
        .catch(err => {
            if(err.response.status===400){
                $('.add-error').removeClass('d-none')
                $('.add-error').text(err.response.data)
            }
        });
    }

    // filter module
    filterOptionData = (filterOption) =>{
        let dataA = []
        dataA = Common.filterData(filterOption, this.state.originFilterData);
        if(!filterOption.length){
            dataA=null;
        }
        $('#project_table').dataTable().fnDestroy();
        this.getDocumentTypeData(dataA);
    }

    changeFilter = () => {
        if(this.state.filterFlag){
            this.setState({filterFlag: false})
        }else{
            this.setState({filterFlag: true})
        }
    }
    // filter module
    DocumentTypeUpdate = (id) => {
        this._isMounted = true;
        var headers = SessionManager.shared().getAuthorizationHeader();
        Axios.get(API.GetDocumentType+id, headers)
        .then(response => {
            this.setState({documentTypeUpdateData: response.data, updateHelpTextModal: true});
        })
        .catch(err => {
            if(err.response.status===401){
                history.push('/login')
            }
        });
    }

    userDelete = (id) => {
        // var params = {"id":id}
        var headers = SessionManager.shared().getAuthorizationHeader();
        confirmAlert({
            title: 'Confirm',
            message: trls('Confirm_Delete_Message'),
            buttons: [
              {
                label: trls('Delete'),
                onClick: () => {
                    Axios.delete(API.DeleteDocumentType+id,headers)
                    .then(result => {
                        this.setState({loading:true})
                        this.getDocumentTypeData();               
                    })
                    .catch(err => {
                        if(err.response.status===401){
                            history.push('/login')
                        }
                        if(err.response.status===400){
                            this.showalert("alert-danger", err.response.data);
                        }
                    });
                }
              },
              {
                label: 'Cancel',
                onClick: () => {}
              }
            ]
          });
       
    }


    render() {
        const { filterColunm, DocumentTypeData } = this.state;
        let DocumentTypeDataList = DocumentTypeData;
        
        return (
            <div className="order_div">
            <div className="content__header content__header--with-line">
                <h2 className="title">{trls('DocumentType')}</h2>
            </div>
            <div className="orders">
                <Row>
                    <Col sm={6}>
                        <Button variant="primary" onClick={()=> this.setState({addDocumentTypeModal: true}) }><i className="fas fa-plus add-icon mr-2"></i>{trls('Add_DocumentType')}</Button>
                    </Col>
                    <Col sm={6} className="has-search">
                        <div style={{display: 'flex', float: 'right'}}>
                            {/* <Button variant="light" onClick={()=>this.changeFilter()}><i className="fas fa-filter add-icon"></i>{trls('Filter')}</Button> */}
                            <div style={{marginLeft: 20}}>
                                <span className="fa fa-search form-control-feedback"></span>
                                <Form.Control className="form-control fitler" type="text" name="number"placeholder={trls("Quick_search")}/>
                            </div>
                        </div>
                    </Col>
                </Row>
                
                <div className="table-responsive credit-history">
                        <table id="example" className="place-and-orders__table table" width="99%">
                        <thead>
                            <tr>
                                {filterColunm.map((item, key)=>(
                                    <th className={!item.show ? "filter-show__hide" : ''} key={key}>
                                       {trls(item.label) ? trls(item.label) : ''}
                                    </th>
                                    )
                                )}
                            </tr>
                        </thead>
                        {DocumentTypeDataList && !this.state.loading &&(<tbody >
                            {
                                DocumentTypeDataList.map((data,i) =>(
                                    <tr id={data.id} key={i}>
                                        <td >{data.name}</td>
                                        <td >{data.documentLanguage}</td>
                                       
                                        <td >
                                            <i className="fas fa-edit add-icon" onClick={()=>this.DocumentTypeUpdate(data.id)}></i>
                                        </td>
                                        <td style={{cursor:'pointer'}}>
                                            <i className ="fa fa-trash" onClick={()=>this.userDelete(data.id)}></i>
                                        </td>
                                    </tr>
                            ))
                            }
                        </tbody>)}
                    </table>
                    { this.state.loading&& (
                        <div className="col-md-4 offset-md-4 col-xs-12 loading" style={{textAlign:"center"}}>
                            <BallBeat
                                color={'#222A42'}
                                loading={this.state.loading}
                            />
                        </div>
                    )}
                </div>
            </div>
            <AddDocumentTypeForm
                show={this.state.addDocumentTypeModal}
                onHide={() => this.setState({addDocumentTypeModal: false})}
                onGetUser={() => this.getDocumentTypeData()}
                customerGroupList = {this.state.customerGroupList}
            />
            <UpdateDocumentTypeForm
                show={this.state.updateHelpTextModal}
                onHide={() => this.setState({updateHelpTextModal: false})}
                onGetUser={() => this.getDocumentTypeData()}
                documentTypeUpdateData = {this.state.documentTypeUpdateData}
                customerGroupList = {this.state.customerGroupList}
            />
            
        </div>
        );
  }
}

export default DocumentType;
