/* eslint-disable no-useless-constructor */
import React, {Component} from 'react'
import { Modal } from 'react-bootstrap';
import { trls } from '../../factories/translate';

class HelpTextModal extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
    }
    render(){
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                onShow={this.onShow}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop= "static"
                centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {trls('Help_Text')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>{this.props.HelpTextTitle}</h5>
                <p>{this.props.HelpTextDescription}</p>
            </Modal.Body>
            </Modal>
        );
    }
}
export default HelpTextModal;