/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import { trls } from "../../factories/translate";
import { Row, Col, Button, Form } from "react-bootstrap";
import SessionManager from "../../factories/session_manage";
import API from "../../factories/api";
import Axios from "axios";
import { Link } from "react-router-dom";
import $ from "jquery";
import { BallBeat } from "react-pure-loaders";
import "react-datepicker/dist/react-datepicker.css";
import "datatables.net";
import history from "../../history";
import * as Common from "../../factories/common";
import Filtercomponent from "../../components/filtercomponent";
import HelpTextModal from "../Dashboard/HelpText";
import TraceModal from "./trackTrace";
import { Document, Page } from "react-pdf";
import Pagination from "../../components/pagination_default20";

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({});

class Deliveriesmanage extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      deliveriesData: [],
      originFilterData: [],
      filterColunm: [
        { label: "ItemCode", value: "ItemCode", type: "text", show: true },
        {
          label: "Collection",
          value: "dbsCollection",
          type: "text",
          show: true,
        },
        { label: "Quantity", value: "Quantity", type: "text", show: true },
        {
          label: "Reference_Header",
          value: "NumAtCard",
          type: "text",
          show: true,
        },
        { label: "Commission", value: "uDbsCustref", type: "text", show: true },
        { label: "Sent", value: "DocDueDate", type: "date", show: true },
        { label: "Currency", value: "Currency", type: "text", show: true },
        { label: "Price", value: "DocDueDate", type: "text", show: true },
        { label: "OrderNumber", value: "DocNum", type: "text", show: true },
        { label: "Order_Date", value: "DocDueDate", type: "date", show: true },
        { label: "Batch", value: "BatchNumbers", type: "text", show: true },
        {
          label: "Ordertype",
          value: "uDbsOrderType",
          type: "text",
          show: true,
        },
        { label: "TrackAndTrace", value: "Action", type: "text", show: true },
      ],
      HelpTextModal: false,
      pdf: "",
      recordNum: 0,
      traceShow: false,
      traceData: [],
      searchText: ''
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    let obj = this;
    $("#search").on("keyup", function (event) {
      if (event.keyCode === 13 || event.key === "Enter") {
        obj.setState({ searchText: this.value }, () => {
          obj.setState({searchText: this.value})
          obj.getDeliveriesData(20, 1, this.value);
        });
      }
    });
    this.getDeliveriesData(20, 1, '');
  }

  getHelpText = () => {
    var pageName = "Deliveries";
    var headers = SessionManager.shared().getAuthorizationPdfHeader();
    var lang = localStorage.getItem("eijf_lang");
    Axios.get(API.GetHelpTextByName + pageName, headers)
      .then((result) => {
        let helpURL = "";
        if (lang === "Dutch") {
          helpURL = result.data.contentDutch;
        } else if (lang === "English") {
          helpURL = result.data.contentEnglish;
        } else if (lang === "French") {
          helpURL = result.data.contentFrench;
        } else {
          helpURL = result.data.contentGerman;
        }
        const a = document.createElement("a");
        a.setAttribute("href", helpURL);
        a.setAttribute("target", "_blank");
        a.click();
      })
      .catch((err) => {
        // if(err.response.status===401){
        //     history.push('/login')
        // }
      });
  };

  getDeliveriesData = (pageSize, pageNumber, searchText) => {
    this._isMounted = true;
    this.setState({ loading: true, recordNum: 0 });
    var headers = SessionManager.shared().getAuthorizationHeader();
    var params = {
        skip: pageSize * (pageNumber - 1),
        quickSearch: searchText,
        shipToCode: localStorage.getItem('eijf_shippingAddress'),
    };
    Axios.post(API.GetDeliveryLines, params, headers)
      .then((result) => {
        if (this._isMounted) {
          this.setState({ loading: false });
          let deliveriesDataList = result.data.value;
          if (!deliveriesDataList.length) {
            this.setState({ deliveriesData: [], loading: false, recordNum: 0 });
          }
          deliveriesDataList.sort(function (a, b) {
            return new Date(b["Delivery Date"]) - new Date(a["Delivery Date"]);
          });
          this.setState({
            deliveriesData: deliveriesDataList,
            originFilterData: deliveriesDataList,
            recordNum: result.data["@odata.count"],
          });
          // $("#deliver-table").dataTable().fnDestroy();
          // var table = $("#deliver-table").DataTable({
          //   language: {
          //     lengthMenu: trls("Show") + " _MENU_ " + trls("Result_on_page"),
          //     zeroRecords: "Nothing found - sorry",
          //     info:
          //       trls("Show_page") +
          //       " _PAGE_ " +
          //       trls("Results_of") +
          //       " _PAGES_",
          //     infoEmpty: "No records available",
          //     infoFiltered: "(filtered from _MAX_ total records)",
          //     search: trls("Search"),
          //     paginate: {
          //       previous: trls("Previous"),
          //       next: trls("Next"),
          //     },
          //   },
          //   dom: 't<"bottom-datatable" lip>',
          //   bSort: false,
          //   bInfo: false,
          //   bPaginate: false,
          // });
        }
      })
      .catch((err) => {
        if (err && err.response && err.response.status) {
          if (err.response.status === 401) {
            history.push("/login");
          }
        }
      });
  };

  removeColumn = (value) => {
    let filterColunm = this.state.filterColunm;
    filterColunm = filterColunm.filter(function (item, key) {
      if (item.label === value) {
        item.show = false;
      }
      return item;
    });
    this.setState({ filterColunm: filterColunm });
  };

  showColumn = (value) => {
    let filterColum = this.state.filterColunm;
    filterColum = filterColum.filter((item, key) => item.label === value);
    return filterColum[0].show;
  };

  showDeliveryDetail = (docNumber, trackAndTrace) => {
    history.push({
      pathname: "/delivery-detail/" + docNumber,
      state: { id: docNumber, newSubmit: true, trackAndTrace: trackAndTrace },
    });
  };

  // filter module
  filterOptionData = (filterOption) => {
    this.setState({ loading: true });
    const { originFilterData } = this.state;
    let dataA = [];
    dataA = Common.filterData(filterOption, this.state.originFilterData);
    setTimeout(() => {
      if (!filterOption.length) {
        dataA = null;
        this.setState({ deliveriesData: originFilterData, loading: false });
      } else {
        this.setState({ deliveriesData: dataA, loading: false });
      }
      $(".filter").on("keyup", function () {
        table.search(this.value).draw();
      });
      $("#deliver-table").dataTable().fnDestroy();
      var table = $("#deliver-table").DataTable({
        language: {
          lengthMenu: trls("Show") + " _MENU_ " + trls("Result_on_page"),
          zeroRecords: "Nothing found - sorry",
          info:
            trls("Show_page") + " _PAGE_ " + trls("Results_of") + " _PAGES_",
          infoEmpty: "No records available",
          infoFiltered: "(filtered from _MAX_ total records)",
          search: trls("Search"),
          paginate: {
            first: '<i class="fa fa-angle-double-left" ></i> First',
            previous: '<i class="fa fa-angle-double-left" ></i> Previous',
            next: 'Next <i class="fa fa-angle-double-right" ></i>',
            last: 'Last <i class="fa fa-angle-double-right" ></i>',
          },
        },
        dom: 't<"bottom-datatable" lip>',
        bSort: false,
      });
    });
  };

  changeFilter = () => {
    if (this.state.filterFlag) {
      this.setState({ filterFlag: false });
    } else {
      this.setState({ filterFlag: true });
    }
  };
  // filter module
  getFileDownLoad = (data) => {
    this.setState({ pageLodingFlag: true });
    this._isMounted = true;
    let params = {
      objectId: data["PDFObjectId"],
      keyValue: data["PDFKeyValue"],
    };
    var headers = SessionManager.shared().getAuthorizationHeader();
    Axios.post(API.GetDownloadFile, params, headers)
      .then((result) => {
        if (result.data.pdf) {
          this.setState({ pageLodingFlag: false });
          this.downloadWithName(
            "data:application/octet-stream;charset=utf-16le;base64," +
              result.data.pdf,
            data["Item"] + ".pdf"
          );
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          history.push("/login");
        }
      });
  };

  downloadWithName = (uri, name) => {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.click();
  };

  goToTrack = (data, deliveriesData) => {
    let tem_array = [];
    deliveriesData.map((el, index) => {
      if (
        el.Ordernummer === data.Ordernummer &&
        el.Leveringnummer === data.Leveringnummer
      ) {
        let tem_el = tem_array.find(
          (item) => item.TrackAndTrace === el.TrackAndTrace
        );
        if (!tem_el) {
          tem_array.push(el);
        }
      }
      return el;
    });
    if (tem_array.length > 1) {
      this.setState({ traceData: tem_array, traceShow: true });
    } else {
      window.open(data["TrackAndTrace"], "_blank");
    }
  };

  render() {
    const { filterColunm, deliveriesData, Partial, traceData, traceShow, searchText } =
      this.state;
    // let deliveryDataList = []
    // deliveriesData.map((el, index) => {
    //     let tem_el = deliveryDataList.find((item) => item.DocNum===el.DocNum && item.LineNum === el.LineNum)
    //     if(!tem_el) {
    //         deliveryDataList.push(el)
    //     }
    //     return el
    // })

    let filterData = [
      { label: trls("ItemCode"), value: "Item", type: "text", show: true },
      {
        label: trls("Collection"),
        value: "Collection",
        type: "text",
        show: true,
      },
      { label: trls("Quantity"), value: "Number", type: "text", show: true },
      {
        label: trls("Reference_Header"),
        value: "Reference Header",
        type: "text",
        show: true,
      },
      {
        label: trls("Commission"),
        value: "Reference Line",
        type: "text",
        show: true,
      },
      { label: trls("Sent"), value: "Delivery Date", type: "date", show: true },
      { label: trls("Currency"), value: "Currency", type: "text", show: true },
      { label: trls("Price"), value: "Price", type: "text", show: true },
      {
        label: trls("Order Number"),
        value: "Order Number",
        type: "",
        show: true,
      },
      {
        label: trls("Order_Date"),
        value: "Orderdate",
        type: "date",
        show: true,
      },
      { label: trls("Batch"), value: "Batch", type: "text", show: true },
      {
        label: trls("Ordertype"),
        value: "Order Type",
        type: "text",
        show: true,
      },
      // {"label": trls('Status delivery'), "value": "Status", "type": 'text', "show": true},
    ];
    return (
      <div className="order_div">
        <div className="content__header content__header--with-line">
          <div id="google_translate_element"></div>
          <h2 className="title">{trls("Deliveries")}</h2>
        </div>
        <div className="orders">
          <Row>
            <Col sm={6}>
              {/* <Button variant="primary" onClick={()=>this.addUser()}><i className="fas fa-plus add-icon"></i>{trls('Add_order')}</Button>  */}
            </Col>
            <Col sm={6} className="has-search">
              <div style={{ display: "flex", float: "right" }}>
                <Button
                  variant="primary"
                  className="float-right"
                  onClick={this.getHelpText}
                >
                  <i className="fa fa-info-circle" aria-hidden="true"></i>
                </Button>
                {/* <Button style={{marginLeft: 20}} variant="light" onClick={()=>this.changeFilter()}><i className="fas fa-filter add-icon"></i>{trls('Filter')}</Button> */}
                <div style={{ marginLeft: 20 }}>
                  <span className="fa fa-search form-control-feedback"></span>
                  <Form.Control
                    id="search"
                    className="form-control filter"
                    type="text"
                    name="number"
                    placeholder={trls("Quick_search")}
                  />
                </div>
              </div>
            </Col>
            {filterColunm.length && (
              <Filtercomponent
                onHide={() => this.setState({ filterFlag: false })}
                filterData={filterData}
                onFilterData={(filterOption) =>
                  this.filterOptionData(filterOption)
                }
                showFlag={this.state.filterFlag}
              />
            )}
          </Row>
          <div className="table-responsive credit-history">
            <table
              id="deliver-table"
              className="place-and-orders__table table"
              width="100%"
            >
              <thead>
                <tr>
                  {filterColunm.map((item, key) => (
                    <th
                      className={!item.show ? "filter-show__hide" : ""}
                      key={key}
                    >
                      {trls(item.label) ? trls(item.label) : ""}
                    </th>
                  ))}
                </tr>
              </thead>
              {deliveriesData && !this.state.loading && (
                <tbody>
                  {deliveriesData.map((data, i) => (
                    <tr id={i} key={i}>
                      <td
                        className={
                          !this.showColumn(filterColunm[0].label)
                            ? "filter-show__hide"
                            : ""
                        }
                      >
                        {data["Artikel"]}
                      </td>
                      <td
                        className={
                          !this.showColumn(filterColunm[1].label)
                            ? "filter-show__hide"
                            : ""
                        }
                      >
                        {data["Collectie"]}
                      </td>
                      <td
                        className={
                          !this.showColumn(filterColunm[2].label)
                            ? "filter-show__hide"
                            : ""
                        }
                      >
                        {data["Aantal"]}
                      </td>
                      <td
                        className={
                          !this.showColumn(filterColunm[3].label)
                            ? "filter-show__hide"
                            : ""
                        }
                      >
                        {data["Referentie"]}
                      </td>
                      <td
                        className={
                          !this.showColumn(filterColunm[4].label)
                            ? "filter-show__hide"
                            : ""
                        }
                      >
                        {data["Commissie"]}
                      </td>
                      <td
                        className={
                          !this.showColumn(filterColunm[5].label)
                            ? "filter-show__hide"
                            : ""
                        }
                      >
                        {Common.formatDate(data["Verstuurd"])}
                      </td>
                      <td
                        className={
                          !this.showColumn(filterColunm[6].label)
                            ? "filter-show__hide"
                            : ""
                        }
                      >
                        {data["Valuta"]}
                      </td>
                      <td
                        className={
                          !this.showColumn(filterColunm[7].label)
                            ? "filter-show__hide"
                            : ""
                        }
                      >
                        {data["Prijs"]}
                      </td>
                      <td
                        className={
                          !this.showColumn(filterColunm[8].label)
                            ? "filter-show__hide"
                            : ""
                        }
                      >
                        {data["Ordernummer"]}
                      </td>
                      <td
                        className={
                          !this.showColumn(filterColunm[9].label)
                            ? "filter-show__hide"
                            : ""
                        }
                      >
                        {Common.formatDate(data["Orderdatum"])}
                      </td>
                      <td
                        className={
                          !this.showColumn(filterColunm[10].label)
                            ? "filter-show__hide"
                            : ""
                        }
                      >
                        {data["Partij"]}
                      </td>
                      <td
                        className={
                          !this.showColumn(filterColunm[11].label)
                            ? "filter-show__hide"
                            : ""
                        }
                      >
                        {data["Ordertype"]}
                      </td>
                      <td
                        className={
                          !this.showColumn(filterColunm[12].label)
                            ? "filter-show__hide"
                            : ""
                        }
                      >
                        <Row
                          style={{ justifyContent: "space-around", width: 70 }}
                        >
                          <i
                            className="fas fa-map-marker-alt"
                            style={{
                              cursor: "pointer",
                              pointerEvents: data["TrackAndTrace"]
                                ? "auto"
                                : "none",
                              color: data["TrackAndTrace"]
                                ? "#585858"
                                : "#bbb8b8",
                            }}
                            onClick={() => this.goToTrack(data, deliveriesData)}
                          >
                            <span className="action-title"></span>
                          </i>
                        </Row>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            <Pagination
              recordNum={this.state.recordNum}
              getData={(pageSize, page) =>
                this.getDeliveriesData(pageSize, page, searchText)
              }
            />
            {this.state.loading && (
              <div
                className="col-md-4 offset-md-4 col-xs-12 loading"
                style={{ textAlign: "center" }}
              >
                <BallBeat color={"#222A42"} loading={this.state.loading} />
              </div>
            )}
          </div>
          <HelpTextModal
            show={this.state.HelpTextModal}
            onHide={() => this.setState({ HelpTextModal: false })}
            HelpTextTitle={this.state.DeliveriesHelpTextTitle}
            HelpTextDescription={this.state.HelpTextDescription}
          />
        </div>
        <TraceModal
          show={traceShow}
          traceData={traceData}
          onHide={() => this.setState({ traceShow: false, traceData: [] })}
        />
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Deliveriesmanage);
