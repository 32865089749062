/* eslint-disable no-unused-vars */
import React, {Component} from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { trls } from '../../factories/translate';
import "react-datepicker/dist/react-datepicker.css";
import API from '../../factories/api'
import * as Auth from '../../factories/auth'
import $ from 'jquery';
import Pageloadspiiner from '../../components/page_load_spinner';
import './return_style.css'

const mapStateToProps = state => ({ 
    ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({

});

class AddReturnsForm extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {  
            itemData: [],
            itemFlag: false,
            pageLodingFlag: false,
            itemEnable: false
        };
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    
    componentDidMount() {
        $(document).on('keypress', ':input', function (e) {
            if (e.which === 13) e.preventDefault();
        });
        $("input[name='itemCode']").focus();
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const { editOrderRowFlag } = this.props;
        const clientFormData = new FormData(event.target);
        const data = {};
        for (let key of clientFormData.keys()) {
            data[key] = clientFormData.get(key);
        }
        if(!editOrderRowFlag) {
            this.props.onAddOrderRow(data); 
        }else {
            this.props.updateOrderRowLine(data);
        }
        this.onHide();

    }

    getItemData = () => {
        this._isMounted = true;
        let itemCodeData = '';
        const { itemCode } = this.state;
        const { setItemCodeFlag, itemData } = this.props;
        if(setItemCodeFlag) {
            itemCodeData = itemData.ItemCode;
        }else{
            itemCodeData = itemCode;
        }
        let code = 0;
        if(itemCodeData!==""){
            code = itemCodeData;
        }
        this.setState({pageLodingFlag: true})
        var settings = {
            "url": API.GetSampleItemByItemCode + code,
            "method": "GET",
            "headers": {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+Auth.getUserToken(),
        }
        }
        $.ajax(settings).done(function (response) {
        })
        .then(response => {
            this.setState({itemData: response, itemFlag: true, pageLodingFlag: false, itemEnable: true}, ()=>{
            });
        })
        .catch(err => {
            this.setState({pageLodingFlag: false, itemEnable: false})
            $(".fade").attr("tabindex","disable");
            this.props.searchItemForm(itemCodeData);
        });
    }

    showSearchItem = () => {
        let itemCodeData = '';
        const { itemCode } = this.state;
        const { setItemCodeFlag, itemData } = this.props;
        if(setItemCodeFlag) {
            itemCodeData = itemData.ItemCode;
        }else{
            itemCodeData = itemCode;
        }
        this.props.searchItemForm(itemCodeData);
        $(".fade").attr("tabindex","disable");
    }

    changeItemCode = (e) => {
        this.setState({itemCode: e.target.value});
        this.props.onSetItemCodeFlag();
    }

    onHide = () => {
        this.setState({
            itemCode: '',
            itemData: [],
            itemFlag: false,
            pageLodingFlag: false,
        })
        this.props.onHide();
    }

    onShow = () => {
        const { editOrderRowData } = this.props;
        this.setState({
            itemCode: editOrderRowData.itemCode ? editOrderRowData.itemCode : '',
            commission: editOrderRowData.commission ? editOrderRowData.commission : '',
            comments: editOrderRowData.comments ? editOrderRowData.comments : '',
        })
    }

    render(){
        const { itemFlag, itemCode, pageLodingFlag, itemEnable, commission, comments, quantity } = this.state;
        const {  itemData, setItemCodeFlag, itemSearchformFlag } = this.props;
        return (
            <Modal
                show={this.props.show}
                onHide={()=>this.onHide()}
                onShow={()=>this.onShow()}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop= "static"
                tabIndex={"disable"}
                centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {trls('Return_Item')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit = { this.handleSubmit }>
                    <Form.Group as={Row} controlId="itemCode">
                        <Col className="product-text">
                            <Form.Control type="text" name="itemCode" autoComplete="off" autoFocus required disabled={itemSearchformFlag} value={ setItemCodeFlag ? itemData.ItemCode : itemCode} className={!itemEnable ? "place-order__product-code active" : 'place-order__product-code'} onChange={(e)=>this.changeItemCode(e)} onBlur={this.getItemData}/>
                            <label className="placeholder-label">{trls('Product_code')}</label>
                            <i className="fas fa-search place-an-order__loop" aria-hidden="true" onClick={()=>this.showSearchItem()}></i>
                        </Col>
                    </Form.Group>
                   
                    <Form.Group as={Row} controlId="quantity">
                        <Col className="product-text">
                            <Form.Control type="text" name="quantity" value={ quantity} onChange={(e)=>this.setState({quantity: e.target.value})}/>
                            <label className="placeholder-label">{trls('Quantity')}</label>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="commission">
                        <Col className="product-text">
                            <Form.Control type="text" name="commission"  value={ commission} onChange={(e)=>this.setState({commission: e.target.value})}/>
                            <label className="placeholder-label">{trls('Commission')}</label>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="comments" className="mb-5">
                        <Col className="product-text">
                            <Form.Control as="textarea" name="comments" className="comments" rows="3"  value={ comments} required onChange={(e)=>this.setState({comments: e.target.value})}/>
                            <label className="placeholder-label">{trls('Comments')}</label>
                        </Col>
                    </Form.Group>
                    {/* <Form.Group as={Row} controlId="quantity" className="mb-5">
                        <Col className="product-text">
                            <Form.Control as="textarea" name="quantity" className="comments" rows="3"  value={ quantity} onChange={(e)=>this.setState({quantity: e.target.value})}/>
                            <label className="placeholder-label">{trls('Quantity')}</label>
                        </Col>
                    </Form.Group> */}
                    <Form.Group style={{textAlign:"center"}} className="mt-2">
                        <Button type="Submit">{trls('Submit')}</Button>
                    </Form.Group>
                </Form>    
            </Modal.Body>
            <Pageloadspiiner loading = {pageLodingFlag} text={''}/>
            </Modal>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddReturnsForm);