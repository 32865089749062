/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, {Component} from 'react'
import { connect } from 'react-redux';
import { trls } from '../../factories/translate';
import { Row, Col, Button, Form } from 'react-bootstrap';
import SessionManager from '../../factories/session_manage';
import API from '../../factories/api'
import Axios from 'axios';
import $ from 'jquery';
import { BallBeat } from 'react-pure-loaders';
import "react-datepicker/dist/react-datepicker.css";
import 'datatables.net';
import history from '../../history';
import Pageloadspiiner from '../../components/page_load_spinner';
import * as Common from '../../factories/common';
import FilterErrorLogs from './filter_error_logs';


const mapStateToProps = state => ({ 
    ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({

});

class ErrorLogs extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {  
            loading: false,
            originFilterData: [],
            ErrorLogsData:[],
            LevelData:[],
            EventData:[],
            filterColunm: [
                {"label": 'id', "value": "id", "type": 'text', "show": true},
                {"label": 'logLevel', "value": "logLevel", "type": 'text', "show": true},
                {"label": 'logEvent', "value": "logEvent", "type": 'text', "show": true},
                {"label": 'dateTime', "value": "dateTime", "type": 'date', "show": true},
                {"label": 'invokedByUserId', "value": "invokedByUserId", "type": 'text', "show": true},
                {"label": 'referenceId', "value": "referenceId", "type": 'text', "show": true},
                {"label": 'Message', "value": "message", "type": 'text', "show": true},
            ],
            refreshFlag:false,
            loadingFlage:false
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this.getErrorLogsData();
        this.getLevelData();
        this.getEventData();
    }

    getErrorLogsData = (data) => {
        this._isMounted = true;
        this.setState({loading:true})
        var headers = SessionManager.shared().getAuthorizationHeader();
        Axios.get(API.GetAllErrorLogs, headers)
        .then(result => {
            
            if(this._isMounted){
                var ErrorLogsData = result.data
                this.ErrorLogsTable(result.data)
                this.setState({loading:false,loadingFlage: false});
            }
            
        })
        .catch(err => {
            // if(err.response.status===401){
            //     history.push('/login')
            // }
        });
    }

    getLevelData = (data) => {
        this._isMounted = true;
        this.setState({loading:true})
        var headers = SessionManager.shared().getAuthorizationHeader();
        Axios.get(API.GetLogsLevel, headers)
        .then(result => {
            if(this._isMounted){
                var ResponseData = result.data,LevelData=[],item={};
                ResponseData.map((data)=>{
                    item={}
                    if(data){
                        item.value=data;
                        item.label=data;
                    }
                    LevelData.push(item)
                })
                this.setState({LevelData:LevelData});
            }
        })
        .catch(err => {
            // if(err.response.status===401){
            //     history.push('/login')
            // }
        });
    }

    getEventData = (data) => {
        this._isMounted = true;
        this.setState({loading:true})
        var headers = SessionManager.shared().getAuthorizationHeader();
        Axios.get(API.GetLogsEvent, headers)
        .then(result => {
            
            if(this._isMounted){
                var ResponseData = result.data,EventData=[],item={};
                ResponseData.map((data)=>{
                    item={}
                    if(data){
                        item.value=data;
                        item.label=data;
                    }
                    EventData.push(item)
                })
                this.setState({EventData:EventData});
            }            
        })
        .catch(err => {
            if(err.response.status===401){
                history.push('/login')
            }
        });
    }

    // filter module
    filterOptionData = (filterOptionData) =>{
        this.setState({loadingFlage: true})
        var headers = SessionManager.shared().getAuthorizationHeader();
        var filter_url, base_url = API.GetAllErrorLogs;
        
        if(filterOptionData.length != 0){
            if(filterOptionData.length==1){
                var filterOption = filterOptionData[0].filterOption
                switch (filterOption) {
                    case 'id':
                        filter_url = base_url +'/' + filterOptionData[0].value
                        break;
                    case 'logLevel':
                        filter_url = base_url +'/level/' + filterOptionData[0].value
                        break;
                    case 'logEvent':
                        filter_url = base_url +'/event/' + filterOptionData[0].value
                        break;
                    case 'dateTime':
                        var startDate = new Date(filterOptionData[0].startDate).toISOString()
                        var endDate = new Date(filterOptionData[0].endDate).toISOString()
                        filter_url = base_url + '/bydate?startDate='+startDate+'&endDate='+endDate
                        break;

                    case 'invokedByUserId':
                        filter_url = base_url +'/user/' + filterOptionData[0].value
                        break;
                    case 'referenceId':
                        filter_url = base_url +'/referenceId/' + filterOptionData[0].value
                        break;
                    default:
                        console.log("error")
                }
            }
            else{
                filter_url = base_url+'/byquery?'
                filterOptionData.map((data,i)=> {
                    
                    switch (data.filterOption) {
                        case 'logLevel':
                            filter_url += 'level=' + data.value + '&'
                            break;
                        case 'logEvent':
                            filter_url += 'logEvent=' + data.value + '&'
                            break;
                        case 'dateTime':
                            var startDate = new Date(data.startDate).toISOString()
                            var endDate = new Date(data.endDate).toISOString()
                            filter_url +=  'startDate='+startDate+'&endDate='+endDate + '&'
                            break;
    
                        case 'invokedByUserId':
                            filter_url += 'userId=' + data.value + '&'
                            break;
                        default:
                            console.log("error")
                    }
                })
            }

            Axios.get(filter_url, headers)
            .then(result => {
            this.setState({loadingFlage: false})

                if((filterOptionData.length == 1) && (filterOptionData[0].filterOption === "id" || filterOptionData[0].filterOption == "referenceId")){
                    var data = result.data
                    var table = $('#errorLog-table').DataTable(
                        {
                            retrieve: true,
                            "language": {
                                "lengthMenu": trls("Show")+" _MENU_ "+trls("Result_on_page"),
                                "zeroRecords": "Nothing found - sorry",
                                "info": trls("Show_page")+" _PAGE_ "+trls('Results_of')+" _PAGES_",
                                "infoEmpty": "No records available",
                                "infoFiltered": "(filtered from _MAX_ total records)",
                                "search": trls('Search'),
                                "paginate": {
                                    "previous": trls('Previous'),
                                    "next": trls('Next')
                                }
                            },
                            // "columnDefs": [{
                            //     "targets": [0, 1, 2, 3, 4, 5, 6, 7],
                            //     "orderable": false
                            // }],
                            "dom": 't<"bottom-datatable" lip>'
                        }
                    );
                    table.clear().draw();
                        table.row.add( [
                            data.id ,
                            data.logLevel ,
                            data.logEvent ,
                            Common.formatDate(data.dateTime) ,
                            data.dateTime,
                            data.invokedByUserId ,
                            data.referenceId,
                            data.message ,
                        ]
                        )            
                    table.draw();
                }
                else{
                    this.ErrorLogsTable(result.data)
                }
                
            })
            .catch(err => {
                // if(err.response.status===401){
                //     history.push('/login')
                // }
            });
        }
        else{
            this.getErrorLogsData();
            // this.setState({loadingFlage: false})
        }
    }

    changeFilter = () => {
        if(this.state.filterFlag){
            this.setState({filterFlag: false})
        }else{
            this.setState({filterFlag: true})
        }
    }

    ErrorLogsTable = (ErrorLogsData) => {
        var table = $('#errorLog-table').DataTable(
            {
                retrieve: true,
                "language": {
                    "lengthMenu": trls("Show")+" _MENU_ "+trls("Result_on_page"),
                    "zeroRecords": "Nothing found - sorry",
                    "info": trls("Show_page")+" _PAGE_ "+trls('Results_of')+" _PAGES_",
                    "infoEmpty": "No records available",
                    "infoFiltered": "(filtered from _MAX_ total records)",
                    "search": trls('Search'),
                    "paginate": {
                        "previous": trls('Previous'),
                        "next": trls('Next')
                    }
                },
                // "columnDefs": [{
                //     "targets": [0, 1, 2, 3, 4, 5, 6, 7],
                //     "orderable": false
                // }],
                "dom": 't<"bottom-datatable" lip>'
            }
        );
        table.clear().draw();
        $.each( ErrorLogsData, function( i,data ) {
            table.row.add( [
                data.id ,
                data.logLevel ,
                data.logEvent ,
                // Common.formatDate(data.dateTime) ,
                data.dateTime,
                data.invokedByUserId ,
                data.referenceId,
                data.message ,
            ]
            )
        });
        table.draw();
    }

    // resetTable = () => {
    //     this.getErrorLogsData()
    //     this.setState({refreshFlag:true})
    // }

    
    render(){   
        const {filterColunm, loadingFlage} = this.state;
        let fitlerData = [
            {"label": trls('id'), "value": "id", "type": 'text', "show": true},
            {"label": trls('logLevel'), "value": "logLevel", "type": 'text', "show": true},
            {"label": trls('logEvent'), "value": "logEvent", "type": 'text', "show": true},
            {"label": trls('dateTime'), "value": "dateTime", "type": 'date', "show": true},
            {"label": trls('invokedByUserId'), "value": "invokedByUserId", "type": 'text', "show": true},
            {"label": trls('referenceId'), "value": "referenceId", "type": 'text', "show": true},
        ]
        let fitlerData1 = [
            {"label": trls('logLevel'), "value": "logLevel", "type": 'text', "show": true},
            {"label": trls('logEvent'), "value": "logEvent", "type": 'text', "show": true},
            {"label": trls('dateTime'), "value": "dateTime", "type": 'date', "show": true},
            {"label": trls('invokedByUserId'), "value": "invokedByUserId", "type": 'text', "show": true},
        ]

        return (
            <div className="order_div">
                <div className="content__header content__header--with-line">
                    <div id="google_translate_element"></div>
                    <h2 className="title">{trls("ErrorLogs")}</h2>
                </div>
                <div className="orders">
                    <Row>
                        <Col sm={6} className="has-search">
                            <div style={{display: 'flex', float: 'right'}}>
                                {/* <Button variant="light" className="mr-2" onClick={()=>this.resetTable()}><i className="fa fa-undo add-icon"></i>Refresh</Button> */}
                                <Button variant="light" onClick={()=>this.changeFilter()}><i className="fas fa-filter add-icon"></i>{trls('Filter')}</Button>
                                <div style={{marginLeft: 20}}>
                                    <span className="fa fa-search form-control-feedback"></span>
                                    <Form.Control className="form-control fitler" type="text" name="number"placeholder={trls("Quick_search")}/>
                                </div>
                            </div>
                        </Col>
                        {filterColunm.length&&(
                            <FilterErrorLogs
                                onHide={()=>this.setState({filterFlag: false})}
                                filterData={fitlerData}
                                onFilterData={(filterOption)=>this.filterOptionData(filterOption)}
                                showFlag={this.state.filterFlag}
                                EventData = {this.state.EventData}
                                LevelData = {this.state.LevelData}
                                refreshFlag = {this.state.refreshFlag}
                                fitlerData1 = {fitlerData1}
                            />
                        )}
                    </Row>
                    <div className="table-responsive credit-history">
                        <table id="errorLog-table" className="place-and-orders__table table" width="100%">
                        <thead>
                            <tr>
                                {filterColunm.map((item, key)=>(
                                    <th className={!item.show ? "filter-show__hide" : ''} key={key}>
                                        {/* {item.label} */}
                                        {trls(item.label) ? trls(item.label) : '' }

                                    </th>
                                    )
                                )}
                            </tr>
                        </thead>
                        <tbody>

                        </tbody>
                    </table>
                        { this.state.loading&& (
                            <div className="col-md-4 offset-md-4 col-xs-12 loading" style={{textAlign:"center"}}>
                                <BallBeat
                                    color={'#222A42'}
                                    loading={this.state.loading}
                                />
                            </div>
                        )}
                    </div>
                    <Pageloadspiiner loading = {loadingFlage} text={''}/>
                </div>
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ErrorLogs);