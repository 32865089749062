import React from 'react';
import './slideshow.css';
class Slideshow extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            current: 0
        };
    }

    selectItem = (index) => {
        this.setState({current:index})
    }

    render() {
        return (
        <div className="slideshow">
            <div className="slideshow-images">
                {
                   this.props.images.length >0 && this.props.images.map((image,index)=>(
                        <div className={`slideshow-image ${index === this.state.current ? "selected" : ""}`} key={index}>
                            <img alt={image.id} src={image.documentUrl}/>
                        </div>
                   ))
                }
            </div>
            <div className="slideshow-nav">
                {this.props.images.length > 1 && this.props.images.map((dot,dotIndex)=>(
                    <span onClick={()=>{this.selectItem(dotIndex)}} className={`dot ${dotIndex === this.state.current ? "selected" : ""}`} key={dotIndex}/>
                ))}
            </div>
        </div>
        );
    }
}
export default Slideshow;
