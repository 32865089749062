/* eslint-disable no-unused-vars */
import React, {Component} from 'react'
import { Row, Col, Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import $ from 'jquery';
import SessionManager from '../../factories/session_manage';
import API from '../../factories/api'
import { BallBeat } from 'react-pure-loaders';
import { trls } from '../../factories/translate';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'datatables.net';
import history from '../../history';
import AddNotification from './add_notification';
import Axios from 'axios';
import Pageloadspiiner from '../../components/page_load_spinner';
import UpdateTermOfServiceUrl from './update_termOfServiceUrl'
import Sweetalert from 'sweetalert';
import Select from 'react-select';
import Switch from "react-switch";
import UpdateMaintenance from './UpdateMaintenance';
import * as Auth from '../../factories/auth'


class Settings extends Component {
    _isMounted = false
    constructor(props) {
        super(props);
        this.state = {
           
            notificationColunm: [
                {"label": 'NotificationEmail', "value": "NotificationEmail", "type": 'text', "show": true},
                {"label": 'Delete', "value": "action", "type": 'text', "show": true},
            ],
            termsOfServiceColunm: [
                {"label": 'TermOfServiceKey', "value": "termOfServiceKey", "type": 'text', "show": true},
                {"label": 'TermOfServiceUrl', "value": "termOfServiceUrl", "type": 'text', "show": true},
                {"label": 'Edit', "value": "action", "type": 'text', "show": true},
            ],
            AddNotificationModal: false,
            UpdateTermOfServiceUrlModal: false,
            pageSpiner:false,
            ExcludeProductgroupsValue: '',
            MaintenanceColunm: [ 
                {"label": 'Text English', "value": "contentEnglish", "type": 'text', "show": true},
                {"label": 'Text Dutch', "value": "contentDutch", "type": 'text', "show": true},
                {"label": 'Text German', "value": "contentGerman", "type": 'text', "show": true},
                {"label": 'Text French', "value": "contentFrench", "type": 'text', "show": true},
                {"label": 'Edit', "value": "action", "type": 'text', "show": true},
            ],
            MaintenanceData:{},
            MaintenanceMode : Auth.getUserInfo().MaintenanceMode === "true" ? true : false,
            productGroups: [],
            selectProductGroups: []
        }; 
    }

    componentDidMount() {
        this._isMounted=true;
        this.getNotifications();
        this.getTermsOfService();
        this.getExcludeProductgroupsList();
        this.getExcludeProductgroups();
        this.getMaintenance();
        
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    getNotifications (data) {    
        this._isMounted = true;
        var headers = SessionManager.shared().getAuthorizationHeader();
        
        this.setState({loading: true})

        Axios.get(API.GetNotifications, headers)
        .then(result => {
                if(this._isMounted){
                    this.setState({NotificationsData: result.data});
                    this.setState({loading:false})
                }
                $('#notification_table').dataTable().fnDestroy();
                var table = $('#notification_table').DataTable({
                    paging: true,
                    pageLength: 10,
                    "language": {
                        "lengthMenu": trls("Show")+" _MENU_ "+trls("Result_on_page"),
                        "zeroRecords": "Nothing found - sorry",
                        "info": trls("Show_page")+" _PAGE_ "+trls('Results_of')+" _PAGES_",
                        "infoEmpty": "No records available",
                        "infoFiltered": "(filtered from _MAX_ total records)",
                        "search": trls('Search'),
                        "paginate": {
                        "previous": trls('Previous'),
                        "next": trls('Next')
                        },
                        "searching": true,
                    },
                    "dom": 't<"bottom-datatable" lip>',
                });
        })
        .catch(err => {
            if(err.response.status===401){
                history.push('/login')
            }
        })
    }

    getTermsOfService (data) {    
        this._isMounted = true;
        
        var headers = SessionManager.shared().getAuthorizationHeader();
        
        this.setState({TermsOfloading: true})

        Axios.get(API.GetTermsOfService, headers)
        .then(result => {
                if(this._isMounted){
                    this.setState({termsOfService: result.data});
                    this.setState({TermsOfloading:false})
                }
        })
        .catch(err => {
            // if(err.response.status===401){
            //     history.push('/login')
            // }
        })
    }

    NotificationsDelete = (notificationEmail) => {
        // var params = {"id":id}
        var headers = SessionManager.shared().getAuthorizationHeader();
        confirmAlert({
            title: 'Confirm',
            message: trls('Confirm_Delete_Message'),
            buttons: [
              {
                label: trls('Delete'),
                onClick: () => {
                    Axios.delete(API.DeleteNotification+notificationEmail,headers)
                    .then(result => {
                        this.setState({loading:true})
                        this.getNotifications();               
                    })
                    .catch(err => {
                        // if(err.response.status===401){
                        //     history.push('/login')
                        // }
                    });
                }
              },
              {
                label: 'Cancel',
                onClick: () => {}
              }
            ]
        });
    }

    UpdateTermsOfService = (termOfServiceKey,termOfServiceUrl) => {
        this.setState({UpdateTermOfServiceUrlModal:true,termOfServiceKey:termOfServiceKey,termOfServiceUrl:termOfServiceUrl})
    }

    getExcludeProductgroups() {
        var headers = SessionManager.shared().getAuthorizationHeader();

        Axios.get(API.GetExcludeProductgroups, headers)
        .then(result => {
            this.setState({
                ExcludeProductgroupsValue: result.data.value
            })
        })
        .catch(err => {
            if(err.response.status===401){
                history.push('/login')
            }
        })
    }

    getExcludeProductgroupsList() {
        var headers = SessionManager.shared().getAuthorizationHeader();
        var params = {
            "additionalProp1": "string",
            "additionalProp2": "string",
            "additionalProp3": "string"
        }
        Axios.post(API.GetItemGroupList, params, headers)
        .then(result => {
            var groupTempList = []
            result.data.value.forEach(element => {
                groupTempList.push({"value":element.Number,"label":element.GroupName})
            });
            this.setState({productGroups: groupTempList});
            Axios.get(API.GetExcludeProductgroups, headers)
            .then(result => {
                var groupList = result.data.value.split(",");   
                var groupTemp = '';
                var tempExcludeProductgroups = [];
                groupList.forEach(element => {
                    groupTemp = groupTempList.filter((item, key)=>item.value===Number(element));
                    if(groupTemp) {
                        tempExcludeProductgroups.push({"value": groupTemp[0].value, "label": groupTemp[0].label});
                    }
                })
                this.setState({ selectProductGroups: tempExcludeProductgroups})
            })
            .catch(err => {
                if(err.response.status===401){
                    history.push('/login')
                }
            })
        })
        .catch(err => {
            if(err.response.status===401){
                history.push('/login')
            }
        })
    }

    changeProductGroups = (val) => {
        let tempProductGroups = []
        let tempExcludeProductgroups = '';
        val.forEach(element => {
            tempProductGroups.push(element)
            if(!tempExcludeProductgroups) {
                tempExcludeProductgroups += element.value;
            } else {
                tempExcludeProductgroups += "," + element.value;
            }
            
        });
        this.setState({selectProductGroups: tempProductGroups, ExcludeProductgroupsValue: tempExcludeProductgroups})
    }

    addExcludeProductgroups= (e) =>{
        e.preventDefault();
        e.stopPropagation();
        
        let excludeProductgroupsValue = this.state.ExcludeProductgroupsValue;
        var headers = SessionManager.shared().getAuthorizationHeader();
        let excludeProductgroupsParams = {'excludeProductgroupsValue':excludeProductgroupsValue}
 
        Axios.put(API.PutExcludeProductgroups + excludeProductgroupsValue, excludeProductgroupsParams , headers)
        .then(result => {
            Sweetalert(trls('ExcludeProductgroups_success'), {
                icon: "success",
            })
        })
        .catch(err => {
            if(err.response.status===401){
                history.push('/login')
            }
        })
        e.preventDefault();

    }

    getMaintenance = () => {
        var headers = SessionManager.shared().getAuthorizationHeader();

        Axios.get(API.GetMaintenance, headers)
        .then(result => {
            this.setState({
                MaintenanceData: result.data
            })
        })
        .catch(err => {
            if(err.response.status===401){
                history.push('/login')
            }
        })
    }

    UpdateMaintainanceMode = (MaintenanceMode) => {
        var headers = SessionManager.shared().getAuthorizationHeader();
        this.setState({MaintenanceMode})
        if(MaintenanceMode){
            Axios.put(API.PutMaintenanceOn,{},headers)
            .then(result => {
            })
            .catch(err=>{
            });
        }
        else{
            Axios.put(API.PutMaintenanceOff,{},headers)
            .then(result => {
            })
            .catch(err=>{
            });
        }

    }

    render() {
        const { NotificationsData, notificationColunm, termsOfService, termsOfServiceColunm,
             ExcludeProductgroupsValue, MaintenanceColunm, MaintenanceData, MaintenanceMode } = this.state;
        return (
            <div className="order_div">
                <div className="content__header content__header--with-line  pb-4">
                    <h1 className="title" style={{fontSize: 30}}><i className="fas fa-cog"></i> {trls('Settings')}</h1>
                </div>
            
            <div className="orders">
                <div className="notification-container">
                    <div className="content__header content__header--with-line  pb-2">
                        <h5 className="title">{trls("Notifications")}</h5>
                    </div>
                    <Row>
                        <Col sm={12}>
                            <div style={{display: 'flex', float: 'right'}}>
                                <div style={{marginLeft: 20}}>
                                <Button variant="primary" onClick={()=> this.setState({AddNotificationModal: true}) }><i className="fas fa-plus add-icon mr-2"></i>{trls('Add_Notifications_Email')}</Button>
                                </div>
                            </div>
                            
                        </Col>
                    </Row>
                    <div className="table-responsive credit-history">
                        <table id="notification_table" className="place-and-orders__table table mt-2" width="99%">
                            <thead>
                                <tr>
                                    {notificationColunm.map((item, key)=>(
                                        <th key={key}>
                                            <b>{item.label}</b>
                                        </th>
                                        )
                                    )}
                                </tr>
                            </thead>
                            { NotificationsData && !this.state.loading &&(<tbody >
                                {
                                    NotificationsData.map((data,i) =>(
                                        <tr id={data.id} key={i}>
                                            <td >{data.notificationEmail}</td>
                                            <td style={{cursor:'pointer'}}>
                                                <i className ="fa fa-trash" onClick={()=>this.NotificationsDelete(data.notificationEmail)}></i>
                                            </td>
                                        </tr>
                                ))
                                }
                            </tbody>)}
                        </table>

                        { this.state.loading&& (
                            <div className="col-md-4 offset-md-4 col-xs-12 loading" style={{textAlign:"center"}}>
                                <BallBeat
                                    color={'#222A42'}
                                    loading={this.state.loading}
                                />
                            </div>
                        )}

                    </div>
                </div>
                <hr/>

                <div className="term-of-service mt-5">
                    <div className="content__header content__header--with-line mt-0 py-4">
                        <h5 className="title">{trls("Terms_Of_Delivery")}</h5>
                    </div>
                    <div className="table-responsive credit-history">
                            <table id="termsOfService_table" className="place-and-orders__table table mt-2" width="99%">
                                <thead>
                                    <tr>
                                        {termsOfServiceColunm.map((item, key)=>(
                                            <th key={key}>
                                            <b>{item.label}</b>
                                            </th>
                                            )
                                        )}
                                    </tr>
                                </thead>
                                { termsOfService && !this.state.loading &&(<tbody >
                                    {
                                        termsOfService.map((data,i) =>(
                                            <tr id={data.id} key={i}>
                                                <td >{data.termOfServiceKey}</td>
                                                <td >{data.termOfServiceUrl}</td>
                                                <td style={{cursor:'pointer'}}>
                                                    <i className ="fa fa-edit" onClick={()=>this.UpdateTermsOfService(data.termOfServiceKey,data.termOfServiceUrl)}></i>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>)}
                            </table>

                            { this.state.TermsOfloading&& (
                                <div className="col-md-4 offset-md-4 col-xs-12 loading" style={{textAlign:"center"}}>
                                    <BallBeat
                                        color={'#222A42'}
                                        loading={this.state.TermsOfloading}
                                    />
                                </div>
                            )}

                    </div>
                </div>
                <hr/>

                <div className="excludeProductgroups-container">
                    <div className="content__header content__header--with-line mt-0 py-4">
                        <h5 className="title">{trls('ExcludeProductgroups')}</h5>
                    </div>
                    <Form className="container product-form" onSubmit={this.addExcludeProductgroups}>
                        <Form.Group as={Row} controlId="formexcludeProductgroups">
                            <Form.Label column sm="4" className="pl-0">
                                {trls('ExcludeProductgroups')}  
                            </Form.Label>
                            <Col sm="6" className="product-text">
                                <Select
                                    options={this.state.productGroups}
                                    isMulti
                                    onChange={val => this.changeProductGroups(val)}
                                    defaultValue = ''
                                    value = {this.state.selectProductGroups}
                                />
                                {/* <Form.Control type="text" name="excludeProductgroups" defaultValue={ExcludeProductgroupsValue} required placeholder= {trls('ExcludeProductgroups')} onChange = {(evt)=>this.setState({ExcludeProductgroupsValue: evt.target.value})} /> */}
                            </Col>
                            <Col sm="2">
                                <div className="d-flex justify-content-end">
                                    <Button type="submit" className="px-4 float-rigth">{trls("Add")}</Button>
                                </div>
                            </Col>

                        </Form.Group>
                    </Form>
                </div>
                <hr/>

                <div className="term-of-service mt-5 mmb-4">
                    <div className="content__header content__header--with-line mt-0 py-4">
                        <h5 className="title">{trls("Maintenance")}</h5>
                    </div>
                    <div className="d-flex">
                        <h4 className="pl-2">{trls("Maintenance_Mode")}: </h4>
                        <h4 className="ml-4 mr-2">Off</h4>
                        <Switch onChange={(checked) =>this.UpdateMaintainanceMode(checked)} checked={MaintenanceMode} />
                        <h4 className="ml-2">On</h4>
                    </div>
                    <h4 className="pl-2 mt-4">{trls("Maintenance_Text")}: </h4>
                    <div className="table-responsive credit-history">
                            <table id="termsOfService_table" className="place-and-orders__table table mt-2" width="99%">
                                <thead>
                                    <tr>
                                        {MaintenanceColunm.map((item, key)=>(
                                            <th key={key}>
                                            <b>{item.label}</b>
                                            </th>
                                            )
                                        )}
                                    </tr>
                                </thead>
                                { MaintenanceData && !this.state.loading &&(
                                <tbody >
                                    <tr >
                                        <td >{MaintenanceData.en}</td>
                                        <td >{MaintenanceData.de}</td>
                                        <td >{MaintenanceData.nl}</td>
                                        <td >{MaintenanceData.fr}</td>
                                        <td style={{cursor:'pointer'}}>
                                            <i className ="fa fa-edit" onClick={()=> this.setState({UpdateMaintenanceModal:true}) }></i>
                                        </td>
                                    </tr>
                                </tbody>
                                )}
                            </table>

                            { this.state.TermsOfloading&& (
                                <div className="col-md-4 offset-md-4 col-xs-12 loading" style={{textAlign:"center"}}>
                                    <BallBeat
                                        color={'#222A42'}
                                        loading={this.state.TermsOfloading}
                                    />
                                </div>
                            )}

                    </div>
                </div>
                
            </div>
            <AddNotification
                show={this.state.AddNotificationModal}
                onHide={() => this.setState({AddNotificationModal: false})}
                getNotifications={() => this.getNotifications()}
                onError={()=>this.setState({pageSpiner:false})}
            />
            <UpdateTermOfServiceUrl
                show={this.state.UpdateTermOfServiceUrlModal}
                onHide={() => this.setState({UpdateTermOfServiceUrlModal: false})}
                getTermsOfService={() => this.getTermsOfService()}
                termsOfServiceData = {{"termOfServiceKey":this.state.termOfServiceKey,"termOfServiceUrl":this.state.termOfServiceUrl}}
                onError={()=>this.setState({pageSpiner:false})}
            />
            <UpdateMaintenance
                show={this.state.UpdateMaintenanceModal}
                onHide={() => this.setState({UpdateMaintenanceModal: false})}
                MaintenanceData={MaintenanceData}
                termsOfServiceData = {{"termOfServiceKey":this.state.termOfServiceKey,"termOfServiceUrl":this.state.termOfServiceUrl}}
                onError={()=>this.setState({pageSpiner:false})}
            />
            <Pageloadspiiner loading = {this.state.pageSpiner} text={''}/>
        </div>
        );
  }
}

export default Settings;
