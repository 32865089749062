/* eslint-disable eqeqeq */
import React, {Component} from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { trls } from '../../factories/translate';
import API from '../../factories/api';
import SessionManager from '../../factories/session_manage';
import Axios from 'axios';
import $ from 'jquery';

const mapStateToProps = state => ({ 
    ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({
});

class UpdateMaintenance extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            textEnglish: '',
            textDutch: '',
            textGermen: '',
            textFrench: '',
        };
    }
    componentWillUnmount() {
    }

    handleSubmit = (e) => {
        e.preventDefault();
        var headers = SessionManager.shared().getAuthorizationHeader();
        const params = {};
        const updateFormData = new FormData(e.target);
       
        for (let key of updateFormData.keys()) {
            params[key] = updateFormData.get(key);
        }
        Axios.put(API.PutMaintenance,params,headers)
        .then(result => {
            this.props.onHide()
            this.props.getMaintenance()
            this.removeState();
        })
        .catch(err=>{
            if(err.response && err.response.status==400){
                $('.add-error').text(err.response.data)
                $('.add-error').removeClass("d-none")
            }
            this.props.onError()
        });

       
    }

    removeState = () => {
        this.setState({
            textEnglish: '',
            textDutch: '',
            textGermen: '',
            textFrench: '',
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        $('.add-error').addClass('d-none')
    }

    hideModel = () => {
        this.props.onHide();
        this.removeState()
    }

    onShow = () => {
        this.setState({
            textEnglish: this.props.MaintenanceData.en,
            textDutch: this.props.MaintenanceData.de,
            textGermen: this.props.MaintenanceData.nl,
            textFrench: this.props.MaintenanceData.fr,
        })
    }

    render(){
        const { textEnglish, textDutch, textGermen, textFrench} = this.state
        return (
            <Modal
            show={this.props.show }
            onHide={this.hideModel}
            onShow={this.onShow}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop= "static"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {trls('Update_Terms_Of_Delivery')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="alert alert-danger add-error d-none" role="alert"></div>
                <Form onSubmit = { this.handleSubmit }>
                    <Form.Group as={Row} controlId="textEnglish">
                        <Col className="product-text">
                            <Form.Control defaultValue={textEnglish}  onChange={(en) => this.setState({textEnglish:en})} type="text" name="en" required />
                            <label className="placeholder-label">{trls('Text English')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="textDutch">
                        <Col className="product-text">
                            <Form.Control defaultValue={textDutch}  onChange={(de) => this.setState({textDutch:de})} type="text" name="de" required />
                            <label className="placeholder-label">{trls('Text Dutch')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="textGermen">
                        <Col className="product-text">
                            <Form.Control defaultValue={textGermen}  onChange={(nl) => this.setState({textGermen:nl})} type="text" name="nl" required />
                            <label className="placeholder-label">{trls('Text German')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="textFrench">
                        <Col className="product-text">
                            <Form.Control defaultValue={textFrench}  onChange={(fr) => this.setState({textFrench:fr})} type="text" name="fr" required />
                            <label className="placeholder-label">{trls('Text French')}</label>
                        </Col>
                    </Form.Group>
                    <Form.Group style={{textAlign:"center"}}>
                        <Button type="Submit" >{trls("Save")}</Button>
                    </Form.Group>
                </Form>    
            </Modal.Body>
            </Modal>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateMaintenance);