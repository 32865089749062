import React from 'react';
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

const mapDispatchToProps = dispatch => ({

});

class Pageloadspinner extends React.Component {
  render() {
    const { loading, text } = this.props;

    if (loading) {
      return (
        <div className="loader">
            <div className="loader-content">
              <Loader
                className="page-loading-spinner"
                type="TailSpin"
                color="#00BFFF"
                height={50}
                width={50}
                style={{zIndex:"9999"}}
              />
              {text !== '' && <div className="loader-message">{text}</div>}
            </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
export default connect(mapDispatchToProps)(Pageloadspinner);
