import React, { Component } from "react";
import { connect } from "react-redux";
import { trls } from "../../factories/translate";
// eslint-disable-next-line no-unused-vars
import { Row, Col, Button, Form } from "react-bootstrap";
import SessionManager from "../../factories/session_manage";
import API from "../../factories/api";
import Axios from "axios";
import { BallBeat } from "react-pure-loaders";
import "react-datepicker/dist/react-datepicker.css";
import "datatables.net";
import HelpTextModal from "../Dashboard/HelpText";
import * as Auth from "../../factories/auth";

const mapStateToProps = (state) => ({
  ...state.auth,
});

class CustomerDocuments extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      Colunm: [
        { label: "Document name", value: "Document", type: "text", show: true },
      ],
      customerDocumentData: [],
      HelpTextModal: false,
      currentUserInfo: Auth.getLoggedUserInfo(),
      docNameLang: {
        English: "name",
        Dutch: "nameDutch",
        German: "nameGerman",
        French: "nameFrench",
      },
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this.getDocumentData();
  }

  getHelpText = () => {
    var pageName = "Documents";
    var headers = SessionManager.shared().getAuthorizationHeader();
    var lang = localStorage.getItem("eijf_lang");
    Axios.get(API.GetHelpTextByName + pageName, headers)
      .then((result) => {
        let helpURL = "";
        if (lang === "Dutch") {
          helpURL = result.data.contentDutch;
        } else if (lang === "English") {
          helpURL = result.data.contentEnglish;
        } else if (lang === "French") {
          helpURL = result.data.contentFrench;
        } else {
          helpURL = result.data.contentGerman;
        }
        const a = document.createElement("a");
        a.setAttribute("href", helpURL);
        a.setAttribute("target", "_blank");
        a.click();
      })
      .catch((err) => {
        // if(err.response.status===401){
        //     history.push('/login')
        // }
      });
  };

  getDocumentData() {
    const { currentUserInfo } = this.state;
    let params = {};
    let userCardCode = "";
    this.setState({ loading: true });
    var headers = SessionManager.shared().getAuthorizationHeader();
    Axios.post(API.GetCustomerData, params, headers)
      .then((result) => {
        userCardCode = result.data.value[0]
          ? result.data.value[0].CardCode
          : "";
        Axios.get(API.GetDocuments, headers)
          .then((result) => {
            var temp_doc = [];
            if (result.data) {
              result.data.forEach((element) => {
                if (
                  element.customerCodes.includes(userCardCode) ||
                  element.customerGroupCodes.includes(currentUserInfo.GroupCode)
                ) {
                  if (
                    element.documentUrl.slice(
                      element.documentUrl.length - 3,
                      element.documentUrl.length
                    ) !== "jpg" &&
                    element.documentUrl.slice(
                      element.documentUrl.length - 3,
                      element.documentUrl.length
                    ) !== "png"
                  ) {
                    temp_doc.push(element);
                  }
                }
              });
              this.setState({
                customerDocumentData: temp_doc,
                loading: false,
              });
            }
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  }

  showDocument = (docURL) => {
    window.open(docURL, "_blank");
  };
  render() {
    const { Colunm, customerDocumentData, docNameLang } = this.state;
    let lang = window.localStorage.getItem("eijf_lang");
    let nameLang = lang ? docNameLang[lang] : docNameLang["Dutch"];
    console.log("ttttt", customerDocumentData);
    return (
      <div className="order_div">
        <div className="content__header content__header--with-line">
          <div id="google_translate_element"></div>
          <h2 className="title">{trls("Current information")}</h2>
        </div>

        <div className="orders">
          <Row>
            <Col sm={12} className="has-search">
              <div
                style={{ display: "flex", float: "right" }}
                className="justify-content-right"
              >
                <Button
                  variant="primary"
                  className="float-right"
                  onClick={this.getHelpText}
                >
                  <i className="fa fa-info-circle" aria-hidden="true"></i>
                </Button>
              </div>
            </Col>
          </Row>
          <div className="table-responsive credit-history">
            <table
              id="deliver-table"
              className="place-and-orders__table table"
              width="100%"
            >
              <thead>
                <tr>
                  {Colunm.map((item, key) => (
                    <th
                      className={!item.show ? "filter-show__hide" : ""}
                      key={key}
                    >
                      {trls(item.label) ? trls(item.label) : ""}
                    </th>
                  ))}
                </tr>
              </thead>
              {customerDocumentData && !this.state.loading && (
                <tbody>
                  {customerDocumentData.map((data, i) => (
                    <tr id={i} key={i}>
                      {/* <td><div id={data.id}>{data[nameLang] ? data[nameLang] : ''}</div></td> */}
                      <td>
                        <div
                          id={data.id}
                          className="action-div"
                          onClick={() => this.showDocument(data.documentUrl)}
                        >
                          {data[nameLang] ? data[nameLang] : data.name}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            {this.state.loading && (
              <div
                className="col-md-4 offset-md-4 col-xs-12 loading"
                style={{ textAlign: "center" }}
              >
                <BallBeat color={"#222A42"} loading={this.state.loading} />
              </div>
            )}
          </div>
          <HelpTextModal
            show={this.state.HelpTextModal}
            onHide={() => this.setState({ HelpTextModal: false })}
            HelpTextTitle={this.state.SalesinvoicesHelpTextTitle}
            HelpTextDescription={this.state.HelpTextDescription}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps)(CustomerDocuments);
