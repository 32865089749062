/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Modal} from "react-bootstrap";
import { connect } from "react-redux";
import { trls } from "../../factories/translate";
import "react-datepicker/dist/react-datepicker.css";
import  { Link } from 'react-router-dom';

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({});

class Newitemform extends Component {
  componentDidMount() {
  }
  render() {
    const { traceData } = this.props
    return (
      <Modal
        show={this.props.show}
        onHide={() => this.props.onHide()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        tabIndex={"disable"}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {trls("TrackAndTrace")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your order has been send in multiple packages:</p>
          <ul className="list-group">
            {traceData.map((data, index) => {
              return (
                <li className="list-group-item d-flex justify-content-between align-items-center" key={index}>Package {index+1}
                  <span className="badge">
                    <Link to={{ pathname: data['TrackAndTrace'] ? data['TrackAndTrace']: ''}} 
                    target="_blank" style={{color: data['TrackAndTrace'] ? '#585858' : '#bbb8b8'}} ><i className="fas fa-map-marker-alt" style={{cursor: "pointer"}}><span className="action-title"></span></i></Link>
                  </span>
                </li>
              )
            })}
          </ul>
        </Modal.Body>
      </Modal>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Newitemform);
